import { Text } from '@ui-kitten/components'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { shortName } from 'src/utils/shortName'

type PropsType = {
  displayName: string
}

export const AvatarName = ({ displayName }: PropsType) => {
  return (
    <View style={styles.avatarContainer}>
      <Text style={styles.shortName}>{shortName(displayName)}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  avatarContainer: {
    height: 55,
    width: 55,
    borderRadius: 100,
    backgroundColor: '#333333ff',
    justifyContent: 'center',
    alignItems: 'center'
  },
  shortName: {
    textTransform: 'uppercase',
    fontSize: 25,
    fontWeight: 'bold',
    color: 'white'
  }
})
