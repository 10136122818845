import { Feather } from '@expo/vector-icons'
import { DrawerActions } from '@react-navigation/native'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { navigationRef } from 'src/navigations/RootNavigation'

export const DrawerButton = () => {
  return (
    <TouchableOpacity
      onPress={() =>
        navigationRef.current?.dispatch(DrawerActions.toggleDrawer())
      }>
      <Feather
        color={'white'}
        style={{
          fontSize: 27,
          marginLeft: 5
        }}
        name={'menu'}
      />
    </TouchableOpacity>
  )
}
