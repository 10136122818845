import { config } from 'src/config/businessman'
import { UserClaims } from 'src/types/businessman'
import { getEnterpriseId } from 'src/utils/firebase/path/getEnterpriseId'

/**
 * Return: enterpries/xyz
 */
export default function getEnterprisePath(
  userClaims: UserClaims | undefined
): string {
  return `${config.collection('enterprises')}/${getEnterpriseId(
    userClaims?.userPath!
  )}`
}
