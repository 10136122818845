import { RouteProp } from '@react-navigation/native'
import React from 'react'
import { PastPresenceList } from 'src/components/pastPresences/PastPresenceList'

type DataType = {
  displayName: string
  userId: string
}
type Props = {
  route: RouteProp<{ params: DataType }>
}

export const CoworkerPastPresenceScreen = ({ route }: Props) => {
  return <PastPresenceList route={route} />
}
