import * as Updates from 'expo-updates'
import { Alert } from 'react-native'

export const updateApp = async () => {
  try {
    const update = await Updates.checkForUpdateAsync()
    if (update.isAvailable) {
      // ... notify user of update ...
      await Updates.fetchUpdateAsync()
      Alert.alert(
        'Mise à jour',
        "L'application doit être redémarré pour installé la mise à jour",
        [
          {
            text: 'OK',
            onPress: async () => {
              await Updates.reloadAsync()
            },
            style: 'default'
          }
        ]
      )
    } else {
      Alert.alert('Mise à jour', 'Aucune mise à disponible', [
        {
          text: 'Fermer',
          style: 'default'
        }
      ])
    }
  } catch (e) {
    console.log(e)
    Alert.alert(
      'Mise à jour',
      "Une erreur s'est produite veuillez réessayer plus tard",
      [
        {
          text: 'Fermer',
          style: 'default'
        }
      ]
    )
  }
}
