import { Button } from '@ui-kitten/components'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { AppVersion } from 'src/components/atoms/AppVersion'
import { updateApp } from 'src/utils/updateApp'

export const PreferencesScreen = () => {
  return (
    <View style={styles.container}>
      <AppVersion color={'gray'} />
      <Button status={'control'} onPress={updateApp}>
        Mettre à jour l'application
      </Button>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
