import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { ResetPasswordScreen, SignInScreen, SignUpScreen } from '../scenes'
import { defaultColor } from '../utils/color'

type StackParamList = {
  SignInScene: undefined
  ResetPasswordScene: undefined
  SignUp: undefined
}

const Stack = createStackNavigator<StackParamList>()
export default function AuthStackNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={'SignInScene'}
      screenOptions={{
        headerStyle: {
          backgroundColor: defaultColor
        },
        headerTintColor: 'white',
        headerBackTitleVisible: false
      }}>
      <Stack.Screen
        name={'SignInScene'}
        component={SignInScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={'ResetPasswordScene'}
        component={ResetPasswordScreen}
        options={{
          headerTransparent: true,
          headerStyle: { backgroundColor: '#FEFCFB' },
          headerTintColor: defaultColor,
          headerTitle: 'Réinitialiser mot de passe'
        }}
      />
      <Stack.Screen
        name={'SignUp'}
        component={SignUpScreen}
        options={{
          headerStyle: { backgroundColor: defaultColor },
          headerTitle: 'Créer un compte'
        }}
      />
    </Stack.Navigator>
  )
}
