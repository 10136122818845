import React from 'react'
import { FlatList, View } from 'react-native'
import { navigate } from 'src/navigations/RootNavigation'
import { User } from 'src/types/businessman'
import { FloatingActionButton } from '../atoms/FloatingActionButton'
import { CoworkerListItem } from './CoworkerListItem'

interface PropsType {
  coworkers: User[] | undefined
}

export const CoworkerList = ({ coworkers }: PropsType) => {
  return (
    <View style={{ flex: 1 }}>
      <FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.id!!}
        data={coworkers}
        renderItem={(data) => <CoworkerListItem coworker={data.item} />}
      />
      <FloatingActionButton
        iconName={'plus-thick'}
        onPress={() => navigate('CoworkerEditFormScreen')}
      />
    </View>
  )
}
