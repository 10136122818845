import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Button, Card, Modal, Text } from '@ui-kitten/components'
import React from 'react'
import { StyleSheet, View } from 'react-native'

type PropsType = {
  message: string
  onPress: () => void
  visible: boolean
}

export const SuccessModal = ({ message, onPress, visible }: PropsType) => {
  return (
    <Modal
      style={{ width: 300 }}
      visible={visible}
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <Card disabled={true}>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <MaterialCommunityIcons name="check-circle" size={50} color="green" />
          <Text style={styles.message}> {message} </Text>
        </View>
        <Button onPress={onPress}>OK</Button>
      </Card>
    </Modal>
  )
}

const styles = StyleSheet.create({
  message: {
    marginVertical: 15,
    textAlign: 'center'
  }
})
