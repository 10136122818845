import * as Location from 'expo-location'
import firebase from 'firebase/compat'
import React, { useContext, useEffect, useState } from 'react'
import { Alert, View } from 'react-native'
import MapView, { Marker, PROVIDER_GOOGLE, Region } from 'react-native-maps'
import { FirebaseContext } from 'src/providers/FirebaseProvider'

type PropsType = {
  data: {
    name: string
    address: string
    location: firebase.firestore.GeoPoint | undefined
  }
  setData: (value: any) => void
}

export const EnterpriseLocation = ({ data, setData }: PropsType) => {
  const { enterpriseData } = useContext(FirebaseContext)
  const [marker, setMarker] = useState<firebase.firestore.GeoPoint | undefined>(
    data.location ?? undefined
  )
  const [initialRegion, setInitialRegion] = useState<Region>()

  useEffect(() => {
    ;(async () => {
      if (enterpriseData?.modules?.geolocation === false) {
        return
      }
      try {
        let { status } = await Location.requestForegroundPermissionsAsync()
        if (status !== 'granted') {
          Alert.alert(
            "L'autorisation d'accéder à la localisation a été refusée, veuillez verifier dans les paramètres du téléphone"
          )
          return
        }
        await Location.getCurrentPositionAsync({})
      } catch (e) {
        console.log(e)
      }
    })()
    setInitialRegion({
      latitude: data.location?.latitude ?? 12.35,
      longitude: data.location?.longitude ?? -1.516667,
      latitudeDelta: 0.1,
      longitudeDelta: 0.1
    })
  }, [data.location])

  return (
    <View
      style={{
        marginTop: 10,
        padding: 2
      }}>
      <MapView
        style={{
          height: 300
        }}
        userLocationPriority={'high'}
        showsUserLocation
        provider={PROVIDER_GOOGLE}
        zoomEnabled
        showsScale
        initialRegion={initialRegion}
        showsMyLocationButton
        onPress={(e) => {
          setMarker(
            new firebase.firestore.GeoPoint(
              e.nativeEvent.coordinate.latitude,
              e.nativeEvent.coordinate.longitude
            )
          )
          setData((prev: any) => ({
            ...prev,
            location: marker
          }))
        }}>
        {marker && (
          <Marker
            coordinate={{
              latitude: marker.latitude,
              longitude: marker.longitude
            }}
            description={enterpriseData?.address}
            title={enterpriseData?.name ?? ' '}
          />
        )}
      </MapView>
    </View>
  )
}
