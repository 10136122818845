type KeyType = {
  region: 'europe-west3'
  'domains.static.dev': 'https://static-dev.businessman.africa'
  'domains.static.prod': 'https://static.businessman.africa'
  'domains.static.quality': 'https://static-quality.businessman.africa'
  'domains.images.dev': 'https://static-dev.businessman.africa/images'
  'domains.images.prod': 'https://static.businessman.africa/images'
  'domains.images.quality': 'https://static-quality.businessman.africa/images'
  'domains.admin.dev': 'https://admin-dev.businessman.africa'
  'domains.admin.prod': 'https://admin.businessman.africa'
  'domains.admin.quality': 'https://admin-quality.businessman.africa'
  'collections.enterprises': 'enterprises'
  'collections.functionExecutionReports': 'xReportExecutionFunctions'
  'collections.userIntegrityReports': 'xReportIntegrityUsers'
  'collections.labels': 'labels'
  'collections.modules': 'modules'
  'collections.settings': 'settings'
  'collections.users': 'users'
  'collections.pastPresences': 'pastPresences'
  'collections.pastSubscriptions': 'pastSubscriptions'
  'collections.vouchers': 'vouchers'
  'collections.receipts': 'receipts'
  'collections.customers': 'customers'
  'collections.statistics': 'statistics'
  'collections.presence': 'presence'
  'topics.expiredSubscriptions': 'expired-subscriptions'
  'subscriptions.expired': 'expired'
  'subscriptions.running': 'running'
}

export type CollectionType = {
  enterprises: 'enterprises'
  functionExecutionReports: 'xReportExecutionFunctions'
  userIntegrityReports: 'xReportIntegrityUsers'
  labels: 'labels'
  users: 'users'
  pastPresences: 'pastPresences'
  pastSubscriptions: 'pastSubscriptions'
  vouchers: 'vouchers'
  receipts: 'receipts'
  customers: 'customers'
  modules: 'modules'
  settings: 'settings'
  statistics: 'statistics'
  presence: 'presence'
}

export type TopicType = {
  expiredSubscriptions: 'expired-subscriptions'
  subscriptionsRestDays: 'subscriptions-rest-days'
}

const configuration = {
  region: 'europe-west3',
  domains: {
    static: {
      dev: 'https://static-dev.businessman.africa',
      prod: 'https://static.businessman.africa',
      quality: 'https://static-quality.businessman.africa'
    },
    images: {
      dev: 'https://static-dev.businessman.africa/images',
      prod: 'https://static.businessman.africa/images',
      quality: 'https://static-quality.businessman.africa/images'
    },
    merchant: {
      dev: 'https://go-dev.businessman.africa',
      prod: 'https://go.businessman.africa',
      quality: 'https://go-quality.businessman.africa'
    },
    admin: {
      dev: 'https://admin-dev.businessman.africa',
      prod: 'https://admin.businessman.africa',
      quality: 'https://admin-quality.businessman.africa'
    }
  },
  collections: {
    enterprises: 'enterprises',
    functionExecutionReports: 'xReportExecutionFunctions',
    userIntegrityReports: 'xReportIntegrityUsers',
    labels: 'labels',
    modules: 'modules',
    users: 'users',
    pastPresences: 'pastPresences',
    pastSubscriptions: 'pastSubscriptions',
    vouchers: 'vouchers',
    receipts: 'receipts',
    customers: 'customers',
    settings: 'settings',
    statistics: 'statistics',
    presence: 'presence'
  },
  subscriptions: {
    expired: 'expired',
    running: 'running'
  },
  topics: {
    expiredSubscriptions: 'expired-subscriptions',
    subscriptionsRestDays: 'subscriptions-rest-days'
  }
}

export const config = {
  get: (key: keyof KeyType) => {
    return fetchFromObject(configuration, key)
  },
  collection: (key: keyof CollectionType): string => {
    // @ts-ignore
    return config.get(`collections.${key}`)
  },
  topic: (key: keyof TopicType): string => {
    // @ts-ignore
    return config.get(`topics.${key}`)
  }
}

function fetchFromObject(obj: any, key: string): any {
  if (typeof obj === 'undefined') {
    return undefined
  }

  const index = key.indexOf('.')

  if (index > -1) {
    return fetchFromObject(obj[key.substring(0, index)], key.substr(index + 1))
  }

  return obj[key]
}
