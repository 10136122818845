import { FontAwesome } from '@expo/vector-icons'
import { Card, Text } from '@ui-kitten/components'
import { collection, query } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { WarningMessageSubscription } from 'src/components/atoms/WarningMessageSubscription'
import { DashboardItem } from 'src/components/manager/DashboardItem'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { FirstLoginModal } from 'src/components/molecules/modals/FirstLoginModal'
import { navigate } from 'src/navigations/RootNavigation'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Statistic } from 'src/types/businessman/entities/Statistic'
import { localDate } from 'src/utils/date/formatDate'
import getSubCollectionPath from 'src/utils/firebase/path/getSubCollectionPath'

type CounterType = {
  customerCounter: number
  userCounter: number
  receiptCounter: number
  presenceCounters:
    | Array<{ counter: number; label: string; color: string }>
    | undefined
}
export const ManagerHomeScreen = () => {
  const { userClaims, firestore, enterpriseData } = useContext(FirebaseContext)
  const [statistics, loading, error] = useCollectionData<Statistic>(
    // @ts-ignore
    query(
      collection(firestore, getSubCollectionPath(userClaims, 'statistics'))
    ),
    {
      idField: 'id'
    }
  )
  const [counters, setCounters] = useState<CounterType>({
    customerCounter: 0,
    userCounter: 0,
    receiptCounter: 0,
    presenceCounters: undefined
  })

  useEffect(() => {
    if (statistics && statistics.length > 0) {
      const customersLength =
        statistics?.filter((stat) => stat.id === 'customers')[0]?.counter ?? 0
      const usersLength =
        statistics?.filter((stat) => stat.id === 'users')[0]?.counter ?? 0
      const receiptsLength =
        statistics?.filter((stat) => stat.id === 'receipts')[0]?.counter ?? 0
      const presenceStats = statistics?.filter(
        (stat) => stat.id === 'presence'
      )[0]
      let presencesCounters = []

      for (const key in presenceStats?.counters) {
        presencesCounters.push(presenceStats?.counters[key])
      }

      setCounters({
        customerCounter: customersLength,
        receiptCounter: receiptsLength,
        userCounter: usersLength,
        presenceCounters: presencesCounters
      })
    }
  }, [statistics])

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen />
  }

  return (
    <>
      <WarningMessageSubscription />
      <FirstLoginModal />
      <View style={styles.container}>
        <View
          style={{
            paddingBottom: 20
          }}>
          <View style={styles.dashboardItems}>
            <DashboardItem
              title={'Collabrateurs'}
              value={counters.userCounter}
              onPress={() => navigate('myCoworkers')}
            />
            <DashboardItem
              title={'Clients'}
              value={counters.customerCounter}
              onPress={() => navigate('myCustomers')}
            />
            <DashboardItem
              mt={15}
              title={'Quittances'}
              value={counters.receiptCounter}
              onPress={() => navigate('myReceipts')}
            />
          </View>
        </View>
        {counters?.presenceCounters &&
          counters?.presenceCounters?.length > 0 &&
          enterpriseData?.modules?.presence && (
            <TouchableOpacity
              onPress={() => navigate('myPresence')}
              style={styles.presenceContainer}>
              {counters.presenceCounters.map((presenceCounter, index) => (
                <View
                  key={index}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 5
                  }}>
                  <FontAwesome
                    name="circle"
                    size={20}
                    color={presenceCounter?.color ?? 'gray'}
                    style={{ marginRight: 10 }}
                  />
                  <Text>
                    {presenceCounter?.label} ({presenceCounter?.counter})
                  </Text>
                </View>
              ))}
            </TouchableOpacity>
          )}
      </View>
      {enterpriseData?.currentSubscription?.endDate && (
        <Card>
          <Text>
            {`Abonnement actif jusqu'au ${localDate(
              enterpriseData?.currentSubscription?.endDate?.toDate()
            )}`}
          </Text>
        </Card>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  titleCoworker: {
    fontWeight: 'bold',
    fontSize: 18,
    padding: 10
  },
  presenceContainer: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.2,
    elevation: 5,
    width: '95%',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 15,
    marginHorizontal: 10
  },
  dashboardItems: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 10,
    marginTop: 20,
    flexWrap: 'wrap'
  }
})
