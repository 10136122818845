import { Button, IconProps, Modal, Text } from '@ui-kitten/components'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'

type PropsType = {
  visible: boolean
  iconName: IconProps
  message: string
  color?: string
  setVisible?: (value: boolean) => void
  onPress: () => void
  loading?: boolean
}
export const ConfirmModal = ({
  visible,
  setVisible,
  iconName,
  message,
  color,
  loading,
  onPress
}: PropsType) => {
  return (
    <Modal
      style={{ width: 300 }}
      visible={visible}
      onBackdropPress={() => {
        setVisible!!(false)
      }}
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
      <View style={styles.container}>
        <Text style={styles.message}>{message}</Text>
        <View style={styles.btnSection}>
          <Button
            size={'small'}
            style={styles.cancel}
            onPress={() => {
              setVisible!!(false)
            }}>
            Annuler
          </Button>
          <Button
            disabled={loading}
            accessoryLeft={loading ? LoadingIndicator : undefined}
            onPress={onPress}
            size={'small'}>
            {loading ? 'En cours' : 'Supprimer'}
          </Button>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    paddingTop: 20,
    backgroundColor: 'white',
    borderRadius: 5,
    alignItems: 'center'
  },
  cancel: {
    backgroundColor: '#c1c1c1ff',
    borderColor: 'transparent',
    marginRight: 10
  },
  btnSection: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  message: {
    marginBottom: 20,
    textAlign: 'center'
  }
})
