import { Feather } from '@expo/vector-icons'
import { MenuItem, OverflowMenu } from '@ui-kitten/components'
import { getAuth, signOut } from 'firebase/auth'
import React, { useState } from 'react'
import { navigate } from 'src/navigations/RootNavigation'
import {
  renderCoworkerIcon,
  renderLogoutIcon,
  renderPreferencesIcon
} from '../icons'

export const MenuButton = () => {
  const [visible, setVisible] = useState(false)

  const logout = async () => {
    try {
      await signOut(getAuth())
    } catch (e) {
      console.log(e)
    }
  }

  const renderMenuIcon = () => (
    <Feather
      style={{ marginRight: 5 }}
      name="menu"
      color={'white'}
      size={30}
      onPress={() => setVisible(true)}
    />
  )

  const onItemSelect = () => {
    setVisible(false)
  }

  return (
    <OverflowMenu
      anchor={renderMenuIcon}
      visible={visible}
      onSelect={onItemSelect}
      onBackdropPress={() => setVisible(false)}>
      <MenuItem
        accessoryLeft={renderCoworkerIcon}
        onPress={() => navigate('UserProfileScene')}
        title="Profil"
      />
      <MenuItem
        accessoryLeft={renderPreferencesIcon}
        onPress={() => navigate('PreferencesScene')}
        title="Préférences"
      />
      <MenuItem
        accessoryLeft={renderLogoutIcon}
        title="Déconnexion"
        onPress={logout}
      />
    </OverflowMenu>
  )
}
