import {
  CheckBox,
  IndexPath,
  Input,
  Select,
  SelectItem,
  Text
} from '@ui-kitten/components'
import React, { useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { ModuleBase, ModulePresence } from 'src/types/businessman'

type PropsType = {
  presenceData: ModulePresence
  setPresenceData: (presenceData: any) => void
  baseData: ModuleBase
  setBaseData: (baseData: any) => void
}

const canalList = ['SMS', 'Telegram', 'Whatsapp']

export const PresenceModuleSettings = ({
  presenceData,
  setPresenceData,
  baseData,
  setBaseData
}: PropsType) => {
  const [selectedIndex, setSelectedIndex] = useState<IndexPath>()

  const displayCanalValue = canalList[selectedIndex?.row!]

  const renderOption = (title: string, index: number) => (
    <SelectItem title={title} key={index} />
  )

  useEffect(() => {
    if (presenceData?.settings?.preferredCommunicationChannel === 'sms:')
      setSelectedIndex(new IndexPath(0))
    else if (
      presenceData?.settings?.preferredCommunicationChannel === 'tg://msg?text='
    )
      setSelectedIndex(new IndexPath(1))
    else if (
      presenceData?.settings?.preferredCommunicationChannel ===
      'https://wa.me/?text='
    )
      setSelectedIndex(new IndexPath(2))
  }, [presenceData?.settings?.preferredCommunicationChannel])

  return (
    <View style={styles.presenceContainer}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <CheckBox
          style={{ flex: 0.09 }}
          checked={presenceData?.settings?.showPresencesForAll}
          onChange={(value) =>
            setPresenceData((prev: any) => ({
              ...prev,
              settings: {
                ...prev.settings,
                showPresencesForAll: value
              }
            }))
          }
        />
        <Text
          style={{ flex: 1 }}
          onPress={() => {
            setPresenceData((prev: any) => ({
              ...prev,
              settings: {
                ...prev.settings,
                showPresencesForAll:
                  !presenceData?.settings?.showPresencesForAll
              }
            }))
          }}>
          Afficher la présence des collaborateurs pour tous
        </Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 30
        }}>
        <CheckBox
          style={{ flex: 0.09 }}
          checked={presenceData?.settings?.showPastPresencesForAll}
          onChange={(value) =>
            setPresenceData((prev: any) => ({
              ...prev,
              settings: {
                ...prev.settings,
                showPastPresencesForAll: value
              }
            }))
          }
        />
        <Text
          style={{ flex: 1 }}
          onPress={() => {
            setPresenceData((prev: any) => ({
              ...prev,
              settings: {
                ...prev.settings,
                showPastPresencesForAll:
                  !presenceData?.settings?.showPastPresencesForAll
              }
            }))
          }}>
          Afficher l'historique des présences pour tous
        </Text>
      </View>
      <View style={{ marginTop: 20 }}>
        <Select
          label={() => (
            <Text style={{ marginBottom: 10 }}>Canal de communication</Text>
          )}
          placeholder={'Choisir dans la liste'}
          value={displayCanalValue}
          selectedIndex={selectedIndex}
          onSelect={(index) => {
            setSelectedIndex(index as IndexPath)
            let preferredCanal = ''
            if (!(index instanceof IndexPath) || index?.row === 0)
              preferredCanal = 'sms:'
            else if (index?.row === 1) preferredCanal = 'tg://msg?text='
            else if (index?.row === 2) preferredCanal = 'https://wa.me/?text='

            setPresenceData((prev: any) => ({
              ...prev,
              settings: {
                ...prev.settings,
                preferredCommunicationChannel: preferredCanal
              }
            }))
          }}>
          {canalList.map(renderOption)}
        </Select>
      </View>
      <View style={{ marginTop: 20 }}>
        <Input
          defaultValue={baseData?.settings?.telegramChatId ?? ''}
          label={() => (
            <Text style={{ marginBottom: 10 }}>
              Identifiant du canal telegram
            </Text>
          )}
          onChangeText={(value) =>
            setBaseData((prev: any) => ({
              ...prev,
              settings: {
                telegramChatId: value
              }
            }))
          }
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  presenceContainer: {
    padding: 10
  }
})
