import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from '@ui-kitten/components'
import React, { useContext, useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { ConfirmModal } from 'src/components/molecules/modals/ConfirmModal'
import { config } from 'src/config/businessman'
import { navigate } from 'src/navigations/RootNavigation'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Customer } from 'src/types/businessman'
import { defaultColor } from 'src/utils/color'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'
import { buildPath } from 'src/utils/firebase/path/buildPath'

type PropsType = {
  data: Customer
}

export const CustomersListItem = ({ data }: PropsType) => {
  const { userEnterpriseId } = useContext(FirebaseContext)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const deleteCustomer = async () => {
    try {
      setLoading(true)
      const customerPath = buildPath(
        config.collection('enterprises'),
        userEnterpriseId!,
        config.collection('customers'),
        data?.id!
      )

      await getFunctionCallable('customerPersistence', {
        action: 'delete',
        customerPath: customerPath,
        enterprisePath: buildPath(
          config.collection('enterprises'),
          userEnterpriseId!
        )
      })
      setShowDialog(false)
      setLoading(false)
    } catch (e) {
      setShowDialog(false)
      setLoading(false)
    }
  }

  return (
    <>
      <ConfirmModal
        loading={loading}
        setVisible={setShowDialog}
        visible={showDialog}
        iconName={'ios-close-circle-outline'}
        color={defaultColor}
        message={'Voulez vraiment supprimer cet client ?'}
        onPress={deleteCustomer}
      />
      <TouchableOpacity
        style={styles.container}
        onPress={() =>
          navigate('CustomerFormScreen', {
            customerData: data,
            headerTitle: 'Modifier le client'
          })
        }>
        <View style={styles.row}>
          <View style={{ marginRight: 10, flex: 0.85 }}>
            <Text style={{ fontWeight: '700' }}>
              {data?.lastName} {data?.firstName}
            </Text>

            <View style={{ marginTop: 10 }}>
              {data?.customerEmail?.length > 0 && (
                <Text>{data?.customerEmail}</Text>
              )}

              {data?.mobilePhone?.length > 0 && (
                <Text>{data?.mobilePhone}</Text>
              )}

              {data?.address?.length > 0 && <Text>{data?.address}</Text>}
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              flex: 0.15
            }}>
            <TouchableOpacity
              style={{ marginRight: 10 }}
              onPress={() => setShowDialog(true)}>
              <MaterialCommunityIcons
                name="delete-outline"
                size={24}
                color="gray"
              />
            </TouchableOpacity>
            <TouchableOpacity>
              <Entypo name="chevron-small-right" size={24} color="black" />
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 3,
    backgroundColor: 'white',
    padding: 10,
    shadowOpacity: 0.1,
    shadowRadius: 2.65,
    shadowColor: '#000',
    shadowOffset: { height: 1, width: 0 },
    elevation: 1
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
