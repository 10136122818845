import { Text } from '@ui-kitten/components'
import Constants from 'expo-constants'
import React from 'react'
import { View, ViewProps } from 'react-native'

interface PropsType extends ViewProps {
  color?: string
}
export const AppVersion = ({ color, style }: PropsType) => (
  <View style={{ flexDirection: 'row', marginVertical: 50 }} {...style}>
    <Text style={{ color: color ? color : 'white', fontWeight: '200' }}>
      Version {Constants.manifest?.version!!}
    </Text>
    {Constants.manifest?.extra?.applicationContext === 'dev' && (
      <Text style={{ color: color ? color : 'white', fontWeight: '200' }}>
        {' '}
        - {Constants.manifest?.extra?.applicationContext}
      </Text>
    )}
  </View>
)
