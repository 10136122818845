import { FontAwesome, Ionicons } from '@expo/vector-icons'
import { Divider, Text } from '@ui-kitten/components'
import React, { useState } from 'react'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import { UserLocation } from 'src/components/presences/UserLocation'
import { Enterprise, Status } from 'src/types/businessman'
import { formatDate } from 'src/utils/date/formatDate'

interface PropsType {
  item: Status
  withName?: boolean
  enterpriseData: Enterprise
}

export const PastPresenceListItem = ({
  item,
  withName,
  enterpriseData
}: PropsType) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <View style={styles.container}>
      <UserLocation
        statusLabel={item.label}
        displayName={item.userDisplayName}
        userLocation={item?.userLocation!}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flexDirection: 'row', flex: 0.9, alignItems: 'center' }}>
          <View style={styles.information}>
            {withName && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>
                <Text style={styles.name}>{item.userDisplayName}</Text>
                <FontAwesome
                  style={styles.icon}
                  name="circle"
                  size={10}
                  color={item?.color ?? 'green'}
                />
              </View>
            )}
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text>{item.label}</Text>
              {!withName && (
                <FontAwesome
                  style={styles.icon}
                  name="circle"
                  size={10}
                  color={item?.color ?? 'green'}
                />
              )}
            </View>
            <Text category={'s2'}>{item?.message ?? ''}</Text>
            <Text category={'s2'}>
              {formatDate(item.createdAt?.toDate()!!)}
            </Text>
          </View>
        </View>
        <View style={{ flex: 0.1 }}>
          {item?.userLocation &&
            Platform.OS !== 'web' &&
            enterpriseData?.modules?.geolocation &&
            item?.userLocation?.latitude !== 0 &&
            item?.userLocation?.longitude !== 0 && (
              <TouchableOpacity
                onPress={() => setShowModal(true)}
                style={{ marginRight: 12 }}>
                <Ionicons name="md-location-outline" size={24} color="black" />
              </TouchableOpacity>
            )}
        </View>
      </View>
      <Divider
        style={{
          marginTop: 10,
          marginLeft: 20,
          marginRight: 20
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 15
  },
  name: {
    fontWeight: 'bold'
  },
  information: {
    marginLeft: 10
  },
  icon: {
    marginLeft: 10
  }
})
