import { config } from 'src/config/businessman'
import { CollectionType } from 'src/config/businessman/config'
import { UserClaims } from 'src/types/businessman'
import getEnterprisePath from 'src/utils/firebase/path/getEnterprisePath'

/**
 * Return example:
 * enterprises/abc/xyz
 */
export default function getSubCollectionPath(
  userClaims: UserClaims | undefined,
  collectionId: keyof CollectionType
): string {
  return `${getEnterprisePath(userClaims)}/${config.collection(collectionId)}`
}
