import { collection, orderBy, query } from 'firebase/firestore'
import React, { useContext } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { FlatList, Text, View } from 'react-native'
import { CustomersListItem } from 'src/components/customers/CustomersListItem'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Customer } from 'src/types/businessman'
import getSubCollectionPath from 'src/utils/firebase/path/getSubCollectionPath'

export const CustomersList = () => {
  const { firestore, userClaims } = useContext(FirebaseContext)
  const [customers, loading, error] = useCollectionData<Customer>(
    // @ts-ignore
    query(
      collection(firestore, getSubCollectionPath(userClaims, 'customers')),
      orderBy('lastName', 'asc')
    ),
    {
      idField: 'id'
    }
  )

  const renderEmptyComponent = () => (
    <View style={{ flex: 1, alignSelf: 'center' }}>
      <Text>Aucun client disponible </Text>
    </View>
  )
  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen />
  }

  return (
    <View style={{ flex: 1, margin: 5 }}>
      <FlatList
        ListEmptyComponent={renderEmptyComponent}
        keyExtractor={(item) => item.id!}
        data={customers}
        renderItem={({ item }) => <CustomersListItem data={item} />}
      />
    </View>
  )
}
