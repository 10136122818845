import { FontAwesome } from '@expo/vector-icons'
import { Tab, TabView } from '@ui-kitten/components'
import { doc, query } from 'firebase/firestore'
import React, { useContext, useState } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { StyleSheet, Text, View } from 'react-native'
import { WarningMessageSubscription } from 'src/components/atoms/WarningMessageSubscription'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { PresenceList } from 'src/components/presences/PresenceList'
import { StatusList } from 'src/components/status/StatusList'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { ModulePresence } from 'src/types/businessman'
import getSettingsDocPath from 'src/utils/firebase/path/getSettingsDocPath'

export const CoworkerHomeScreen = () => {
  const { userData, firestore, userClaims } = useContext(FirebaseContext)
  const [selectedIndex, setSelectedIndex] = useState(
    userClaims?.roles.includes('manager') ? 1 : 0
  )
  const [presenceModuleData, loading, error] = useDocumentData<ModulePresence>(
    // @ts-ignore
    query(doc(firestore, getSettingsDocPath(userClaims, 'presence')))
  )

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen />
  }

  const statusTitle = () => (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      <FontAwesome
        name="circle"
        size={10}
        style={{ paddingRight: 15 }}
        color={userData?.latestStatus?.color ?? 'gray'}
      />
      <Text>Mon statut</Text>
    </View>
  )

  return (
    <>
      <WarningMessageSubscription />
      {!presenceModuleData?.settings?.showPresencesForAll &&
      !userClaims?.roles.includes('manager') &&
      userClaims?.roles.includes('coworker') ? (
        <StatusList />
      ) : (
        <TabView
          tabBarStyle={{
            height: 50
          }}
          style={styles.container}
          selectedIndex={selectedIndex}
          onSelect={(index) => setSelectedIndex(index)}>
          <Tab title={statusTitle}>
            <StatusList />
          </Tab>
          <Tab title="Mes collègues">
            <PresenceList />
          </Tab>
        </TabView>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
})
