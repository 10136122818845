import { Button, Text } from '@ui-kitten/components'
import * as ImagePicker from 'expo-image-picker'
import { doc, setDoc } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { Image, Platform, ScrollView, View } from 'react-native'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import getSettingsDocPath from 'src/utils/firebase/path/getSettingsDocPath'
import { uploadImage } from 'src/utils/firebase/uploadImage'

type PropsType = {
  uploading: boolean
  setUploading: (value: boolean) => void
}

type receiptType = {
  stamp: string | undefined
  logo: string | undefined
}

export const EnterpriseReceipt = () => {
  const [imageType, setImageType] = useState('')
  const { firestore, userClaims, baseModuleData, userEnterpriseId } =
    useContext(FirebaseContext)
  const [receiptFileData, setReceiptFileData] = useState<receiptType>({
    stamp: undefined,
    logo: undefined
  })
  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    if (baseModuleData?.logo || baseModuleData?.stamp) {
      setReceiptFileData({
        logo: baseModuleData?.logo,
        stamp: baseModuleData?.stamp
      })
    }
  }, [baseModuleData])

  const saveData = async ({
    stampUrl,
    logoUrl
  }: {
    stampUrl?: string
    logoUrl?: string
  }) => {
    let uploadedData: any = {}
    if (logoUrl || receiptFileData.logo) {
      uploadedData.logo = logoUrl ?? receiptFileData.logo
    }
    if (stampUrl || receiptFileData.stamp) {
      uploadedData.stamp = stampUrl ?? receiptFileData.stamp
    }

    try {
      await setDoc(
        doc(firestore, getSettingsDocPath(userClaims, 'base')),
        uploadedData,
        {
          merge: true
        }
      )
    } catch (e) {
      console.log(e)
    }
  }

  const pickImageAndUpload = async (fileName: string) => {
    if (Platform.OS !== 'web') {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        alert(
          "Désolé, vueillez accorder d'abord les droits d'accès à la galerie dans les paramètres du téléphone"
        )
        return
      }
    }

    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1
    })

    try {
      if (!result.cancelled) {
        setUploading(true)
        const uploadUrl = await uploadImage(
          result.uri,
          `enterprises/${userEnterpriseId}/settings/base/`,
          fileName
        )
        if (fileName.trim() === 'logo') {
          setReceiptFileData((prev: any) => ({ ...prev, logo: uploadUrl }))
          await saveData({ logoUrl: uploadUrl })
        } else {
          setReceiptFileData((prev: any) => ({ ...prev, stamp: uploadUrl }))
          await saveData({ stampUrl: uploadUrl })
        }
      }
    } catch (e) {
      alert('Le changement de la photo a échoué')
    } finally {
      setUploading(false)
    }
  }

  return (
    <ScrollView>
      <View style={{ alignItems: 'center' }}>
        {(!receiptFileData?.logo || receiptFileData?.logo?.length < 10) &&
        !uploading ? (
          <Text style={{ marginVertical: 20 }}>
            Veuillez sélectionner votre logo
          </Text>
        ) : (
          <Image
            source={{ uri: receiptFileData?.logo }}
            style={{
              height: 200,
              width: 200,
              marginVertical: 10,
              resizeMode: 'contain'
            }}
          />
        )}
        <Button
          accessoryLeft={
            uploading && imageType === 'logo' ? LoadingIndicator : undefined
          }
          size={'small'}
          disabled={uploading}
          onPress={async () => {
            setImageType('logo')
            await pickImageAndUpload('logo')
          }}>
          {uploading && imageType === 'logo'
            ? 'En cours'
            : 'Sélectionner votre logo'}
        </Button>
      </View>
      <View style={{ alignItems: 'center', marginTop: 20 }}>
        {(!receiptFileData?.stamp || receiptFileData?.stamp?.length < 10) &&
        !uploading ? (
          <Text style={{ marginVertical: 20 }}>
            Veuillez sélectionner votre cachet
          </Text>
        ) : (
          <Image
            source={{ uri: receiptFileData?.stamp }}
            style={{
              height: 200,
              width: 200,
              marginVertical: 10,
              resizeMode: 'contain'
            }}
          />
        )}
        <Button
          accessoryLeft={
            uploading && imageType === 'stamp' ? LoadingIndicator : undefined
          }
          disabled={uploading}
          size={'small'}
          onPress={async () => {
            setImageType('stamp')
            await pickImageAndUpload('stamp')
          }}>
          {uploading && imageType === 'stamp'
            ? 'En cours'
            : 'Sélectionner votre cachet'}
        </Button>
      </View>
    </ScrollView>
  )
}
