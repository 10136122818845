import { Input } from '@ui-kitten/components'
import React, { useContext } from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { widthPercentageToDP } from 'react-native-responsive-screen'
import { navigate } from 'src/navigations/RootNavigation'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Product } from 'src/types/businessman'
import { FloatingActionButton } from '../../../atoms/FloatingActionButton'
import { renderSearchIcon } from '../../../icons'
import { ProductItem } from '../Item/productItem'

interface PropsType {
  products: Array<Product>
}

export const ProductListView = ({ products }: PropsType) => {
  const { userEnterpriseId } = useContext(FirebaseContext)

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        data={products}
        keyExtractor={(item: Product) => item.id!!}
        renderItem={(data) => (
          <ProductItem product={data.item} enterpriseId={userEnterpriseId!} />
        )}
      />
      <View style={[styles.bgTransparent, styles.pt3]}>
        <Input
          style={styles.filterInput}
          placeholder={'Entrer le nom du produit'}
          accessoryRight={renderSearchIcon}
        />
        <FloatingActionButton
          iconName={'plus-thick'}
          onPress={() => navigate('CoworkerEditFormScreen')}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  filterInput: {
    width: widthPercentageToDP('100%') - 70,
    paddingLeft: widthPercentageToDP(2),
    paddingBottom: widthPercentageToDP(4),
    paddingTop: widthPercentageToDP(2),
    borderRadius: 5
  },
  bgTransparent: {
    backgroundColor: '#FFF'
  },

  pt3: {
    paddingTop: widthPercentageToDP(0.5)
  },
  p2: {
    padding: widthPercentageToDP(2)
  },
  discountButton: {
    maxWidth: widthPercentageToDP(50),
    marginLeft: widthPercentageToDP(2.1)
  }
})
