import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Button, Input, Spinner, Text } from '@ui-kitten/components'
import * as ImagePicker from 'expo-image-picker'
import { doc, setDoc } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  Animated,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native'
import { AppVersion } from 'src/components/atoms/AppVersion'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'
import { renderEyeIcon } from 'src/components/icons'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { User, UserArgumentsUpdate } from 'src/types/businessman'
import { fadeIn, fadeOut } from 'src/utils/animation'
import { errorColor } from 'src/utils/color'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'
import { uploadImage } from 'src/utils/firebase/uploadImage'

const initialUserData: any = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  address: ''
}

export const UserProfileScreen = () => {
  // todo fix potential bug below: #1
  const { account, userClaims, userData, firestore, userEnterpriseId } =
    useContext(FirebaseContext)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [fadeAnimation] = useState(new Animated.Value(1))
  const [data, setData] = useState<User>(initialUserData)
  const [image, setImage] = useState<string | undefined>(undefined)
  const [uploading, setUploading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm()

  const pickImageAndUpload = async () => {
    if (Platform.OS !== 'web') {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
      if (status !== 'granted') {
        alert(
          "Désolé, vueillez accorder d'abord les droits d'accès à la galerie dans les paramètres du téléphone"
        )
        return
      }
    }

    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.3
    })

    try {
      setUploading(true)
      if (!result.cancelled) {
        const uploadUrl = await uploadImage(
          result.uri,
          `enterprises/${userEnterpriseId}/base/`,
          account?.uid!
        )
        await setDoc(
          doc(firestore, userClaims?.userPath!),
          {
            profileUrl: uploadUrl
          },
          { merge: true }
        )
        setImage(uploadUrl)
      }
    } catch (e) {
      console.log(e)
      alert('Le changement de la photo a échoué')
    } finally {
      setUploading(false)
    }
  }

  useEffect(() => {
    if (userData) {
      setData(userData)
      reset(userData)
    }
  }, [userData])

  useEffect(() => {
    if (showSuccess) {
      const interval = setInterval(() => {
        fadeOut(fadeAnimation, setShowSuccess)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [showSuccess])

  // @ts-ignore
  const updateCustomer: SubmitHandler<SubmitHandler<User>> = async (
    data: User
  ) => {
    setShowError(false)
    setShowSuccess(false)
    setSaveLoading(true)

    const userData: Partial<User> = {
      lastName: data.lastName,
      firstName: data.firstName,
      address: data.address,
      email: data.email ?? '',
      phoneNumber: data.phoneNumber ?? '',
      // @ts-ignore
      password: data.password ?? ''
    }

    try {
      await getFunctionCallable('userPersistence', {
        action: 'update',
        userPath: userClaims?.userPath,
        data: userData
      } as UserArgumentsUpdate)

      setShowSuccess(true)
      setSaveLoading(false)
      fadeIn(fadeAnimation)
    } catch (error: any) {
      setShowError(true)
      setSaveLoading(false)
    }
  }

  return (
    <ScrollView style={styles.container}>
      <View style={styles.pictureContainer}>
        <TouchableOpacity onPress={pickImageAndUpload}>
          <View style={styles.ImageShape}>
            <View
              style={{
                zIndex: 500,
                position: 'absolute',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              {(image || data?.profileUrl != '') && (
                <Image
                  source={{ uri: image || data?.profileUrl }}
                  style={{
                    height: 90,
                    width: 90,
                    borderRadius: 90
                  }}
                />
              )}
            </View>
            <TouchableOpacity
              onPress={pickImageAndUpload}
              style={styles.iconButton}>
              {uploading && <Spinner size={'small'} />}
              {!uploading && (
                <MaterialCommunityIcons
                  name="camera-plus-outline"
                  size={24}
                  color="black"
                />
              )}
            </TouchableOpacity>
          </View>
        </TouchableOpacity>
      </View>
      <View>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <Input
              returnKeyType={'next'}
              clearButtonMode={'while-editing'}
              style={styles.input}
              value={field.value}
              placeholder={'Prénom'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              onBlur={field.onBlur}
            />
          )}
          rules={{ required: true }}
          defaultValue={data?.firstName ?? ''}
        />
        {errors.firstName && (
          <Text style={{ color: errorColor }}>Le prénom est obligatoire.</Text>
        )}
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              placeholder={'Nom'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              textStyle={{ textTransform: 'uppercase' }}
              onBlur={field.onBlur}
              style={styles.input}
              value={field.value}
            />
          )}
          rules={{ required: true }}
          defaultValue={data?.lastName ?? ''}
        />
        {errors.lastName && (
          <Text style={{ color: errorColor }}>Le nom est obligatoire.</Text>
        )}
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <Input
              style={styles.input}
              placeholder={'Adresse'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              onBlur={field.onBlur}
              value={field.value}
            />
          )}
          rules={{ required: false }}
          defaultValue={data?.address ?? ''}
        />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              disabled={true}
              style={styles.input}
              placeholder={'Email'}
              keyboardType={'email-address'}
              value={field.value}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              onBlur={field.onBlur}
            />
          )}
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'address mail invalide'
            },
            required: false
          }}
          defaultValue={data?.email ?? ''}
        />

        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <Input
              placeholder={'Numéro'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              onBlur={field.onBlur}
              value={field.value}
              style={styles.input}
            />
          )}
          rules={{ required: false }}
          defaultValue={data?.phoneNumber ?? ''}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              secureTextEntry={hidePassword}
              textContentType={'password'}
              placeholder={'Mot de passe'}
              accessoryRight={() =>
                renderEyeIcon(hidePassword, setHidePassword)
              }
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              onBlur={field.onBlur}
              value={field.value}
              style={styles.input}
            />
          )}
          rules={{ minLength: 6 }}
        />

        {errors.password && (
          <Text style={{ color: errorColor }}>
            Le mot de passe doit comporter au moins 6 caractère.
          </Text>
        )}

        <Button
          disabled={saveLoading}
          status={'primary'}
          accessoryLeft={saveLoading ? LoadingIndicator : undefined}
          onPress={handleSubmit(updateCustomer)}
          style={{
            borderColor: 'transparent',
            marginTop: 20
          }}>
          {saveLoading ? 'Enregistrement en cours' : 'VALIDER'}
        </Button>
      </View>

      {showSuccess && fadeAnimation && (
        <Animated.View style={{ opacity: fadeAnimation }}>
          <Text status={'success'} style={styles.successMessage}>
            Profil modifié avec succès
          </Text>
        </Animated.View>
      )}
      {showError && (
        <Text>
          Problème lors de l'enregistrement. Veuillez essayer à nouveau plus
          tard.
        </Text>
      )}
      <AppVersion color={'gray'} />
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10
  },
  input: {
    marginBottom: 5
  },
  successMessage: {
    fontWeight: 'bold'
  },
  pictureContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20
  },
  ImageShape: {
    backgroundColor: 'white',
    height: 90,
    width: 90,
    borderRadius: 90
  },
  iconButton: {
    left: 60,
    top: 70,
    backgroundColor: 'white',
    height: 30,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    zIndex: 1000
  }
})
