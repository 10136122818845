import React from 'react'
import { StyleSheet, View } from 'react-native'
import { FloatingActionButton } from 'src/components/atoms/FloatingActionButton'
import { CustomersList } from 'src/components/customers'
import { navigate } from 'src/navigations/RootNavigation'

export const CustomerScreen = () => {
  return (
    <View style={styles.container}>
      <CustomersList />
      <FloatingActionButton
        iconName={'plus'}
        onPress={() => navigate('CustomerFormScreen')}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
})
