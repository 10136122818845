import { MaterialCommunityIcons } from '@expo/vector-icons'
import { IconProps } from '@ui-kitten/components'
import React from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { defaultColor } from 'src/utils/color'
import { LoadingIndicator } from './LoadingIndicator'

type PropsType = {
  loading?: boolean
  iconName: IconProps
  onPress: () => void
  color?: string
  backGroundColor?: string
}

export const FloatingActionButton = ({
  iconName,
  onPress,
  color,
  backGroundColor,
  loading
}: PropsType) => {
  return (
    <View style={styles.buttonIcon}>
      <TouchableOpacity
        disabled={loading}
        style={[
          styles.iconContainer,
          { backgroundColor: backGroundColor ?? defaultColor }
        ]}
        onPress={onPress}>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <MaterialCommunityIcons
            name={iconName}
            color={color ?? 'white'}
            size={25}
          />
        )}
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonIcon: {
    position: 'absolute',
    alignSelf: 'flex-end',
    bottom: 10,
    paddingHorizontal: 10
  },
  iconContainer: {
    height: 50,
    width: 50,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center'
  }
})
