import { Text, Toggle } from '@ui-kitten/components'
import { doc, setDoc } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { formatDate } from 'src/utils/date/formatDate'
import { StatusListItem } from './StatusListItem'

export const StatusList = () => {
  const {
    userData,
    userClaims,
    firestore,
    presenceModuleData,
    baseModuleData
  } = useContext(FirebaseContext)
  const [lastDate, setLastDate] = useState<string>('appliquer un statut')
  const [enableNotification, setEnableNotification] = useState<boolean>(false)

  useEffect(() => {
    if (userData?.latestStatus?.updatedAt) {
      const date = userData?.latestStatus?.updatedAt?.toDate()
      if (userData?.preferences?.isNotificationEnable)
        setEnableNotification(userData?.preferences?.isNotificationEnable)

      setLastDate(formatDate(date!!))
    }
    setEnableNotification(userData?.preferences.isNotificationEnable ?? false)
  }, [userData])

  const savePreference = async (checked: boolean) => {
    setEnableNotification(checked)
    try {
      await setDoc(
        doc(firestore, userClaims?.userPath!),
        {
          preferences: {
            isNotificationEnable: checked
          }
        },
        { merge: true }
      )
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      <Text style={styles.sectionTitle}> Sélectionner votre statut</Text>
      <View style={styles.status}>
        {presenceModuleData?.statuses?.length! > 0 ? (
          presenceModuleData?.statuses?.map((status, key) => (
            <StatusListItem
              telegramChatId={baseModuleData?.settings?.telegramChatId ?? ''}
              latestStatus={userData?.latestStatus!}
              isSelected={userData?.latestStatus?.key === status.key}
              status={status}
              key={key}
            />
          ))
        ) : (
          <Text style={{ alignSelf: 'center', marginBottom: 10 }}>
            Aucun disponible
          </Text>
        )}
      </View>
      {presenceModuleData?.statuses && (
        <Text>Dernière mise à jour: {lastDate}</Text>
      )}
      {presenceModuleData?.statuses &&
        userData?.preferences.showNotificationPreference && (
          <View style={styles.notificationSection}>
            <Toggle
              checked={enableNotification}
              onChange={savePreference}
              style={{
                transform: [{ scale: 0.6 }],
                position: 'absolute',
                left: -10
              }}
            />
            <Text style={{ marginLeft: 45 }}>Recevoir les notifications </Text>
          </View>
        )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FEFCFB',
    flex: 1,
    padding: 10,
    paddingBottom: 5
  },
  status: {
    marginTop: wp(5),
    justifyContent: 'space-around'
  },
  sectionTitle: {
    fontWeight: 'bold'
  },
  notificationSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 15
  }
})
