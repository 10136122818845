import { Animated } from 'react-native'

export const fadeIn = (fadeAnimation: any) => {
  Animated.timing(fadeAnimation, {
    toValue: 1,
    duration: 1000,
    useNativeDriver: false
  }).start()
}

export const fadeOut = (
  fadeAnimation: any,
  setShowSuccess: (value: boolean) => void
) => {
  Animated.timing(fadeAnimation, {
    toValue: 0,
    duration: 2000,
    useNativeDriver: false
  }).start(() => {
    setShowSuccess(false)
  })
}
