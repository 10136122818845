import { collection, orderBy, query } from 'firebase/firestore'
import React, { useContext, useEffect } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { FlatList, Text, View } from 'react-native'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { ReceiptListItem } from 'src/components/receipt/ReceiptListItem'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Receipt } from 'src/types/businessman/entities/Receipt'
import getSubCollectionPath from 'src/utils/firebase/path/getSubCollectionPath'

export const ReceiptList = () => {
  const { firestore, userClaims, setCountReceipts } =
    useContext(FirebaseContext)
  const [receipts, loading, error] = useCollectionData<Receipt>(
    // @ts-ignore
    query(
      collection(firestore, getSubCollectionPath(userClaims, 'receipts')),
      orderBy('emittedAt', 'desc')
    ),
    {
      idField: 'id'
    }
  )

  useEffect(() => {
    setCountReceipts(receipts?.length)
  }, [receipts])

  const renderEmptyComponent = () => (
    <View style={{ flex: 1, alignSelf: 'center' }}>
      <Text>Aucune quittance disponible </Text>
    </View>
  )
  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen />
  }

  return (
    <View style={{ flex: 1, margin: 5 }}>
      <FlatList
        ListEmptyComponent={renderEmptyComponent}
        data={receipts}
        renderItem={({ item, index }) => (
          <ReceiptListItem data={item} key={index} />
        )}
      />
    </View>
  )
}
