import { config } from 'src/config/businessman'
import { UserClaims } from 'src/types/businessman'
import getEnterprisePath from 'src/utils/firebase/path/getEnterprisePath'

/**
 * Return example :
 * enterprises/abc/settings/presence
 */
export default function getSettingsDocPath(
  userClaims: UserClaims | undefined,
  moduleId: string
): string {
  return `${getEnterprisePath(userClaims)}/${config.collection(
    'settings'
  )}/${moduleId}`
}
