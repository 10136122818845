import Constants from 'expo-constants'
import React, { useContext, useState } from 'react'
import { ManualPaymentView } from 'src/components/payment/ManualPaymentView'
import { OrangeMoneyView } from 'src/components/payment/OrangeMoneyView'
import { config } from 'src/config/businessman'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Module, RenewSubscriptionData } from 'src/types/businessman'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'

export const PaymentScreen = ({ route }) => {
  const { enterpriseData, userEnterpriseId } = useContext(FirebaseContext)
  const [phoneNumber, setPhoneNumber] = useState(
    Constants?.manifest?.extra?.applicationContext === 'dev' ? '06373838' : ''
  )
  const [otp, setOtp] = useState('')
  const [paymentMessage, setPaymentMessage] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [confirmationMessage, setConfirmationMessage] = useState<string>('')
  const {
    selectedModules,
    totalPrice,
    subscriptionType,
    modules,
    voucher,
    paymentMethod
  } = route.params

  const makePayment = async () => {
    const url = Constants.manifest?.extra?.paymentApiUrl

    const data = {
      client_number: phoneNumber,
      payment_amount: totalPrice.toString(),
      otp: otp
    }

    try {
      setLoading(true)

      if (paymentMethod === 'orangeMoney') {
        setShowModal(true)
        const result = await fetch(url, {
          method: 'POST',
          mode: 'no-cors',
          headers: new Headers({
            'Content-Type': 'application/json; charset=utf-8',
            Accept: '*/*'
          }),
          body: JSON.stringify(data)
        })

        const responseData = await result.json()
        if (responseData.payment_status === 200) {
          await updateSubscription()
          setPaymentMessage(responseData.message)
        } else {
          setPaymentMessage(responseData.message)
        }
      } else {
        await updateSubscription()
        setPaymentSuccess(true)
      }

      setLoading(false)
    } catch (e) {
      setPaymentMessage("Une erreur s'est produite, Vueillez reéssayer")
      setLoading(false)
    }
  }

  const updateSubscription = async () => {
    let currentModules = []
    for (let i in enterpriseData?.modules) {
      // @ts-ignore
      if (enterpriseData?.modules[i] === true) {
        let moduleIndex = modules.findIndex((mod: Module) => mod.code === i)
        currentModules.push(modules[moduleIndex])
      }
    }

    let data: RenewSubscriptionData = {
      newModules: selectedModules,
      subscriptionType,
      totalPrice,
      currentModules,
      voucher: voucher,
      confirmationMessage: confirmationMessage ?? '',
      paymentMethod: paymentMethod,
      phoneNumber: phoneNumber
    }

    try {
      //enterpriseSchema.parse(data) // todo adama to be tested
      await getFunctionCallable('persistence', {
        operations: [
          {
            collection: 'enterprises',
            action: 'renewSubscription',
            enterprisePath: `${config.collection(
              'enterprises'
            )}/${userEnterpriseId}`,
            data: data
          }
        ]
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      {paymentMethod === 'orangeMoney' ? (
        <OrangeMoneyView
          otp={otp}
          setOtp={setOtp}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          showModal={showModal}
          setShowModal={setShowModal}
          loading={loading}
          submit={makePayment}
          paymentMessage={paymentMessage}
        />
      ) : (
        <ManualPaymentView
          setPhoneNumber={setPhoneNumber}
          paymentSuccess={paymentSuccess}
          submit={makePayment}
          saveLoading={loading}
          totalPrice={totalPrice}
          confirmationMessage={confirmationMessage}
          setConfirmationMessage={setConfirmationMessage}
        />
      )}
    </>
  )
}
