import { Button, Text } from '@ui-kitten/components'
import { doc, setDoc } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { Animated, StyleSheet, View } from 'react-native'
import { AppVersion } from 'src/components/atoms/AppVersion'
import { FloatingActionButton } from 'src/components/atoms/FloatingActionButton'
import { PresenceModuleSettings } from 'src/components/settings/PresenceModuleSettings'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { ModuleBase, ModulePresence } from 'src/types/businessman'
import { fadeIn, fadeOut } from 'src/utils/animation'
import getSettingsDocPath from 'src/utils/firebase/path/getSettingsDocPath'
import { updateApp } from 'src/utils/updateApp'

const initialParameter: any = {
  settings: {
    showPresencesForAll: false,
    showPastPresencesForAll: false,
    preferredCommunicationChannel: ''
  }
}

const initialBaseData: any = {
  settings: {
    telegramChatId: ''
  }
}

export const ManagerPreferencesView = () => {
  const { userClaims, firestore, presenceModuleData, baseModuleData } =
    useContext(FirebaseContext)
  const [fadeAnimation] = useState(new Animated.Value(1))
  const [showSuccess, setShowSuccess] = useState(false)
  const [data, setData] = useState<ModulePresence>(initialParameter)
  const [baseFormData, setBaseFormData] = useState<ModuleBase>(initialBaseData)

  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (presenceModuleData) {
      setData(presenceModuleData)
    }
    if (baseModuleData) {
      setBaseFormData(baseModuleData)
    }
  }, [presenceModuleData, baseModuleData])

  useEffect(() => {
    if (showSuccess) {
      const interval = setInterval(() => {
        fadeOut(fadeAnimation, setShowSuccess)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [showSuccess])

  const saveSettings = async () => {
    const presenceModuleData: Omit<ModulePresence, 'active' | 'statuses'> = {
      settings: {
        showPastPresencesForAll:
          data?.settings?.showPastPresencesForAll ?? false,
        showPresencesForAll: data?.settings?.showPresencesForAll ?? false,
        preferredCommunicationChannel:
          data?.settings?.preferredCommunicationChannel ?? ''
      }
    }
    const baseModuleData: Omit<ModuleBase, 'active'> = {
      settings: {
        telegramChatId: baseFormData?.settings?.telegramChatId
      }
    }

    try {
      setLoading(true)
      await setDoc(
        doc(firestore, getSettingsDocPath(userClaims, 'presence')),
        presenceModuleData,
        {
          merge: true
        }
      )

      await setDoc(
        doc(firestore, getSettingsDocPath(userClaims, 'base')),
        baseModuleData,
        {
          merge: true
        }
      )
      setShowSuccess(true)
      fadeIn(fadeAnimation)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <PresenceModuleSettings
        baseData={baseFormData}
        setBaseData={setBaseFormData}
        presenceData={data}
        setPresenceData={setData}
      />
      {showSuccess && fadeAnimation && (
        <Animated.View
          style={{
            marginTop: 20,
            opacity: fadeAnimation,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Text status={'success'} style={styles.successMessage}>
            Paramètres enregistré avec succès
          </Text>
        </Animated.View>
      )}
      <View style={{ marginTop: 100, margin: 10 }}>
        <AppVersion color={'gray'} />
        <Button status={'control'} onPress={updateApp}>
          Mettre à jour l'application
        </Button>
      </View>
      <FloatingActionButton
        loading={loading}
        iconName={'content-save'}
        onPress={saveSettings}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  successMessage: {
    fontWeight: 'bold'
  }
})
