import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

export const uploadImage = async (
  fileUri: string,
  bucketPath: string,
  fileName: string
) => {
  const blob: Blob = await new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()
    request.onload = function () {
      resolve(request.response)
    }
    request.onerror = function (e) {
      reject(new TypeError('Network request failed'))
    }
    request.responseType = 'blob'
    request.open('GET', fileUri, true)
    request.send(null)
  })

  const fileRef = ref(getStorage(), `${bucketPath}/${fileName}`)
  await uploadBytes(fileRef, blob)

  return await getDownloadURL(fileRef)
}
