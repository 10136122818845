import { Text } from '@ui-kitten/components'
import React from 'react'
import { TouchableOpacity } from 'react-native'

type PropsType = {
  title: string
  value: number
  mt?: number
  ml?: number
  mr?: number
  mb?: number
  onPress: () => void
}

export const DashboardItem = ({
  title,
  value,
  onPress,
  mr,
  mb,
  ml,
  mt
}: PropsType) => {
  return (
    <TouchableOpacity
      style={{
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 1 },
        shadowOpacity: 0.2,
        elevation: 5,
        width: '48%',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderRadius: 4,
        marginLeft: ml,
        marginTop: mt,
        marginRight: mr,
        marginBottom: mb,
        alignItems: 'center',
        padding: 15
      }}
      onPress={onPress}>
      <Text style={{ fontWeight: '700' }}>{value}</Text>
      <Text style={{ marginTop: 5 }}>{title}</Text>
    </TouchableOpacity>
  )
}
