import { collection, orderBy, query, where } from 'firebase/firestore'
import React, { useContext } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { FlatList, StyleSheet, Text, View } from 'react-native'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { PresenceListItem } from 'src/components/presences/PresenceListItem'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { User } from 'src/types/businessman'
import getSubCollectionPath from 'src/utils/firebase/path/getSubCollectionPath'

export const PresenceList = () => {
  const { userClaims, firestore } = useContext(FirebaseContext)
  const [users, loading, error] = useCollectionData<User>(
    // @ts-ignore
    query(
      collection(firestore, getSubCollectionPath(userClaims, 'users')),
      where('preferences.showInPresenceList', '==', true),
      orderBy('firstName')
    ),
    {
      idField: 'id'
    }
  )

  const renderEmptyComponent = () => (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
      }}>
      <Text>Aucun collaborateur</Text>
    </View>
  )

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen />
  }

  return (
    <View style={styles.container}>
      <FlatList
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={renderEmptyComponent}
        keyExtractor={(item) => item.id!}
        data={users}
        renderItem={(item) => <PresenceListItem user={item.item} />}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FEFCFB',
    flex: 1,
    padding: 10
  }
})
