import Constants from 'expo-constants'
import 'firebase/auth'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import firebase from 'firebase/compat'
import 'firebase/firestore'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import 'firebase/functions'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import 'firebase/storage'

let firebaseApp: firebase.app.App

if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp({
    apiKey: Constants.manifest?.extra?.apiKey,
    authDomain: Constants.manifest?.extra?.authDomain,
    projectId: Constants.manifest?.extra?.projectId,
    storageBucket: Constants.manifest?.extra?.storageBucket,
    messagingSenderId: Constants.manifest?.extra?.messagingSenderId,
    appId: Constants.manifest?.extra?.appId,
    measurementId: Constants.manifest?.extra?.measurementId
  })
  if (Constants.manifest?.extra?.applicationContext === 'local') {
    const origin =
      Constants.manifest?.debuggerHost?.split(':').shift() || 'localhost'

    // Firestore
    connectFirestoreEmulator(getFirestore(firebaseApp), origin, 3600)

    // // Cloud Function
    connectFunctionsEmulator(getFunctions(firebaseApp), origin, 5001)

    // Auth
    connectAuthEmulator(getAuth(firebaseApp), `http://${origin}:9099`)
  }
}

export default firebaseApp!
