import { RouteProp } from '@react-navigation/native'
import { collection, orderBy, query, where } from 'firebase/firestore'
import React, { useContext } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { FlatList, StyleSheet, Text, View } from 'react-native'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Status } from 'src/types/businessman'
import getSubCollectionPath from 'src/utils/firebase/path/getSubCollectionPath'
import { PastPresenceListItem } from './PastPresenceListItem'

type DataType = {
  displayName: string
  userId: string
}
type Props = {
  route: RouteProp<{ params: DataType }>
}

export const PastPresenceList = ({ route }: Props) => {
  const { userClaims, firestore, enterpriseData } = useContext(FirebaseContext)
  const { displayName } = route.params
  const { userId } = route.params

  const [coworkerHistoryData, loading, error] = useCollectionData<Status>(
    // @ts-ignore
    query(
      collection(firestore, getSubCollectionPath(userClaims, 'pastPresences')),
      where('userId', '==', userId.trim()),
      orderBy('createdAt', 'desc')
    ),
    {
      idField: 'id'
    }
  )

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen />
  }

  const renderEmptyComponent = () => (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
      }}>
      <Text>Aucun status enregistré</Text>
    </View>
  )

  return (
    <View style={styles.container}>
      <Text style={styles.displayName}>{displayName}</Text>
      <FlatList
        ListEmptyComponent={renderEmptyComponent}
        data={coworkerHistoryData}
        renderItem={({ item }) => (
          <PastPresenceListItem
            enterpriseData={enterpriseData!}
            item={item}
            withName={false}
          />
        )}
        keyExtractor={(item) => item.id}
      />
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white'
  },
  displayName: {
    fontWeight: 'bold',
    fontSize: 18,
    padding: 10
  }
})
