const secondaryColor = '#D6D6D6'
const errorColor = '#C7052A'
const backgroundColor = '#003f5c'
const defaultColor = '#003F5C'
const dangerColor = '#fb5b5a'

export {
  errorColor,
  secondaryColor,
  backgroundColor,
  defaultColor,
  dangerColor
}
