import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ReceiptForm } from 'src/components/forms'

export const ReceiptFormScreen = () => {
  return (
    <View style={styles.container}>
      <ReceiptForm />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
})
