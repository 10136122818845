export function generateId(baseWord: string = '') {
  return baseWord.trim()
    ? sanitize(baseWord) + generateRandomNumber(0, 10) + generateRandomString()
    : generateRandomString(18)
}

function generateRandomString(length: number = 9): string {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

function generateRandomNumber(max: number, min: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function capitalizeWords(words: Array<string>) {
  return words.map((element) => {
    return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
  })
}

function sanitize(baseWord: string): string {
  const words = capitalizeWords(baseWord.split(' '))
  const id = words.join('')
  const sanitizeId = id
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\W/g, '')
    .trim()

  return sanitizeId.charAt(0).toLowerCase() + sanitizeId.slice(1)
}
