import { Text } from '@ui-kitten/components'
import React, { useState } from 'react'
import {
  Image,
  KeyboardAvoidingView,
  Platform,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Switch,
  TouchableOpacity,
  View
} from 'react-native'
import { AppVersion } from 'src/components/atoms/AppVersion'
import { EmailAndPassword, PhoneAuth } from 'src/components/signIn'
import { navigate } from 'src/navigations/RootNavigation'
import { backgroundColor } from 'src/utils/color'

export const SignInScreen = () => {
  const [isPhoneAuth, setIsPhoneAuth] = useState(false)
  const onToggleSwitch = () => setIsPhoneAuth(!isPhoneAuth)

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
      contentContainerStyle={{ minHeight: '100%' }}>
      <StatusBar barStyle={'light-content'} backgroundColor={backgroundColor} />
      <SafeAreaView style={styles.container}>
        <View
          style={{
            bottom: 60,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Image
            source={require('../../../assets/adaptive-icon.png')}
            style={{ width: 120, height: 120 }}
            width={120}
            height={120}
          />
          <Text status={'control'} category={'h6'} style={{ marginTop: 20 }}>
            BUSINESSMAN
          </Text>
        </View>
        <View style={styles.switchContainer}>
          <Text
            style={{
              fontSize: 17,
              color: 'white',
              paddingRight: 15
            }}>
            {isPhoneAuth
              ? 'Se connecter avec son adresse mail'
              : 'Se connecter avec son numéro '}
          </Text>
          <Switch value={isPhoneAuth} onValueChange={onToggleSwitch} />
        </View>
        {isPhoneAuth ? <PhoneAuth /> : <EmailAndPassword />}
        <TouchableOpacity onPress={() => navigate('SignUp')}>
          <Text
            status={'control'}
            style={{
              marginVertical: 10,
              alignSelf: 'center',
              fontSize: 13
            }}>
            Créer un compte entreprise
          </Text>
        </TouchableOpacity>
        <AppVersion
          style={{ top: 50, alignSelf: 'center' }}
          color={'#ced4da'}
        />
      </SafeAreaView>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: backgroundColor
  },
  icon: {
    textTransform: 'uppercase'
  },
  switchContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
    marginBottom: 15
  }
})
