import { MaterialCommunityIcons } from '@expo/vector-icons'
import { Button, Card, Input, Text } from '@ui-kitten/components'
import * as Location from 'expo-location'
import firebase from 'firebase/compat'
import React, { useContext, useState } from 'react'
import {
  Alert,
  Modal,
  Platform,
  SafeAreaView,
  StyleSheet,
  View
} from 'react-native'
import MapView, { Marker } from 'react-native-maps'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'
import { renderLocationIcon } from 'src/components/icons'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Status } from 'src/types/businessman'
import { defaultColor, secondaryColor } from 'src/utils/color'

type PropsType = {
  onPress: () => void
  visible: boolean
  status?: Status
  data: {
    statusMessage: string
    userLocation?: firebase.firestore.GeoPoint | undefined
  }
  setData: (data: any) => void
  setVisible: (value: boolean) => void
}

export const StatusMessageModal = ({
  onPress,
  visible,
  status,
  setVisible,
  data,
  setData
}: PropsType) => {
  const { enterpriseData } = useContext(FirebaseContext)

  const cancelIcon = () => (
    <MaterialCommunityIcons name="cancel" size={20} color={secondaryColor} />
  )
  const saveIcon = () => (
    <MaterialCommunityIcons name="content-save" size={20} color="white" />
  )
  const [loading, setLoading] = useState<boolean>(false)

  const takePosition = async () => {
    try {
      let { status } = await Location.requestForegroundPermissionsAsync()
      if (status !== 'granted') {
        Alert.alert(
          "L'autorisation d'accéder à la localisation a été refusée, veuillez verifier dans les paramètres du téléphone"
        )
        return
      }

      setLoading(true)
      let location = await Location.getCurrentPositionAsync({})
      setLoading(false)
      setData((prev: any) => ({
        ...prev,
        userLocation: new firebase.firestore.GeoPoint(
          location.coords.latitude,
          location.coords.longitude
        )
      }))
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  return (
    <Modal visible={visible} animationType={'slide'} animated={true}>
      <SafeAreaView style={{ backgroundColor: defaultColor }}>
        <Card disabled={true}>
          <View style={{ flexDirection: 'row' }}>
            <MaterialCommunityIcons
              // @ts-ignore
              name={status?.icon!!}
              size={24}
            />
            <Text style={{ fontWeight: 'bold', marginLeft: 10 }}>
              {status?.value!!}
            </Text>
          </View>
          <Input
            style={{
              marginTop: 20,
              marginBottom: 15
            }}
            multiline={true}
            selectionColor={defaultColor}
            textStyle={{ minHeight: 80, textAlignVertical: 'top' }}
            value={data.statusMessage}
            defaultValue={data.statusMessage}
            onChangeText={(value) =>
              setData((prev: any) => ({
                ...prev,
                statusMessage: value
              }))
            }
            placeholder="Entrer un message associé (optionel)"
          />

          {enterpriseData?.modules?.geolocation && Platform.OS !== 'web' && (
            <Button
              onPress={takePosition}
              accessoryLeft={loading ? LoadingIndicator : renderLocationIcon}>
              {loading ? 'Localisation en cours' : 'Ajouter position GPS'}
            </Button>
          )}

          {data.userLocation && enterpriseData?.modules?.geolocation && (
            <MapView
              style={{ height: 200, marginTop: 10 }}
              userLocationPriority={'high'}
              showsUserLocation={true}
              minZoomLevel={13}
              initialRegion={{
                latitude: data.userLocation.latitude,
                longitude: data.userLocation.longitude,
                latitudeDelta: 0.07,
                longitudeDelta: 0.07
              }}>
              <Marker
                coordinate={{
                  latitude: 12.316007535398889,
                  longitude: -1.5395170227685646
                }}
                description={enterpriseData?.address}
                title={enterpriseData?.name}
              />
            </MapView>
          )}

          <View style={styles.btnContainer}>
            <Button
              size={'small'}
              status={'basic'}
              onPress={() => {
                setData((prev: any) => ({
                  ...prev,
                  statusMessage: '',
                  userLocation: undefined
                }))
                setVisible(false)
              }}
              accessoryLeft={cancelIcon}>
              Annuler
            </Button>
            <Button size={'small'} onPress={onPress} accessoryLeft={saveIcon}>
              Sauver
            </Button>
          </View>
        </Card>
      </SafeAreaView>
    </Modal>
  )
}

const styles = StyleSheet.create({
  btnContainer: {
    flexDirection: 'row',
    marginTop: 60,
    justifyContent: 'space-evenly'
  }
})
