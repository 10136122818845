import { collection, orderBy, query } from 'firebase/firestore'
import React, { useContext } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { FlatList, StyleSheet, Text, View } from 'react-native'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { PastPresenceListItem } from 'src/components/pastPresences/PastPresenceListItem'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Status } from 'src/types/businessman'
import getSubCollectionPath from 'src/utils/firebase/path/getSubCollectionPath'

export const PastPresencesScreen = () => {
  const { userClaims, firestore, enterpriseData } = useContext(FirebaseContext)
  const [coworkerHistoryData, loading, error] = useCollectionData<Status>(
    // @ts-ignore
    query(
      collection(firestore, getSubCollectionPath(userClaims, 'pastPresences')),
      orderBy('createdAt', 'desc')
    ),
    {
      idField: 'id'
    }
  )

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen />
  }

  const renderEmptyComponent = () => (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
      }}>
      <Text>Aucun status enregistré</Text>
    </View>
  )

  return (
    <View style={styles.container}>
      <FlatList
        ListEmptyComponent={renderEmptyComponent}
        data={coworkerHistoryData}
        renderItem={({ item }) => (
          <PastPresenceListItem
            enterpriseData={enterpriseData!}
            item={item}
            withName={true}
          />
        )}
        keyExtractor={(item) => item.id}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white'
  },
  displayName: {
    fontWeight: 'bold',
    fontSize: 18,
    padding: 10
  },
  select: {
    flex: 1,
    margin: 2
  }
})
