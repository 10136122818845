import { FontAwesome } from '@expo/vector-icons'
import { CheckBox } from '@ui-kitten/components'
import React, { useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { widthPercentageToDP } from 'react-native-responsive-screen'
import { navigate } from 'src/navigations/RootNavigation'
import { Product } from 'src/types/businessman'
import { defaultColor } from 'src/utils/color'
import { renderProductDiscountIcon } from '../../../icons'
import { ConfirmModal } from '../../../molecules/modals/ConfirmModal'

interface PropsType {
  product: Product
  enterpriseId: string
}

export const ProductItem = ({ product, enterpriseId }: PropsType) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  return (
    <View
      style={{
        paddingVertical: 5,
        backgroundColor: 'white',
        borderBottomWidth: 0.3,
        borderBottomColor: 'gray'
      }}>
      <View style={styles.container}>
        <View style={styles.contentBox}>
          <CheckBox style={styles.mr2} />
          <Text>{product.name}</Text>
        </View>
        <View style={styles.iconsContainer}>
          <TouchableOpacity onPress={() => {}}>
            {renderProductDiscountIcon()}
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.ml5}
            onPress={() => navigate('ProductFormScreen')}>
            <FontAwesome name="edit" size={24} color="black" />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.ml5}
            onPress={() => setShowDialog(true)}>
            <FontAwesome name="trash-o" size={24} color="black" />
          </TouchableOpacity>
        </View>
        <ConfirmModal
          setVisible={setShowDialog}
          visible={showDialog}
          iconName={'ios-close-circle-outline'}
          color={defaultColor}
          message={'Voulez vraiment supprimer ce produit ?'}
          onPress={() => console.log('Suppression')}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 10
  },
  btnContainer: {
    marginBottom: 5
  },
  iconsContainer: {
    width: widthPercentageToDP(30),
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  ml5: {
    marginLeft: widthPercentageToDP(5)
  },
  mr5: {
    marginRight: widthPercentageToDP(5)
  },
  mr2: {
    marginRight: widthPercentageToDP(2)
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})
