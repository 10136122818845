import { Divider, Modal, Text } from '@ui-kitten/components'
import React from 'react'
import { StyleSheet, View } from 'react-native'

type PropsType = {
  visible: boolean
  setVisible: (value: boolean) => void
}
export const PaymentOptionModal: React.FC<PropsType> = ({
  visible,
  setVisible,
  children
}) => {
  return (
    <Modal
      style={{ width: 300 }}
      visible={visible}
      onBackdropPress={() => setVisible(false)}
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
      <View style={styles.container}>
        <View style={{ paddingTop: 10, paddingHorizontal: 10 }}>
          <Text style={{ fontWeight: '700' }}>
            Sélectionner le moyen de paiement
          </Text>
          <Divider style={{ marginVertical: 10 }} />
        </View>
        <View
          style={{
            paddingHorizontal: 5,
            paddingVertical: 20,
            alignItems: 'center'
          }}>
          {children}
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderRadius: 5
  }
})
