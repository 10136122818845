import { useEffect, useState } from 'react'
import { Product } from 'src/types/businessman'

interface UseProductsType {
  products: Array<Product>
  isLoading: boolean
  hasError: boolean
}

export const useProducts = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const [products, setProducts] = useState<Array<Product>>([])

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      try {
        const p: Array<Product> = [...Array(15).keys()].map((id) => {
          return {
            id: (id + 1).toString(),
            name: `Mon produit ${id + 1}`,
            categoryName: `Catégorie ${id + 1}`,
            description: `After all, both expressions would result an an N-element array of undefined elements. The difference is that in the former expression, each element is explicitly set to undefined, whereas in the latter, each element was never set`,
            active: (id + 1) % 2 === 0,
            priceCustomer:
              Math.floor(Math.random() * (65000 - 1500 + 1)) + 1500,
            category: undefined,
            images: undefined,
            files: undefined,
            mainImage: undefined
          } as unknown as Product
        })
        setProducts(p)
      } catch (e) {
        setHasError(true)
      }
      setIsLoading(false)
    }, 1000)
  }, [])

  return {
    products,
    isLoading,
    hasError
  } as UseProductsType
}
