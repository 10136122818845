import { Tab, TabView } from '@ui-kitten/components'
import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import {
  CurrentSubscription,
  PastSubscriptionList
} from 'src/components/subscriptions'

export const SubscriptionScreen = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const shouldLoadComponent = (index: number) => index === selectedIndex

  return (
    <TabView
      tabBarStyle={{
        height: 50
      }}
      style={styles.container}
      selectedIndex={selectedIndex}
      shouldLoadComponent={shouldLoadComponent}
      onSelect={(index) => setSelectedIndex(index)}>
      <Tab title="En cours">
        <CurrentSubscription />
      </Tab>
      <Tab title="Historiques">
        <PastSubscriptionList />
      </Tab>
    </TabView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 2
  },
  card: {
    margin: 0
  },
  tabContainer: {
    backgroundColor: 'transparent',
    justifyContent: 'center'
  },
  text: {
    fontSize: 16,
    fontWeight: '900',
    marginTop: 20
  },
  button: {
    borderColor: 'transparent',
    marginTop: 15
  }
})
