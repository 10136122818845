import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import {
  Button,
  IconProps,
  IndexPath,
  Input,
  Select,
  SelectItem,
  Text
} from '@ui-kitten/components'
import { doc, setDoc } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'
import { ConfirmModal } from 'src/components/molecules/modals/ConfirmModal'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { defaultColor } from 'src/utils/color'
import getSettingsDocPath from 'src/utils/firebase/path/getSettingsDocPath'
import { StatusType } from 'src/utils/types/StatusType'

const colors = [
  {
    code: 'green',
    label: 'vert'
  },
  {
    code: 'red',
    label: 'rouge'
  },
  {
    code: '#FFD505',
    label: 'jaune'
  },
  {
    code: 'gray',
    label: 'gris'
  }
]

const icons = [
  {
    code: 'office-building',
    label: 'immeuble de bureaux'
  },
  {
    code: 'circle-off-outline',
    label: 'cercle vide'
  },
  {
    code: 'home',
    label: 'Maison'
  },
  {
    code: 'heart-pulse',
    label: 'pouls cardiaque'
  },
  {
    code: 'account-group',
    label: 'réunion'
  }
]

export const EnterpriseStatuses = () => {
  const { firestore, userClaims, presenceModuleData } =
    useContext(FirebaseContext)
  const [showForm, setShowForm] = useState(false)
  const [colorSelectedIndex, setColorSelectedIndex] = useState<IndexPath>()
  const [iconSelectedIndex, setIconSelectedIndex] = useState<IndexPath>()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState<StatusType>()
  const [statuses, setStatuses] = useState<StatusType[]>([])
  const [selectedStatus, setSelectedStatus] = useState<StatusType>()
  const [editStatusIndex, setEditStatusIndex] = useState<number | undefined>(
    undefined
  )

  useEffect(() => {
    if (presenceModuleData?.statuses) {
      setStatuses(presenceModuleData.statuses)
    }
  }, [presenceModuleData])

  // @ts-ignore
  const displayColorValue = colors[colorSelectedIndex?.row]
  // @ts-ignore
  const displayIconValue = icons[iconSelectedIndex?.row]

  const renderColors = (
    color: { code: string; label: string },
    index: number
  ) => (
    <SelectItem
      title={color.label}
      key={index}
      accessoryLeft={() => (
        <View style={{ backgroundColor: color.code, height: 20, width: 20 }} />
      )}
    />
  )

  const renderIcons = (
    icon: { code: IconProps; label: string },
    index: number
  ) => (
    <SelectItem
      title={icon.label}
      key={index}
      accessoryLeft={() => (
        <MaterialCommunityIcons name={icon.code} size={24} />
      )}
    />
  )

  const resetData = () => {
    setFormData(undefined)
    setEditStatusIndex(undefined)
    setIconSelectedIndex(undefined)
    setColorSelectedIndex(undefined)
  }

  const editStatus = (status: StatusType, statusIndex: number) => {
    setFormData({
      label: status.label,
      icon: status.icon,
      color: status.color
    })
    let iconIndex = icons.findIndex((e) => e.code === status.icon)
    let colorIndex = colors.findIndex((e) => e.code === status.color)
    if (iconIndex >= 0) {
      setIconSelectedIndex(new IndexPath(iconIndex))
    }

    if (colorIndex >= 0) {
      setColorSelectedIndex(new IndexPath(colorIndex))
    }
    setEditStatusIndex(statusIndex)
    setShowForm(true)
  }

  const saveForm = async (newStatuses?: StatusType[]) => {
    try {
      setLoading(true)
      let updatedStatuses = []
      if (newStatuses) {
        updatedStatuses = newStatuses.map((status) => {
          return {
            active: true,
            key: status?.icon + Date.now().toString(),
            ...status
          }
        })
      } else {
        const newStatus = {
          active: true,
          key: formData?.icon + Date.now().toString(),
          ...formData
        }
        if (editStatusIndex) {
          let stats = statuses
          stats[editStatusIndex] = newStatus
          updatedStatuses = stats
        } else {
          updatedStatuses = [...statuses, newStatus]
        }
      }

      await setDoc(
        doc(firestore, getSettingsDocPath(userClaims, 'presence')),
        {
          statuses: updatedStatuses
        },
        {
          merge: true
        }
      )

      setLoading(false)
      setShowForm(false)
      resetData()
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <ScrollView style={styles.container}>
      {showForm ? (
        <View>
          <Input
            clearButtonMode={'while-editing'}
            style={styles.input}
            placeholder={'Titre du statut'}
            value={formData?.label}
            onChangeText={(value: any) => {
              setFormData((prev: any) => ({
                ...prev,
                label: value
              }))
            }}
          />
          <Select
            placeholder={'Couleur du statut'}
            style={styles.input}
            selectedIndex={colorSelectedIndex}
            onSelect={(index) => {
              setColorSelectedIndex(index)
              if (index instanceof IndexPath) {
                setFormData((prev: any) => ({
                  ...prev,
                  color: colors[index?.row].code
                }))
              }
            }}
            value={displayColorValue?.label}>
            {colors.map((color, index) => renderColors(color, index))}
          </Select>
          <Select
            style={styles.input}
            placeholder={'Icon du statut'}
            selectedIndex={iconSelectedIndex}
            onSelect={(index) => {
              setIconSelectedIndex(index)
              if (index instanceof IndexPath) {
                setFormData((prev: any) => ({
                  ...prev,
                  icon: icons[index?.row].code
                }))
              }
            }}
            value={displayIconValue?.label}>
            {icons.map((icon, index) => renderIcons(icon, index))}
          </Select>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
            <Button
              status={'control'}
              onPress={() => {
                setShowForm(false)
                resetData()
              }}
              style={{
                borderColor: 'transparent',
                marginTop: 20
              }}>
              Annuler
            </Button>
            <Button
              disabled={
                !formData?.label ||
                !formData?.color ||
                !formData?.icon ||
                loading
              }
              accessoryLeft={loading ? LoadingIndicator : undefined}
              status={'primary'}
              onPress={() => saveForm()}
              style={{
                borderColor: 'transparent',
                marginTop: 20
              }}>
              {loading ? 'En cours' : 'Ajouter un statut'}
            </Button>
          </View>
        </View>
      ) : (
        <ScrollView showsVerticalScrollIndicator={true}>
          {statuses?.length > 0 ? (
            statuses.map((status, index) => (
              <View style={{ marginTop: 10, marginRight: 10 }} key={index}>
                <TouchableOpacity
                  onPress={() => {
                    setSelectedStatus(status)
                    setShowDialog(true)
                  }}
                  style={styles.deleteButton}>
                  <Ionicons
                    name="close-circle-sharp"
                    size={30}
                    color={'gray'}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.statusButton}
                  onPress={() => editStatus(status, index)}>
                  <MaterialCommunityIcons
                    style={{
                      flex: 0.38,
                      textAlign: 'right',
                      marginRight: 10
                    }}
                    name={status.icon}
                    size={24}
                    color={'black'}
                  />
                  <Text
                    style={{
                      fontWeight: '700',
                      flex: 0.68,
                      textAlign: 'left'
                    }}>
                    {status.label}
                  </Text>
                </TouchableOpacity>
                <ConfirmModal
                  setVisible={setShowDialog}
                  visible={showDialog}
                  iconName={'ios-close-circle-outline'}
                  color={defaultColor}
                  message={'Voulez vraiment supprimer cet statut ?'}
                  onPress={() => {
                    const newStatuses = statuses.filter(
                      (sts) => sts.label !== selectedStatus!.label
                    )
                    saveForm(newStatuses)
                    setShowDialog(false)
                  }}
                />
              </View>
            ))
          ) : (
            <Text>Aucun statut disponible</Text>
          )}
          <Button style={{ marginTop: 20 }} onPress={() => setShowForm(true)}>
            Ajouter un statut
          </Button>
        </ScrollView>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10
  },
  input: {
    marginBottom: 5
  },
  statusButton: {
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 13,
    borderRadius: 5,
    marginBottom: 5,
    borderWidth: 1,
    borderColor: defaultColor,
    flexDirection: 'row'
  },
  deleteButton: {
    position: 'absolute',
    alignSelf: 'flex-end',
    zIndex: 1,
    top: -10,
    right: -10
  }
})
