import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { MenuButton } from '../components/atoms/MenuButton'
import {
  CoworkerHomeScreen,
  CoworkerPastPresenceScreen,
  PreferencesScreen,
  UserProfileScreen
} from '../scenes'
import { defaultColor } from '../utils/color'

type StackParamList = {
  CoworkerHomeScene: undefined
  UserProfileScene: undefined
  PreferencesScene: undefined
  CoworkerHistoryScreen: undefined
}

const Stack = createStackNavigator<StackParamList>()
export default function CoworkerStackNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerRight: () => <MenuButton />,
        headerStyle: {
          backgroundColor: defaultColor
        },
        headerTintColor: 'white'
      }}
      initialRouteName={'CoworkerHomeScene'}>
      <Stack.Screen
        name={'CoworkerHomeScene'}
        component={CoworkerHomeScreen}
        options={{
          title: 'Businessman'
        }}
      />
      <Stack.Screen
        name={'UserProfileScene'}
        component={UserProfileScreen}
        options={{
          headerBackTitleVisible: false,
          title: 'Profile'
        }}
      />
      <Stack.Screen
        name={'PreferencesScene'}
        component={PreferencesScreen}
        options={{
          headerBackTitleVisible: false,
          title: 'Préférences'
        }}
      />
      <Stack.Screen
        name={'CoworkerHistoryScreen'}
        component={CoworkerPastPresenceScreen}
        options={{
          headerTitle: 'Historique des présences',
          headerStyle: {
            backgroundColor: defaultColor
          },
          headerTintColor: 'white',
          headerBackTitleVisible: false
        }}
      />
    </Stack.Navigator>
  )
}
