import { Button } from '@ui-kitten/components'
import React, { Fragment, useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell
} from 'react-native-confirmation-code-field'
import { LoadingIndicator } from '../../atoms/LoadingIndicator'

type Props = {
  onPress(value: string): void
  loading: boolean
}

const CELL_COUNT = 6

const CodeInput = ({ onPress, loading }: Props) => {
  const [value, setValue] = useState('')
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT })
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue
  })

  return (
    <Fragment>
      <CodeField
        ref={ref}
        {...props}
        value={value}
        caretHidden={false}
        onChangeText={setValue}
        cellCount={CELL_COUNT}
        rootStyle={styles.codeFieldRoot}
        keyboardType="phone-pad"
        textContentType="oneTimeCode"
        onSubmitEditing={() => onPress(value)}
        renderCell={({ index, symbol, isFocused }) => (
          <View
            style={[styles.cell, isFocused && styles.focusCell]}
            key={index}>
            <Text
              style={[styles.inputText]}
              key={index}
              onLayout={getCellOnLayoutHandler(index)}>
              {symbol || (isFocused ? <Cursor /> : null)}
            </Text>
          </View>
        )}
      />
      <Button
        status={'control'}
        appearance={'outline'}
        style={styles.loginButton}
        onPress={() => onPress(value)}
        accessoryRight={loading ? LoadingIndicator : undefined}>
        {loading ? 'En cours' : 'Confirmer'}
      </Button>
    </Fragment>
  )
}

const styles = StyleSheet.create({
  codeFieldRoot: { marginVertical: 20 },
  cell: {
    width: 44,
    height: 44,
    borderRadius: 22,
    alignSelf: 'center',
    borderWidth: 3,
    marginHorizontal: 5,
    borderColor: '#e3e3e3',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputText: {
    color: 'white',
    fontSize: 20
  },
  focusCell: {
    backgroundColor: '#e3e3e3'
  },
  loginButton: {
    alignSelf: 'center',
    borderColor: 'transparent'
  }
})

export default CodeInput
