import React from 'react'
import { View } from 'react-native'
import { ManagerPreferencesView } from 'src/components/managerPreferences'

export const ParameterScreen = () => {
  return (
    <View style={{ flex: 1 }}>
      <ManagerPreferencesView />
    </View>
  )
}
