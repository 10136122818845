import { Feather } from '@expo/vector-icons'
import {
  Button,
  Card,
  CheckBox,
  Divider,
  Input,
  Text
} from '@ui-kitten/components'
import firebase from 'firebase/compat'
import { collection, doc, getDoc, orderBy, query } from 'firebase/firestore'
import React, { useContext, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'
import { ModulePrice } from 'src/components/modulePrice/ModulePrice'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { PaymentOptionModal } from 'src/components/molecules/modals/PaymentOptionModal'
import { config } from 'src/config/businessman'
import { navigate } from 'src/navigations/RootNavigation'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Module, Voucher } from 'src/types/businessman'
import { addOneMonth } from 'src/utils/date/addOneMonth'
import { addOneYear } from 'src/utils/date/addOneYear'
import { localDate } from 'src/utils/date/formatDate'
import { isSubscriptionExpired } from 'src/utils/date/isSubscriptionExpired'
import getVouchersDocPath from 'src/utils/firebase/path/getVouchersDocPath'

export const CurrentSubscription = () => {
  const [selectedModules, setSelectedModules] = useState<Module[]>([])
  const [totalPrice, setTotalPrice] = useState(0)
  const { firestore, userClaims, paymentLabels } = useContext(FirebaseContext)
  const [voucher, setVoucher] = useState<Voucher | undefined>(undefined)
  const [isVoucherCodeApplied, setIsVoucherCodeApplied] = useState<
    boolean | undefined
  >(undefined)
  const [modules, loading, error] = useCollectionData<Module>(
    // @ts-ignore
    query(
      collection(firestore, config.collection('modules')),
      orderBy('sorting', 'asc')
    ),
    {
      idField: 'id'
    }
  )
  const [voucherCode, setVoucherCode] = useState('')
  const [isVoucherValid, setIsVoucherValid] = useState<boolean | undefined>(
    undefined
  )
  const [validatingVoucher, setValidatingVoucher] = useState(false)
  const [showPayment, setShowPayment] = useState(false)

  const { enterpriseData } = useContext(FirebaseContext)
  const newSubscriptionEndDate = {
    month: addOneMonth(enterpriseData?.currentSubscription?.endDate),
    year: addOneYear(enterpriseData?.currentSubscription?.endDate)
  }

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen />
  }

  const validVoucher = async () => {
    try {
      if (voucherCode.length < 2) {
        return
      }

      setValidatingVoucher(true)

      const voucherDoc = await getDoc(
        doc(firestore, getVouchersDocPath(userClaims, voucherCode))
      )

      if (voucherDoc.exists()) {
        let voucherData = voucherDoc.data() as Voucher
        voucherData.id = voucherDoc.id

        if (
          voucherData?.used ||
          voucherData?.validUntil < firebase.firestore.Timestamp.now()
        ) {
          setIsVoucherValid(false)
          setValidatingVoucher(false)
          return
        }
        setIsVoucherValid(true)
        setVoucher(voucherData)
        setTotalPrice((prev) => prev - voucherData?.amount)
        setIsVoucherCodeApplied(true)
      } else {
        setIsVoucherValid(false)
      }
      setValidatingVoucher(false)
    } catch (e) {
      setValidatingVoucher(false)
    }
  }

  return (
    <View style={styles.container}>
      <PaymentOptionModal visible={showPayment} setVisible={setShowPayment}>
        {paymentLabels?.enableOrangeMoneyApi?.value === 'true' && (
          <>
            <TouchableOpacity
              style={styles.btnContainer}
              onPress={() => {
                navigate('PaymentScreen', {
                  paymentMethod: 'orangeMoney',
                  selectedModules: selectedModules,
                  modules: modules,
                  totalPrice: totalPrice,
                  subscriptionType: 'month',
                  voucher: isVoucherCodeApplied ? voucher : undefined
                })
                setShowPayment(false)
              }}>
              <View
                style={{
                  flex: 0.2
                }}>
                <Image
                  source={require('./../../../assets/orange-money-icon.png')}
                  style={{
                    height: 30,
                    width: 30
                  }}
                />
              </View>
              <View
                style={{
                  flex: 0.7
                }}>
                <Text category={'s1'}>Orange Money (OTP)</Text>
                <Text category={'c1'}>Recevoir un code unique</Text>
              </View>
            </TouchableOpacity>
            <Divider
              style={{
                width: '90%',
                marginVertical: 5
              }}
            />
          </>
        )}
        {paymentLabels?.enableOrangeMoneyManual?.value === 'true' && (
          <>
            <TouchableOpacity
              style={styles.btnContainer}
              onPress={() => {
                navigate('PaymentScreen', {
                  paymentMethod: 'manual',
                  totalPrice: totalPrice,
                  selectedModules: selectedModules,
                  modules: modules,
                  subscriptionType: 'month',
                  voucher: isVoucherCodeApplied ? voucher : undefined
                })
                setShowPayment(false)
              }}>
              <View
                style={{
                  flex: 0.2
                }}>
                <Feather name="smartphone" color={'black'} size={25} />
              </View>
              <View
                style={{
                  flex: 0.7
                }}>
                <Text category={'s1'}>Orange Money </Text>
                <Text category={'c1'}>Copier une preuve SMS</Text>
              </View>
            </TouchableOpacity>
          </>
        )}
      </PaymentOptionModal>
      {enterpriseData?.currentSubscription?.endDate && (
        <Card style={styles.card}>
          {isSubscriptionExpired(
            enterpriseData?.currentSubscription?.endDate
          ) ? (
            <Text>
              {`Abonnement actif jusqu'au ${localDate(
                enterpriseData?.currentSubscription?.endDate?.toDate()
              )}`}
            </Text>
          ) : (
            <Text
              style={{ alignSelf: 'center' }}
              status={'warning'}>{`Abonnement expiré`}</Text>
          )}
        </Card>
      )}

      {modules?.map((module, index) => {
        if (module.code === 'receipt' || module.code === 'base') {
          return (
            <ModulePrice
              key={index}
              setTotalPrice={setTotalPrice}
              module={module}
              selectedModules={selectedModules}
              setSelectedModules={setSelectedModules}
            />
          )
        } else {
          return (
            <CheckBox
              checked={selectedModules.includes(module)}
              onChange={(check) => {
                let price = module?.pricePerMonth!
                if (check) {
                  setSelectedModules((prev: any) => [...prev, module])
                  setTotalPrice((prev) => (prev += price ?? 0))
                } else {
                  const filterData = selectedModules.filter(
                    (d) => d.code !== module.code
                  )
                  setSelectedModules(filterData)
                  setTotalPrice((prev) => (prev -= price ?? 0))
                }
              }}
              style={{ marginVertical: 5 }}
              key={index}>
              <View>
                <Text category="label">{module?.name.toUpperCase()}</Text>
                <Text category="label">
                  {`${module?.pricePerMonth?.toString()} FCFA`}
                </Text>
              </View>
            </CheckBox>
          )
        }
      })}

      <Card style={styles.card}>
        <Text>{`Prix total: ${totalPrice} CFA`}</Text>
      </Card>

      <View
        style={{
          marginTop: 10,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <Input
          style={{ flex: 0.75 }}
          size={'medium'}
          value={voucherCode}
          onChangeText={(text) => {
            setIsVoucherValid(undefined)
            setVoucherCode(text)
          }}
          placeholder={'Code de rabais'}
        />

        <Button
          disabled={isVoucherCodeApplied}
          size={'small'}
          style={{ flex: 0.2 }}
          accessoryLeft={validatingVoucher ? LoadingIndicator : undefined}
          onPress={validVoucher}>
          {validatingVoucher ? '' : 'Valider'}
        </Button>
      </View>
      {isVoucherValid !== undefined && (
        <Text status={isVoucherValid ? 'success' : 'danger'} category={'label'}>
          {isVoucherValid
            ? `Code valide, rabais de ${voucher?.amount} FCFA activé`
            : 'Code invalid ou expiré'}
        </Text>
      )}
      <Button
        status={'primary'}
        onPress={() => {
          setShowPayment(true)
        }}
        style={styles.button}>
        {enterpriseData?.currentSubscription?.totalPrice
          ? `Renouveler au prix de ${totalPrice} CFA`
          : `S'abonner au prix de ${totalPrice} CFA`}
      </Button>

      <Text category={'s2'} style={{ marginTop: 5, opacity: 0.7 }}>
        Votre abonnement sera renouvelé pour un mois jusqu'au{' '}
        {localDate(newSubscriptionEndDate.month)}
      </Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  card: {
    margin: 0
  },
  text: {
    fontSize: 16,
    fontWeight: '900',
    marginTop: 20
  },
  button: {
    borderColor: 'transparent',
    marginTop: 15
  },
  btnContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})
