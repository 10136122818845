import { format, formatDistance } from 'date-fns'
import { fr } from 'date-fns/locale'

export const formatDate = (date: Date) => {
  return format(date, 'PP à pp', { locale: fr })
}

export const dateDistance = (endDate: Date, baseDate: Date) => {
  return formatDistance(endDate, baseDate, { addSuffix: true, locale: fr })
}

export const localDate = (date: Date) => {
  return format(date, 'P', { locale: fr })
}
