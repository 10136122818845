import { Button, Card, CheckBox, Modal, Text } from '@ui-kitten/components'
import { collection, query } from 'firebase/firestore'
import React, { useContext, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Alert, Linking, ScrollView, View } from 'react-native'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'
import { config } from 'src/config/businessman'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Module, RenewSubscriptionData } from 'src/types/businessman'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'

export const FirstLoginModal = () => {
  const { enterpriseData, firestore, userData, userEnterpriseId } =
    useContext(FirebaseContext)
  const [selectedModules, setSelectedModules] = useState<Module[]>([])
  const [modules, loading] = useCollectionData<Module>(
    // @ts-ignore
    query(collection(firestore, config.collection('modules'))),
    {
      idField: 'id'
    }
  )
  const [saveLoading, setSaveLoading] = useState<boolean>(false)

  const subscribe = async () => {
    let data: RenewSubscriptionData = {
      newModules: selectedModules,
      subscriptionType: 'week',
      totalPrice: 0,
      currentModules: [],
      voucher: undefined,
      confirmationMessage: '',
      paymentMethod: '',
      phoneNumber: ''
    }

    try {
      setSaveLoading(true)
      await getFunctionCallable('persistence', {
        operations: [
          {
            collection: 'enterprises',
            action: 'renewSubscription',
            enterprisePath: `${config.collection(
              'enterprises'
            )}/${userEnterpriseId}`,
            data: data
          }
        ]
      })
      setSaveLoading(false)
      Alert.alert(
        'Félicitation',
        'Félicitation ! Vous avez une semaine pour renouveler votre abonnement'
      )
    } catch (e) {
      setSaveLoading(false)
    }
  }

  return (
    <Modal
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      style={{ width: '100%' }}
      visible={
        enterpriseData?.isFirstLogin !== undefined &&
        enterpriseData?.isFirstLogin
      }>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}>
        <ScrollView
          style={{
            margin: 15,
            width: '100%'
          }}>
          <Card
            disabled={true}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              margin: 15
            }}>
            <Text style={{ marginVertical: 10 }}>
              Bonjour {userData?.displayName ?? ''} et bienvenu !
            </Text>
            <Text style={{ marginVertical: 10 }}>
              Pour commencer veuillez choisir les modules qui seront activés
              dans l'application.
            </Text>
            {modules?.map(
              (module, index) =>
                module.code !== 'base' && (
                  <View
                    key={index}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      marginVertical: 5,
                      width: '100%'
                    }}>
                    <CheckBox
                      checked={selectedModules.includes(module)}
                      onChange={(check) => {
                        if (check) {
                          setSelectedModules((prev: any) => [...prev, module])
                        } else {
                          const filterData = selectedModules.filter(
                            (d) => d.code !== module.code
                          )
                          setSelectedModules(filterData)
                        }
                      }}
                      style={{ marginRight: 10, marginTop: 3 }}
                    />
                    <Text>{module.description}</Text>
                  </View>
                )
            )}
            <Text style={{ marginVertical: 10 }}>
              Vous recevez un crédit d'une semaine pour tester l'application.
              Vous pourrez renouveler votre compte dans le menu "abonnement".
            </Text>
            <Text>
              Pour toute question, n'hésitez pas à nous contacter au mail
              suivant:
            </Text>
            <Text
              onPress={() => {
                Linking.openURL('mailto:support@businessman.africa')
              }}
              style={{
                marginBottom: 20,
                color: 'blue',
                textDecorationLine: 'underline'
              }}>
              support@businessman.africa
            </Text>

            <Button
              disabled={saveLoading}
              accessoryLeft={saveLoading ? LoadingIndicator : undefined}
              size={'small'}
              onPress={subscribe}>
              {saveLoading ? 'En cours' : "C'est parti"}
            </Button>
          </Card>
        </ScrollView>
      </View>
    </Modal>
  )
}
