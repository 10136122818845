import Constants from 'expo-constants'
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { config } from 'src/config/businessman'

export default function getFunctionCallable(functionName: string, params = {}) {
  const functions = getFunctions(
    getApp(),
    Constants.manifest?.extra?.applicationContext === 'local'
      ? undefined
      : config.get('region')
  )
  return httpsCallable(functions, functionName, { timeout: 540000 })(params)
}
