import { MaterialCommunityIcons } from '@expo/vector-icons'
import { IconProps, Text } from '@ui-kitten/components'
import firebase from 'firebase/compat'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { Animated, StyleSheet, TouchableOpacity, View } from 'react-native'
import { config } from 'src/config/businessman'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Status } from 'src/types/businessman'
import { fadeIn, fadeOut } from 'src/utils/animation'
import { backgroundColor } from 'src/utils/color'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'
import { buildPath } from 'src/utils/firebase/path/buildPath'
import getSubCollectionPath from 'src/utils/firebase/path/getSubCollectionPath'
import { StatusMessageModal } from '../molecules/modals/StatusMessageModal'

type StatusItemType = {
  status?: Status
  latestStatus: Status
  isSelected: boolean
  telegramChatId: string
}

export const StatusListItem = ({
  status,
  latestStatus,
  isSelected,
  telegramChatId
}: StatusItemType) => {
  const { userClaims, firestore, userEnterpriseId } =
    useContext(FirebaseContext)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [fadeAnimation] = useState(new Animated.Value(1))
  const [visible, setVisible] = React.useState(false)
  const [data, setData] = useState({
    statusMessage: isSelected ? latestStatus?.message ?? '' : '',
    userLocation: isSelected
      ? !latestStatus?.userLocation?.isEqual(
          new firebase.firestore.GeoPoint(0, 0)
        )
        ? latestStatus.userLocation
        : undefined
      : undefined
  })

  const updateStatus = async () => {
    setShowError(false)
    setShowSuccess(false)
    setSaveLoading(true)

    const enterprisePath = buildPath(
      config.collection('enterprises'),
      userEnterpriseId!
    )

    try {
      //Update status
      await setDoc(
        doc(firestore, userClaims?.userPath!),
        {
          latestStatus: {
            ...status,
            message: data.statusMessage,
            userLocation:
              data.userLocation ?? new firebase.firestore.GeoPoint(0, 0),
            updatedAt: firebase.firestore.Timestamp.now()
          }
        },
        {
          merge: true
        }
      )
      setShowSuccess(true)
      setSaveLoading(false)
      fadeIn(fadeAnimation)

      // Save data in pastStatus
      let pastData: any = {
        ...status,
        userDisplayName: userClaims?.displayName,
        createdAt: firebase.firestore.Timestamp.now(),
        userId: userClaims?.user_id,
        message: data.statusMessage
      }
      if (data.userLocation) {
        pastData.userLocation = data.userLocation
      }

      await addDoc(
        collection(
          firestore,
          getSubCollectionPath(userClaims, 'pastPresences')
        ),
        pastData
      )

      setData({
        userLocation: undefined,
        statusMessage: ''
      })

      // Update presence stats
      await getFunctionCallable('presencePersistence', {
        action: 'postProcess',
        enterprisePath: enterprisePath
      })

      //Notify users
      await getFunctionCallable('notificationManager', {
        action: 'notifyUserStatus',
        data: {
          latestStatus: {
            ...status,
            message: data.statusMessage
          },
          telegramChatId: telegramChatId
        },
        documentPath: userClaims?.userPath
      })
    } catch (e) {
      console.log(e)
      setShowError(true)
      setSaveLoading(false)
    }
  }

  useEffect(() => {
    if (showSuccess) {
      const interval = setInterval(() => {
        fadeOut(fadeAnimation, setShowSuccess)
      }, 5000)
      return () => clearInterval(interval)
    }
  }, [showSuccess])

  return (
    <View style={[styles.container, isSelected && { borderWidth: 0 }]}>
      {isSelected ? (
        <TouchableOpacity
          onPress={() => setVisible(true)}
          style={[
            styles.statusButton,
            {
              borderColor: '#3AD142',
              backgroundColor: '#3AD14230',
              borderWidth: 1,
              borderRadius: 5
            }
          ]}>
          <MaterialCommunityIcons
            style={{
              flex: 0.38,
              textAlign: 'right',
              marginRight: 10
            }}
            name={status?.icon as IconProps}
            size={24}
            color={'black'}
          />
          <Text style={{ fontWeight: '700', flex: 0.68, textAlign: 'left' }}>
            {status?.label}
          </Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          style={styles.statusButton}
          onPress={() => setVisible(true)}
          disabled={saveLoading}>
          <MaterialCommunityIcons
            style={{
              flex: 0.38,
              textAlign: 'right',
              marginRight: 10
            }}
            name={status?.icon as IconProps}
            size={24}
            color={'black'}
          />
          <Text style={{ fontWeight: '700', flex: 0.68, textAlign: 'left' }}>
            {status?.label}
          </Text>
        </TouchableOpacity>
      )}
      <View>
        {showSuccess && isSelected && fadeAnimation && (
          <Animated.View style={{ opacity: fadeAnimation }}>
            <Text style={styles.successMessage}>
              Statut modifié avec succès
            </Text>
          </Animated.View>
        )}

        {showError && !showSuccess && <Text>Veuillez réessayez</Text>}
      </View>
      <StatusMessageModal
        data={data}
        setData={setData}
        setVisible={setVisible}
        status={status}
        visible={visible}
        onPress={async () => {
          setVisible(false)
          await updateStatus()
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 25,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: backgroundColor
  },
  messageContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  successMessage: {
    fontWeight: 'bold',
    color: '#3AD142'
  },
  statusButton: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 13,
    flexDirection: 'row'
  }
})
