import { createDrawerNavigator } from '@react-navigation/drawer'
import React, { useContext } from 'react'
import { DrawerButton } from 'src/components/drawer/DrawerButton'
import { DrawerContent } from 'src/components/drawer/DrawerContent'
import { FirebaseContext } from '../providers/FirebaseProvider'
import {
  CoworkerHomeScreen,
  CoworkersScreen,
  CustomerScreen,
  EnterpriseScreen,
  ManagerHomeScreen,
  ParameterScreen,
  PastPresencesScreen,
  ProductHomeScreen,
  ReceiptScreen,
  SubscriptionScreen,
  UserProfileScreen
} from '../scenes'
import { defaultColor } from '../utils/color'

const { Navigator, Screen } = createDrawerNavigator()

export const ManagerDrawerNavigator = () => {
  const { restDays } = useContext(FirebaseContext)

  return (
    <Navigator
      screenOptions={{
        swipeEnabled: restDays !== 0,
        headerStyle: {
          backgroundColor: defaultColor
        },
        headerTintColor: 'white'
      }}
      drawerContent={(props) => <DrawerContent {...props} />}>
      <Screen
        name="Businessman"
        options={{
          title: 'Businessman'
        }}
        component={ManagerHomeScreen}
      />
      <Screen
        name="myPresence"
        component={CoworkerHomeScreen}
        options={{ title: 'Ma présence' }}
      />
      <Screen
        name="myCoworkers"
        options={{
          title: 'Mes collaborateurs'
        }}
        component={CoworkersScreen}
      />
      <Screen
        name="pastPresences"
        options={{ title: 'Historiques des présences' }}
        component={PastPresencesScreen}
      />
      <Screen
        name="mySubscription"
        component={SubscriptionScreen}
        options={{
          title: 'Mon abonnement',
          headerLeft: () => (restDays === 0 ? null : <DrawerButton />)
        }}
      />
      {process.env.FEATURE_PRODUCT_ENABLED && (
        <Screen
          name="myProducts"
          options={{ title: 'Mes produits' }}
          component={ProductHomeScreen}
        />
      )}
      <Screen
        name={'myEnterprise'}
        options={{ title: 'Mon entreprise' }}
        component={EnterpriseScreen}
      />
      <Screen
        name={'myReceipts'}
        options={{ title: 'Mes quittances' }}
        component={ReceiptScreen}
      />
      <Screen
        name={'myCustomers'}
        options={{ title: 'Mes Clients' }}
        component={CustomerScreen}
      />
      <Screen
        name="myProfile"
        options={{ title: 'Mon profil' }}
        component={UserProfileScreen}
      />
      <Screen
        name="mySettings"
        options={{ title: 'Mes Paramètres' }}
        component={ParameterScreen}
      />
    </Navigator>
  )
}
