import { AntDesign } from '@expo/vector-icons'
import { Divider, Input, Modal, Text } from '@ui-kitten/components'
import React, { useEffect, useState } from 'react'
import { FlatList, TouchableOpacity, View } from 'react-native'
import { SearchItem } from 'src/components/search/SearchItem'
import { Customer } from 'src/types/businessman'
import { defaultColor } from 'src/utils/color'

type PropsType = {
  data: Customer[]
  visible: boolean
  searchTerm: string
  setSelectedCustomer: (value: any) => void
  setVisible: (value: boolean) => void
}

export const SearchModal = ({
  visible,
  setVisible,
  data,
  searchTerm,
  setSelectedCustomer
}: PropsType) => {
  const [filerScannedData, setFilterScannedData] = useState<Customer[]>([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (data) {
      setFilterScannedData(data)
    }
    if (searchTerm) {
      setSearch(searchTerm)
      searchFilter(searchTerm)
    }
  }, [data, searchTerm])

  const searchFilter = (text: string) => {
    // Check if searched text is not blank
    if (text) {
      // Inserted text is not blank
      // Filter the masterDataSource and update FilteredDataSource
      const newData = data.filter((item) => {
        // Applying filter for the inserted text in search bar
        const textData = text.toUpperCase()

        return (
          item?.lastName?.toUpperCase()?.indexOf(textData) > -1 ||
          item?.firstName?.toUpperCase()?.indexOf(textData) > -1 ||
          item?.customerEmail?.toUpperCase()?.indexOf(textData) > -1
        )
      })
      setFilterScannedData(newData)
      setSearch(text)
    } else {
      // Inserted text is blank
      // Update FilteredDataSource with data
      setFilterScannedData(data)
      setSearch(text)
    }
  }

  const renderEmptyComponent = () => (
    <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
      <Text>
        {search.length > 1 ? 'Aucune donnée trouvée' : 'Aucun enregisrement'}
      </Text>
    </View>
  )

  return (
    <Modal
      visible={visible}
      style={{ height: '90%', width: '90%' }}
      onBackdropPress={() => setVisible(false)}
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <View
        style={{
          flex: 1,
          backgroundColor: 'white',
          width: '100%',
          padding: 10,
          borderRadius: 5
        }}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Text>Rechercher</Text>
          <TouchableOpacity onPress={() => setVisible(false)}>
            <AntDesign
              style={{ alignSelf: 'flex-end' }}
              name="closecircleo"
              size={20}
              color="black"
            />
          </TouchableOpacity>
        </View>
        <Divider style={{ marginVertical: 10 }} />
        <View style={{ marginBottom: 15 }}>
          <Input
            selectionColor={defaultColor}
            value={search}
            defaultValue={search}
            onChangeText={(text) => searchFilter(text)}
            placeholder="Entrer un prénom"
          />
        </View>

        <FlatList
          style={{}}
          keyExtractor={(item) => item?.id!.toString()}
          ListEmptyComponent={renderEmptyComponent}
          ItemSeparatorComponent={() => (
            <Divider style={{ marginVertical: 10 }} />
          )}
          data={filerScannedData}
          renderItem={({ item }) => (
            <SearchItem
              setSelectedCustomer={setSelectedCustomer}
              setVisible={setVisible}
              data={item}
            />
          )}
        />
      </View>
    </Modal>
  )
}
