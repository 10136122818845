import { Ionicons } from '@expo/vector-icons'
import {
  Button,
  Card,
  Input,
  Modal,
  Spinner,
  Text
} from '@ui-kitten/components'
import React from 'react'
import {
  Image,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  View
} from 'react-native'

type PropsType = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  loading: boolean
  submit: () => void
  paymentMessage: string
  phoneNumber: string
  setPhoneNumber: (value: string) => void
  otp: string
  setOtp: (value: string) => void
}

export const OrangeMoneyView: React.FC<PropsType> = ({
  showModal,
  setShowModal,
  loading,
  submit,
  paymentMessage,
  phoneNumber,
  setPhoneNumber,
  otp,
  setOtp
}) => {
  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
      contentContainerStyle={{ minHeight: '100%' }}>
      <Modal
        visible={showModal}
        backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        {loading ? (
          <Card>
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              <Spinner />
              <Text category={'s1'} style={{ marginTop: 20 }}>
                Paiement en cours
              </Text>
            </View>
          </Card>
        ) : (
          <Card
            style={{
              marginHorizontal: 10
            }}>
            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
              <Ionicons
                name="ios-information-circle-outline"
                size={40}
                color="black"
              />
              <Text style={{ marginTop: 20 }}>{paymentMessage}</Text>
            </View>
            <Button
              style={{ marginTop: 10 }}
              onPress={() => {
                setShowModal(false)
              }}>
              OK
            </Button>
          </Card>
        )}
      </Modal>
      <View style={styles.container}>
        <View style={{ alignItems: 'center', marginBottom: 20 }}>
          <Image
            source={require('../../../assets/orange-money.png')}
            style={{ height: 100, width: 250 }}
          />
          <Text style={{ marginTop: 10 }}>
            <Text category={'s1'}>NB: </Text> Pour le renouvellement vous devez
            générer un code OTP sur votre téléphone qui contient votre compte
            orange money. Ainsi, taper:
          </Text>
          <Text
            style={{ textAlign: 'center', marginVertical: 5 }}
            category={'s1'}>
            *144*4*6*montant#
          </Text>
          <Input
            value={phoneNumber}
            onChangeText={setPhoneNumber}
            placeholder={'Numéro orange money'}
            style={styles.input}
            keyboardType={'number-pad'}
            textContentType={'telephoneNumber'}
            autoCompleteType={'cc-number'}
            returnKeyType={'next'}
          />
          <Input
            value={otp}
            onChangeText={setOtp}
            returnKeyType={'done'}
            placeholder={'Code otp'}
            style={styles.input}
            keyboardType={'number-pad'}
          />
        </View>

        <Button onPress={submit} disabled={otp === '' || phoneNumber === ''}>
          {(evaProps) => (
            <Text
              category={'s2'}
              {...evaProps}
              style={{ textTransform: 'uppercase', color: 'white' }}>
              VALIDER
            </Text>
          )}
        </Button>
      </View>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10
  },
  input: {
    marginVertical: 5
  }
})
