import { collection, orderBy, query } from 'firebase/firestore'
import React, { useContext } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { FlatList, StyleSheet, Text, View } from 'react-native'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import getSubCollectionPath from 'src/utils/firebase/path/getSubCollectionPath'
import { SubscriptionHistory } from 'src/utils/types/SubscriptionHistory'
import { PastSubscriptionListItem } from './PastSubscriptionListItem'

export const PastSubscriptionList = () => {
  const { firestore, userClaims } = useContext(FirebaseContext)
  const [
    subscriptionsHistoryData,
    isSubscriptionHistoryDataLoading,
    subscriptionHistoryDataError
  ] = useCollectionData<SubscriptionHistory>(
    // @ts-ignore
    query(
      collection(
        firestore,
        getSubCollectionPath(userClaims, 'pastSubscriptions')
      ),
      orderBy('createdAt', 'desc')
    ),
    {
      idField: 'id',
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  )

  if (isSubscriptionHistoryDataLoading) {
    return <LoadingScreen />
  }

  if (subscriptionHistoryDataError) {
    return <ErrorScreen />
  }

  const renderEmptyComponent = () => (
    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
      <Text>Aucun abonnement enregistré</Text>
    </View>
  )

  return (
    <View style={styles.container}>
      <FlatList
        data={subscriptionsHistoryData}
        ListEmptyComponent={renderEmptyComponent}
        renderItem={({ item }) => <PastSubscriptionListItem item={item} />}
        keyExtractor={(item) => item?.id!!}
      />
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    padding: 5,
    flex: 1
  }
})
