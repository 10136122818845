import { EvilIcons, Feather, Ionicons, MaterialIcons } from '@expo/vector-icons'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { dangerColor } from 'src/utils/color'

export const renderCoworkersIcon = () => (
  <Feather name="users" color={'black'} size={20} />
)

export const renderCoworkerIcon = () => (
  <Feather name="user" color={'black'} size={20} />
)

export const renderHomeIcon = () => (
  <Feather name="home" color={'black'} size={20} />
)

export const renderEnterpriseIcon = () => (
  <Ionicons name="ios-business-outline" size={20} color="black" />
)

export const renderMapIcon = () => (
  <Feather name="map" color={'black'} size={20} />
)
export const renderSubscriptionIcon = () => (
  <Feather name="credit-card" color={'black'} size={20} />
)

export const renderSettingsIcon = () => (
  <Feather name="settings" color={'black'} size={20} />
)

export const renderLogoutIcon = () => (
  <Feather name="log-out" color={dangerColor} size={20} />
)

export const renderPreferencesIcon = () => (
  <Feather name="settings" color={'black'} size={20} />
)

export const renderPresenceIcon = () => (
  <Feather name="circle" color={'black'} size={20} />
)

export const renderProductIcon = () => (
  <Feather name="gift" color={'black'} size={20} />
)

export const renderProductDiscountIcon = (color?: string, size?: number) => (
  <Ionicons
    name="megaphone-outline"
    size={size || 20}
    color={color || 'black'}
  />
)

export const renderSearchIcon = () => (
  <EvilIcons name="search" size={20} color="black" />
)

export const renderPostStatusIcon = () => (
  <MaterialIcons name="history" size={20} color="black" />
)

export const renderLocationIcon = () => (
  <MaterialIcons name="add-location-alt" size={20} color="white" />
)

export const renderParameterIcon = () => (
  <Feather name="settings" size={20} color="black" />
)

export const renderReceiptIcon = () => (
  <Ionicons name="receipt-outline" size={20} color="black" />
)

export const renderEyeIcon = (
  hidePassword: boolean,
  setHidePassword: (value: boolean) => void
) => (
  <TouchableOpacity onPress={() => setHidePassword(!hidePassword)}>
    <Ionicons
      name={hidePassword ? 'ios-eye-outline' : 'ios-eye-off-outline'}
      size={20}
      color="black"
    />
  </TouchableOpacity>
)
