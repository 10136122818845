import { Tab, TabView } from '@ui-kitten/components'
import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { EditFormData } from './EditFormData'
import { EditImages } from './EditImages'

export const FormTabView = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const shouldLoadComponent = (index: number) => index === selectedIndex

  return (
    <TabView
      tabBarStyle={{
        height: 50
      }}
      style={styles.container}
      selectedIndex={selectedIndex}
      shouldLoadComponent={shouldLoadComponent}
      onSelect={(index) => setSelectedIndex(index)}>
      <Tab title={'Données'}>
        <EditFormData />
      </Tab>
      <Tab title={'Images'}>
        <EditImages />
      </Tab>
    </TabView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 4
  }
})
