import { Input, Tab, TabView, Text } from '@ui-kitten/components'
import { doc, setDoc } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { Animated, Platform, ScrollView, StyleSheet, View } from 'react-native'
import { FloatingActionButton } from 'src/components/atoms/FloatingActionButton'
import { EnterpriseLocation } from 'src/components/enterprise/EnterpriseLocation'
import { EnterpriseReceipt } from 'src/components/enterprise/EnterpriseReceipt'
import { EnterpriseStatuses } from 'src/components/enterprise/EnterpriseStatuses'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { fadeIn, fadeOut } from 'src/utils/animation'
import getEnterprisePath from 'src/utils/firebase/path/getEnterprisePath'

export const EnterpriseScreen = () => {
  const { enterpriseData, firestore, userClaims } = useContext(FirebaseContext)
  const [data, setData] = useState({
    address: enterpriseData?.address ?? '',
    name: enterpriseData?.name ?? '',
    location: enterpriseData?.location ?? undefined
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [fadeAnimation] = useState(new Animated.Value(1))
  const [showSuccess, setShowSuccess] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    if (showSuccess) {
      const interval = setInterval(() => {
        fadeOut(fadeAnimation, setShowSuccess)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [showSuccess])

  const saveData = async () => {
    setLoading(true)
    try {
      await setDoc(
        doc(firestore, getEnterprisePath(userClaims)),
        {
          address: data.address,
          name: data.name,
          location: data.location ?? null
        },
        { merge: true }
      )

      setLoading(false)
      setShowSuccess(true)
      fadeIn(fadeAnimation)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <View style={styles.container}>
      <TabView
        swipeEnabled={false}
        style={{ flex: 1 }}
        tabBarStyle={{
          height: 50
        }}
        selectedIndex={selectedIndex}
        onSelect={(index) => setSelectedIndex(index)}>
        <Tab title={'Localisation'}>
          {enterpriseData?.modules?.geolocation && Platform.OS !== 'web' ? (
            <View style={{ flex: 1 }}>
              <ScrollView>
                <Input
                  style={styles.input}
                  placeholder={'Nom'}
                  value={data.name}
                  onChangeText={(value) =>
                    setData((prev: any) => ({ ...prev, name: value }))
                  }
                />
                <Input
                  style={styles.input}
                  placeholder={'Addresse'}
                  value={data.address}
                  onChangeText={(value) =>
                    setData((prev: any) => ({ ...prev, address: value }))
                  }
                />
                <EnterpriseLocation data={data} setData={setData} />
                {data.location?.latitude && data.location?.latitude && (
                  <Text category={'s2'}>
                    Latitude: {data.location.latitude.toFixed(3)}, Longitude:
                    {data.location.longitude.toFixed(3)}
                  </Text>
                )}
                <Text
                  category={'s1'}
                  style={{
                    backgroundColor: '#a3cef1',
                    padding: 5,
                    marginTop: 5
                  }}>
                  Veuillez cliquer sur la carte pour sélectionner l'emplacement
                  de votre entreprise.
                </Text>
              </ScrollView>
              <FloatingActionButton
                loading={loading}
                onPress={saveData}
                iconName={'content-save'}
              />
            </View>
          ) : (
            <Text category={'s2'} style={{ marginTop: 10 }}>
              {Platform.OS === 'web'
                ? "Veuillez utiliser l'application mobile pour cette fonctionnalité"
                : 'Veuillez vous abonner au module de géolocalisation pour utiliser cette fonctionnalité'}
            </Text>
          )}
        </Tab>
        <Tab title={'Statuts'}>
          <EnterpriseStatuses />
        </Tab>
        <Tab title={'Quittance'}>
          <EnterpriseReceipt />
        </Tab>
      </TabView>
      {showSuccess && fadeAnimation && (
        <Animated.View
          style={{
            bottom: 25,
            opacity: fadeAnimation,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Text status={'success'} style={styles.successMessage}>
            Données mises à jour avec succès
          </Text>
        </Animated.View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 2
  },
  input: {
    marginTop: 5,
    borderColor: 'transparent'
  },
  successMessage: {
    fontWeight: 'bold'
  }
})
