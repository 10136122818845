import { Button, Card, Modal } from '@ui-kitten/components'
import React, { useContext, useState } from 'react'
import { Text } from 'react-native'
import { navigate } from 'src/navigations/RootNavigation'
import { FirebaseContext } from 'src/providers/FirebaseProvider'

export const ExpiredSubscriptionModal = () => {
  const { userClaims } = useContext(FirebaseContext)
  const [showModal, setShowModal] = useState(true)

  return (
    <Modal
      visible={showModal}
      backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      {userClaims?.roles.includes('manager') && (
        <Card>
          <Text style={{ marginBottom: 10 }}>Votre abonnement a expiré</Text>
          <Button
            size={'small'}
            onPress={() => {
              navigate('mySubscription')
              setShowModal(false)
            }}>
            Renouveler
          </Button>
        </Card>
      )}
    </Modal>
  )
}
