import { Button, Input, Text } from '@ui-kitten/components'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import React, { useState } from 'react'
import { KeyboardAvoidingView, Platform, StyleSheet, View } from 'react-native'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'
import { ErrorMessage } from 'src/components/molecules/errors/ErrorMessage'
import { SuccessModal } from 'src/components/molecules/modals/SuccessModal'

export const ResetPasswordScreen = () => {
  const [email, setEmail] = useState('')
  const [sendEmailError, setEmailError] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const sendEmail = () => {
    if (email.length > 6) {
      setLoading(true)
      sendPasswordResetEmail(getAuth(), email)
        .then((res) => {
          setShowSuccess(true)
          setLoading(false)
        })
        .catch((e) => {
          setEmailError(e)
          setLoading(false)
        })
    }
  }

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={-100}>
      <View style={styles.container}>
        <Text
          style={{
            textAlign: 'center'
          }}>
          Saisissez votre email pour la réinitialisation de votre mot de passe
        </Text>

        <View>
          <Input
            onChangeText={(text) => {
              setEmail(text)
              setEmailError('')
            }}
            value={email}
            size={'large'}
            clearButtonMode={'while-editing'}
            textContentType={'emailAddress'}
            keyboardType={'email-address'}
            style={{ marginTop: 50, marginBottom: 20 }}
            placeholder={'Entrer votre Email'}
          />
          <Button
            style={{ marginBottom: 20 }}
            onPress={sendEmail}
            accessoryRight={loading ? LoadingIndicator : undefined}>
            {loading ? 'Envoi en cours' : 'Envoyer'}
          </Button>
          {sendEmailError ? (
            <ErrorMessage error={sendEmailError} visible={true} />
          ) : null}
          {showSuccess ? (
            <SuccessModal
              visible={showSuccess}
              message={
                'Demande envoyée avec succès. Veuillez vérifier votre boîte e-mail. Vérifiez également votre dossier "spam" si le message n\'apparaît pas dans votre boîte de réception.'
              }
              onPress={() => setShowSuccess(false)}
            />
          ) : null}
        </View>
      </View>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FEFCFB',
    flex: 1,
    padding: 10,
    justifyContent: 'center'
  }
})
