import { CheckBox, IndexPath, Input, Text } from '@ui-kitten/components'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Animated, StyleSheet, View } from 'react-native'
import { FirebaseContext } from '../../providers/FirebaseProvider'
import { FloatingActionButton } from '../atoms/FloatingActionButton'

export const EditFormData = () => {
  {
    const { userClaims } = useContext(FirebaseContext)
    const {
      control,
      handleSubmit,
      formState: { errors },
      reset
    } = useForm()
    const [selectedIndex, setSelectedIndex] = useState<IndexPath[]>([
      new IndexPath(0)
    ])
    const [loading, setLoading] = useState<boolean>(false)
    const [fadeAnimation] = useState(new Animated.Value(1))
    const [showSuccess, setShowSuccess] = useState(false)
    const [message, setMessage] = useState<string>('')

    return (
      <View style={styles.container}>
        <View style={styles.checkBoxContainer}>
          <CheckBox />
          <Text style={{ marginLeft: 5, fontSize: 18 }}>Actif</Text>
        </View>
        <View>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                size={'large'}
                style={styles.input}
                value={field.value}
                placeholder={'Nom'}
                onChangeText={(value: any) => field.onChange(value)}
                onBlur={field.onBlur}
              />
            )}
            rules={{ required: true }}
          />
          {errors.name && <Text>Ce champ est obligatoire.</Text>}

          <Controller
            name="prix"
            control={control}
            render={({ field }) => (
              <Input
                size={'large'}
                placeholder={'Prix client'}
                onChangeText={(value: any) => field.onChange(value)}
                onBlur={field.onBlur}
                style={styles.input}
                value={field.value}
              />
            )}
            rules={{ required: true }}
          />
          {errors.prix && <Text>Ce champ est obligatoire.</Text>}

          <Controller
            name="quantity"
            control={control}
            render={({ field }) => (
              <Input
                size={'large'}
                placeholder={'Quantité'}
                onChangeText={(value: any) => {
                  field.onChange(value)
                }}
                keyboardType={'numeric'}
                onBlur={field.onBlur}
                value={field.value}
                style={styles.input}
              />
            )}
            rules={{ required: true }}
          />
          {errors.quantity && <Text>Ce champ est obligatoire.</Text>}

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input
                size={'large'}
                placeholder={'Description'}
                onChangeText={(value: any) => {
                  field.onChange(value)
                }}
                onBlur={field.onBlur}
                value={field.value}
                style={styles.input}
              />
            )}
            rules={{ required: true }}
          />
          {errors.description && <Text>Ce champ est obligatoire.</Text>}

          {showSuccess && fadeAnimation && (
            <Animated.View
              style={{
                marginTop: 20,
                opacity: fadeAnimation,
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <Text status={'success'} style={styles.successMessage}>
                {message}
              </Text>
            </Animated.View>
          )}
        </View>
        <FloatingActionButton
          loading={loading}
          iconName={'content-save'}
          // @ts-ignore
          onPress={() => console.log('Hello')}
        />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1
  },
  input: {
    marginBottom: 10,
    borderColor: 'transparent'
  },
  select: {
    borderColor: 'transparent',
    marginBottom: 50
  },
  successMessage: {
    fontWeight: 'bold'
  },
  checkBoxContainer: {
    alignItems: 'center',
    marginVertical: 15,
    flexDirection: 'row'
  }
})
