import { AntDesign, MaterialCommunityIcons } from '@expo/vector-icons'
import React, { useContext, useState } from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { widthPercentageToDP } from 'react-native-responsive-screen'
import { config } from 'src/config/businessman'
import { navigate } from 'src/navigations/RootNavigation'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { User, UserArgumentsDelete } from 'src/types/businessman'
import { defaultColor } from 'src/utils/color'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'
import { buildPath } from 'src/utils/firebase/path/buildPath'
import { ConfirmModal } from '../molecules/modals/ConfirmModal'

interface PropsType {
  coworker: User
}

export const CoworkerListItem = ({ coworker }: PropsType) => {
  const { userEnterpriseId } = useContext(FirebaseContext)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const deleteCoworker = async () => {
    try {
      setLoading(true)

      const userPath = buildPath(
        config.collection('enterprises'),
        userEnterpriseId!,
        config.collection('users'),
        coworker.id!
      )

      await getFunctionCallable('userPersistence', {
        action: 'delete',
        userPath: userPath
      } as UserArgumentsDelete)

      setShowDialog(false)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setShowDialog(false)
    }
  }

  return (
    <TouchableOpacity
      onPress={() => {
        navigate('CoworkerEditFormScreen', {
          coworker: coworker,
          name: 'Modifier collaborateur'
        })
      }}
      style={{
        paddingVertical: 5,
        backgroundColor: 'white',
        borderBottomWidth: 0.3,
        borderBottomColor: 'gray'
      }}>
      <View style={styles.container}>
        <View>
          <Text style={{ fontWeight: 'bold' }}>
            {coworker?.firstName} {coworker?.lastName}
          </Text>
          <Text style={{ paddingTop: 5 }}>
            {coworker?.latestStatus?.value ?? 'Pas de statut'}
          </Text>
        </View>
        <View style={styles.iconsContainer}>
          <TouchableOpacity
            onPress={() => {
              navigate('CoworkerEditFormScreen', {
                coworker: coworker,
                name: 'Modifier collaborateur'
              })
            }}>
            <MaterialCommunityIcons
              name="account-edit-outline"
              size={24}
              color="black"
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setShowDialog(true)}>
            <AntDesign name="deleteuser" size={24} color="black" />
          </TouchableOpacity>
        </View>
        <ConfirmModal
          loading={loading}
          setVisible={setShowDialog}
          visible={showDialog}
          iconName={'ios-close-circle-outline'}
          color={defaultColor}
          message={'Voulez vraiment supprimer cet collaborateur ?'}
          onPress={deleteCoworker}
        />
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: 10
  },
  btnContainer: {
    marginBottom: 5
  },
  iconsContainer: {
    width: widthPercentageToDP(15),
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})
