import {
  Button,
  CheckBox,
  IndexPath,
  Input,
  Select,
  SelectItem,
  Text
} from '@ui-kitten/components'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Alert,
  Animated,
  ScrollView,
  Share,
  StyleSheet,
  View
} from 'react-native'
import { renderEyeIcon } from 'src/components/icons'
import { config } from 'src/config/businessman'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import {
  User,
  UserArgumentsCreate,
  UserArgumentsUpdate
} from 'src/types/businessman'
import { fadeIn, fadeOut } from 'src/utils/animation'
import { errorColor } from 'src/utils/color'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'
import { buildPath } from 'src/utils/firebase/path/buildPath'
import { generateId } from 'src/utils/generators/generateId'
import { normalizePhoneNumber } from 'src/utils/normalizePhoneNumber'
import { FloatingActionButton } from '../../atoms/FloatingActionButton'

const roles = ['coworker', 'manager']
const appUrl =
  'https://play.google.com/store/apps/details?id=africa.declic.businessman'

interface PropsType {
  coworker?: User
}

const initialCoworkerData: any = {
  active: true,
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  roles: [],
  preferences: {
    showInPresenceList: true,
    showNotificationPreference: true
  }
}

export const CoworkerForm = ({ coworker }: PropsType) => {
  const { userClaims, userData, firestore, userEnterpriseId } =
    useContext(FirebaseContext)
  const [data, setData] = useState<User>(initialCoworkerData)
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm()
  const [selectedIndex, setSelectedIndex] = useState<IndexPath[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [fadeAnimation] = useState(new Animated.Value(1))
  const [showSuccess, setShowSuccess] = useState(false)
  const [message, setMessage] = useState<string>('')
  const [hidePassword, setHidePassword] = useState(true)

  useEffect(() => {
    if (coworker) {
      setData(coworker)
      coworker?.roles?.length === 2
        ? setSelectedIndex([new IndexPath(0), new IndexPath(1)])
        : setSelectedIndex([new IndexPath(0)])
      reset(coworker)
    }
  }, [coworker])

  useEffect(() => {
    if (showSuccess) {
      const interval = setInterval(() => {
        fadeOut(fadeAnimation, setShowSuccess)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [showSuccess])

  const displayValues = selectedIndex.map((index) => {
    return roles[index.row] === 'coworker' ? 'collaborateur' : roles[index.row]
  })

  const renderOption = (role: string, index: number) => (
    <SelectItem
      title={role === 'coworker' ? 'collaborateur' : role}
      key={index}
    />
  )

  const sendInvitation = async () => {
    try {
      await Share.share({
        title: 'Invitation à installer Businessman',
        message: `${userData?.lastName} ${userData?.firstName} vous invite à installer l'application Businessman: ${appUrl}`
      })
    } catch (error: any) {
      alert(error.message)
    }
  }

  const saveCoworker = async (formData: User) => {
    const coworkerData: User = {
      active: data.active,
      // @ts-ignore
      roles: selectedIndex.map((index) => roles[index.row]),
      phoneNumber: formData.phoneNumber ?? '',
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      // @ts-ignore
      password: formData.password ?? '',
      preferences: {
        showInPresenceList: data.preferences.showInPresenceList ?? false,
        showNotificationPreference:
          data.preferences.showNotificationPreference ?? false,
        isNotificationEnable: data.preferences.isNotificationEnable ?? false
      }
    }

    setLoading(true)

    try {
      if (coworker?.id) {
        const userId = coworker?.id

        try {
          const userPath = buildPath(
            config.collection('enterprises'),
            userEnterpriseId!,
            config.collection('users'),
            userId
          )
          await getFunctionCallable('userPersistence', {
            action: 'update',
            userPath: userPath,
            data: coworkerData
          } as UserArgumentsUpdate)

          setLoading(false)
          setMessage('Collaborateur modifié avec succès')
          setShowSuccess(true)
          fadeIn(fadeAnimation)
        } catch (e) {
          console.log(e)
          setLoading(false)
        }
      } else {
        try {
          const isAccountExist = await getFunctionCallable('accountExists', {
            phoneNumber: normalizePhoneNumber(coworkerData?.phoneNumber),
            email: coworkerData?.email
          })

          if (isAccountExist.data === true) {
            setLoading(false)
            Alert.alert(
              'Erreur',
              'Un compte existe déjà sous cette adress mail ou ce numéro de téléphone',
              [
                {
                  text: 'OK',
                  style: 'default'
                }
              ]
            )
            return
          }

          const userPath = buildPath(
            config.collection('enterprises'),
            userEnterpriseId!,
            config.collection('users'),
            generateId(coworkerData?.lastName + coworkerData?.firstName)
          )

          await getFunctionCallable('userPersistence', {
            action: 'create',
            userPath: userPath,
            data: coworkerData
          } as UserArgumentsCreate)

          setLoading(false)
          setMessage('Collaborateur ajouté avec succès')
          setShowSuccess(true)
          fadeIn(fadeAnimation)
        } catch (e) {
          setLoading(false)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <View style={styles.container}>
      <ScrollView>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              style={styles.input}
              value={field.value}
              placeholder={'Prénom'}
              onChangeText={(value: any) => field.onChange(value)}
              onBlur={field.onBlur}
              defaultValue={coworker?.firstName ?? ''}
            />
          )}
          rules={{ required: true }}
        />
        {errors.firstName && (
          <Text style={{ color: errorColor }}>Le prénom est obligatoire.</Text>
        )}

        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              placeholder={'Nom'}
              onChangeText={(value: any) => field.onChange(value)}
              onBlur={field.onBlur}
              style={styles.input}
              value={field.value}
              defaultValue={coworker?.lastName ?? ''}
            />
          )}
          rules={{ required: true }}
        />
        {errors.lastName && (
          <Text style={{ color: errorColor }}>Le nom est obligatoire.</Text>
        )}

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              placeholder={'Adresse mail'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              keyboardType={'email-address'}
              textContentType={'emailAddress'}
              onBlur={field.onBlur}
              value={field.value}
              style={styles.input}
              defaultValue={coworker?.email ?? ''}
            />
          )}
          rules={{
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'address mail invalide'
            }
          }}
        />
        {errors.email && (
          <Text style={{ color: errorColor }}>L'email est obligatoire.</Text>
        )}

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              placeholder={'Mot de passe'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              accessoryRight={() =>
                renderEyeIcon(hidePassword, setHidePassword)
              }
              secureTextEntry={hidePassword}
              textContentType={'password'}
              onBlur={field.onBlur}
              value={field.value}
              style={styles.input}
            />
          )}
          rules={{ required: coworker?.id === undefined, minLength: 6 }}
        />
        {errors.password && (
          <Text style={{ color: errorColor }}>
            Le mot de passe est obligatoire et doit comporter au moins 6
            caractère.
          </Text>
        )}

        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <Input
              size={'large'}
              placeholder={'Numéro de téléphone'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              keyboardType={'phone-pad'}
              textContentType={'telephoneNumber'}
              onBlur={field.onBlur}
              value={field.value}
              style={styles.input}
              defaultValue={coworker?.phoneNumber ?? ''}
            />
          )}
          rules={{ required: false }}
        />

        <Controller
          name="roles"
          control={control}
          render={({ field }) => (
            <Select
              multiSelect={true}
              size={'large'}
              style={styles.select}
              placeholder="Roles"
              value={displayValues.join(',')}
              selectedIndex={selectedIndex}
              onSelect={(index) => {
                // @ts-ignore
                setSelectedIndex(index)
              }}>
              {roles.map((role, index) => renderOption(role, index))}
            </Select>
          )}
          rules={{ required: false }}
        />

        <View style={styles.checkBoxContainer}>
          <CheckBox
            style={{ flex: 0.1 }}
            checked={data.active}
            onChange={(value) =>
              setData((prev) => ({ ...prev, active: value }))
            }
          />
          <Text
            onPress={() =>
              setData((prev) => ({ ...prev, active: !data.active }))
            }
            style={{ fontSize: 16, flex: 0.9 }}>
            Compte Actif
          </Text>
        </View>

        <View style={styles.checkBoxContainer}>
          <CheckBox
            style={{ flex: 0.1 }}
            checked={data.preferences.showInPresenceList}
            onChange={(value) =>
              setData((prev) => ({
                ...prev,
                preferences: {
                  ...prev.preferences,
                  showInPresenceList: value
                }
              }))
            }
          />
          <Text
            style={{ fontSize: 16, flex: 0.9 }}
            onPress={() =>
              setData((prev) => ({
                ...prev,
                preferences: {
                  ...prev.preferences,
                  showInPresenceList: !data.preferences.showInPresenceList
                }
              }))
            }>
            Visible dans la liste de présence
          </Text>
        </View>

        <View style={styles.checkBoxContainer}>
          <CheckBox
            style={{ flex: 0.1 }}
            checked={data.preferences.showNotificationPreference}
            onChange={(value) =>
              setData((prev) => ({
                ...prev,
                preferences: {
                  ...prev.preferences,
                  showNotificationPreference: value
                }
              }))
            }
          />
          <Text
            style={{ fontSize: 16, flex: 0.9 }}
            onPress={() =>
              setData((prev) => ({
                ...prev,
                preferences: {
                  ...prev.preferences,
                  showNotificationPreference:
                    !data.preferences.showNotificationPreference
                }
              }))
            }>
            Permission d'activer les notifications
          </Text>
        </View>

        <Button
          size={'small'}
          style={{ width: 100, marginTop: 20 }}
          onPress={sendInvitation}>
          Inviter
        </Button>
      </ScrollView>
      {showSuccess && fadeAnimation && (
        <Animated.View
          style={{
            marginTop: 20,
            opacity: fadeAnimation,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Text status={'success'} style={styles.successMessage}>
            {message}
          </Text>
        </Animated.View>
      )}
      <FloatingActionButton
        loading={loading}
        iconName={'content-save'}
        // @ts-ignore
        onPress={handleSubmit(saveCoworker)}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1
  },
  input: {
    marginBottom: 10,
    borderColor: 'transparent'
  },
  select: {
    borderColor: 'transparent',
    marginBottom: 20
  },
  successMessage: {
    fontWeight: 'bold'
  },
  checkBoxContainer: {
    alignItems: 'center',
    marginVertical: 5,
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})
