import { EvilIcons } from '@expo/vector-icons'
import {
  Button,
  CheckBox,
  IndexPath,
  Input,
  Select,
  SelectItem,
  Text
} from '@ui-kitten/components'
import { collection, query } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Controller, useForm } from 'react-hook-form'
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native'
import { SearchModal } from 'src/components/molecules/modals/SearchModal'
import { navigate } from 'src/navigations/RootNavigation'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Customer } from 'src/types/businessman'
import { errorColor } from 'src/utils/color'
import getSubCollectionPath from 'src/utils/firebase/path/getSubCollectionPath'

const data = ['Espèces', 'Orange Money', 'Moov Money']

export const ReceiptForm = () => {
  const [selectedIndex, setSelectedIndex] = useState<IndexPath | undefined>()
  const displayValue = data[selectedIndex?.row!]
  const { firestore, userClaims } = useContext(FirebaseContext)
  const [saveCustomer, setSaveCustomer] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>()
  const [showSearchModal, setSearchShowModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: selectedCustomer?.firstName,
      lastName: selectedCustomer?.lastName,
      email: selectedCustomer?.customerEmail,
      phoneNumber: selectedCustomer?.mobilePhone,
      amount: '',
      reason: ''
    }
  })

  const [customers] = useCollectionData<Customer>(
    // @ts-ignore
    query(collection(firestore, getSubCollectionPath(userClaims, 'customers'))),
    {
      idField: 'id'
    }
  )

  useEffect(() => {
    if (selectedCustomer) {
      clearErrors()
      setValue('firstName', selectedCustomer?.firstName)
      setValue('lastName', selectedCustomer?.lastName)
      setValue('email', selectedCustomer?.customerEmail)
      setValue('phoneNumber', selectedCustomer?.mobilePhone)
    }
  }, [selectedCustomer])

  const renderOption = (title: string, index: number) => (
    <SelectItem title={title} key={index} />
  )

  const saveDate = (data: any) => {
    data.paymentMethod = displayValue ?? ''
    data.saveCustomer = saveCustomer

    navigate('ReceiptPreviewScreen', {
      data: data
    })
  }

  const renderSearchIcon = (term: string) => (
    <TouchableOpacity
      onPress={() => {
        setSearchTerm(term)
        setSearchShowModal(true)
      }}>
      <EvilIcons name={'search'} size={25} color="black" />
    </TouchableOpacity>
  )

  return (
    <View style={styles.container}>
      <SearchModal
        searchTerm={searchTerm}
        setSelectedCustomer={setSelectedCustomer}
        data={customers ?? []}
        visible={showSearchModal}
        setVisible={setSearchShowModal}
      />
      <ScrollView>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              style={styles.input}
              value={field.value}
              placeholder={'Prénom *'}
              onChangeText={(value: any) => field.onChange(value)}
              onBlur={field.onBlur}
              accessoryRight={renderSearchIcon(field.value ?? '')}
            />
          )}
          rules={{ required: true }}
        />
        {errors.firstName && (
          <Text style={{ color: errorColor, fontSize: 12 }}>
            Le prénom est obligatoire.
          </Text>
        )}

        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              placeholder={'Nom *'}
              onChangeText={(value: any) => field.onChange(value)}
              onBlur={field.onBlur}
              style={styles.input}
              value={field.value}
              accessoryRight={renderSearchIcon(field.value ?? '')}
            />
          )}
          rules={{ required: true }}
        />
        {errors.lastName && (
          <Text style={{ color: errorColor, fontSize: 12 }}>
            Le nom est obligatoire.
          </Text>
        )}

        <Select
          selectedIndex={selectedIndex}
          onSelect={(index) => setSelectedIndex(index)}
          size={'large'}
          placeholder={'Méthode de paiement'}
          style={styles.input}
          value={displayValue}>
          {data.map(renderOption)}
        </Select>

        <Controller
          name="amount"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              placeholder={'Montant *'}
              keyboardType={'numeric'}
              onChangeText={(value: any) => field.onChange(value)}
              onBlur={field.onBlur}
              style={styles.input}
              value={field.value}
              defaultValue={''}
            />
          )}
          rules={{ required: true }}
        />

        {errors.amount && (
          <Text style={{ color: errorColor, fontSize: 12 }}>
            Le montant est obligatoire.
          </Text>
        )}

        <Controller
          name="reason"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              placeholder={'Motif *'}
              onChangeText={(value: any) => field.onChange(value)}
              onBlur={field.onBlur}
              style={styles.input}
              value={field.value}
            />
          )}
          rules={{ required: true }}
        />

        {errors.reason && (
          <Text style={{ color: errorColor, fontSize: 12 }}>
            Le motif ou la raison est obligatoire.
          </Text>
        )}

        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <Input
              size={'large'}
              placeholder={'Numéro de téléphone'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              keyboardType={'phone-pad'}
              textContentType={'telephoneNumber'}
              onBlur={field.onBlur}
              value={field.value}
              style={styles.input}
            />
          )}
          rules={{ required: false }}
        />

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              placeholder={'Adresse mail'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              accessoryRight={renderSearchIcon(field.value ?? '')}
              keyboardType={'email-address'}
              textContentType={'emailAddress'}
              onBlur={field.onBlur}
              value={field.value}
              style={styles.input}
            />
          )}
          rules={{
            required: false,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'address mail invalide'
            }
          }}
        />

        {errors.email && (
          <Text style={{ color: errorColor, fontSize: 12 }}>
            Address mail invalide.
          </Text>
        )}

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 30
          }}>
          <CheckBox
            style={{ flex: 0.09 }}
            checked={saveCustomer}
            onChange={(value) => setSaveCustomer(value)}
          />
          <Text
            style={{ flex: 1 }}
            onPress={() => setSaveCustomer(!saveCustomer)}>
            Enregistrer le client
          </Text>
        </View>

        <Button style={{ marginTop: 20 }} onPress={handleSubmit(saveDate)}>
          Prévisualiser
        </Button>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1
  },
  input: {
    marginTop: 10,
    borderColor: 'transparent'
  },
  successMessage: {
    fontWeight: 'bold'
  }
})
