import {
  Avatar,
  Drawer,
  DrawerItem,
  IndexPath,
  Text
} from '@ui-kitten/components'
import { getAuth, signOut } from 'firebase/auth'
import React, { useContext } from 'react'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import { navigate } from 'src/navigations/RootNavigation'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { defaultColor } from 'src/utils/color'
import { shortName } from 'src/utils/shortName'
import {
  renderCoworkerIcon,
  renderCoworkersIcon,
  renderEnterpriseIcon,
  renderHomeIcon,
  renderLogoutIcon,
  renderParameterIcon,
  renderPostStatusIcon,
  renderPresenceIcon,
  renderProductIcon,
  renderReceiptIcon,
  renderSubscriptionIcon
} from '../icons'

export const DrawerContent = ({ navigation, state }: any) => {
  const { userData, account, enterpriseData } = useContext(FirebaseContext)

  const logout = async () => {
    try {
      await signOut(getAuth())
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <View>
      <View style={styles.headerContainer}>
        <TouchableOpacity onPress={() => navigate('myProfile')}>
          <View style={styles.avatarContainer}>
            {userData?.profileUrl && userData.profileUrl !== '' ? (
              <Avatar source={{ uri: userData?.profileUrl }} size={'giant'} />
            ) : (
              <Text style={styles.shortName}>
                {userData?.displayName
                  ? shortName(userData?.displayName!)
                  : shortName(account?.displayName!)}
              </Text>
            )}
          </View>
        </TouchableOpacity>
        <Text style={styles.displayName}>{userData?.displayName ?? ''}</Text>
      </View>
      <Drawer
        selectedIndex={new IndexPath(state.index)}
        onSelect={(index) => navigation.navigate(state.routeNames[index.row])}>
        <DrawerItem title="Accueil" accessoryLeft={renderHomeIcon} />
        <DrawerItem title="Ma présence" accessoryLeft={renderPresenceIcon} />
        <DrawerItem
          title="Mes collaborateurs"
          accessoryLeft={renderCoworkersIcon}
        />
        <DrawerItem
          title="Historique des présences"
          accessoryLeft={renderPostStatusIcon}
        />
        <DrawerItem
          title="Mon abonnement"
          accessoryLeft={renderSubscriptionIcon}
        />
        <DrawerItem
          title="Mon entreprise"
          accessoryLeft={renderEnterpriseIcon}
        />
        <DrawerItem
          onPress={() => navigate('myReceipts')}
          title="Mes quittances"
          style={{
            display:
              enterpriseData?.modules?.receipt &&
              enterpriseData?.modules?.receipt === true
                ? undefined
                : 'none'
          }}
          accessoryLeft={renderReceiptIcon}
        />
        <DrawerItem title="Mes clients" accessoryLeft={renderCoworkersIcon} />
        <DrawerItem title="Mon profil" accessoryLeft={renderCoworkerIcon} />
        <DrawerItem
          title="Mes paramètres"
          accessoryLeft={renderParameterIcon}
        />
        <>
          {process.env.FEATURE_PRODUCT_ENABLED && (
            <DrawerItem
              title="Mes produits"
              accessoryLeft={renderProductIcon}
            />
          )}
        </>
      </Drawer>
      <DrawerItem
        accessoryLeft={renderLogoutIcon}
        title="Déconnexion"
        onPress={logout}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  headerContainer: {
    backgroundColor: defaultColor,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: Platform.OS === 'ios' ? 160 : 100,
    paddingHorizontal: 5,
    paddingVertical: 10
  },
  avatarContainer: {
    marginTop: Platform.OS === 'ios' ? 20 : undefined,
    height: 60,
    width: 60,
    borderRadius: 100,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center'
  },
  shortName: {
    fontSize: 30,
    fontWeight: '600'
  },
  displayName: {
    marginTop: 5,
    color: 'white'
  }
})
