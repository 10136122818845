import { Firestore } from '@firebase/firestore'
import { Functions } from '@firebase/functions'
import Constants from 'expo-constants'
import * as Notifications from 'expo-notifications'
import firebase from 'firebase/compat'
import { doc, getFirestore, onSnapshot, setDoc } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import React, { createContext, useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { config } from 'src/config/businessman'
import {
  Enterprise,
  Label,
  ModuleBase,
  ModulePresence,
  User,
  UserClaims
} from 'src/types/businessman'
import { ModuleReceipt } from 'src/types/businessman/entities/ModuleReceipt'
import { getEnterpriseId } from 'src/utils/firebase/path/getEnterpriseId'
import getEnterprisePath from 'src/utils/firebase/path/getEnterprisePath'
import getSettingsDocPath from 'src/utils/firebase/path/getSettingsDocPath'
import firebaseApp from './firebaseApp'

type PaymentLabelsType = {
  enableOrangeMoneyApi?: Label | undefined
  enableOrangeMoneyManual?: Label | undefined
}
type FirebaseContextType = {
  account: firebase.User | undefined
  setAccount: (user: firebase.User | undefined) => void
  userData: User | undefined
  setUserData: (userData: User | undefined) => void
  userClaims: UserClaims | undefined
  userEnterpriseId: string | undefined
  enterpriseData: Enterprise | undefined
  restDays: number | undefined
  baseModuleData: ModuleBase | undefined
  presenceModuleData: ModulePresence | undefined
  receiptModuleData: ModuleReceipt | undefined
  firebaseApp: firebase.app.App
  countReceipts: number | undefined
  setCountReceipts: (value: number | undefined) => void
  paymentLabels: PaymentLabelsType | undefined
  firestore: Firestore
  functions: Functions
}

export const FirebaseContext = createContext<FirebaseContextType>(undefined!)

export const FirebaseProvider = ({ children }: { children: any }) => {
  const [account, setAccount] = useState<firebase.User>()
  const [userData, setUserData] = useState<User | undefined>()
  const [paymentLabels, setPaymentLabels] = useState<
    PaymentLabelsType | undefined
  >()
  const [userEnterpriseId, setUserEnterpriseId] = useState<string | undefined>()
  const [userClaims, setUserClaims] = useState<UserClaims>()
  const [enterpriseData, setEnterpriseData] = useState<Enterprise | undefined>()
  const [restDays, setRestDays] = useState<number | undefined>(undefined)
  const [countReceipts, setCountReceipts] = useState<number | undefined>(
    undefined
  )
  const [baseModuleData, setBaseModuleData] = useState<ModuleBase | undefined>()
  const [presenceModuleData, setPresenceModuleData] = useState<
    ModulePresence | undefined
  >()
  const [receiptModuleData, setReceiptModuleData] = useState<
    ModuleReceipt | undefined
  >()

  useEffect(() => {
    if (account?.uid) {
      account
        .getIdTokenResult()
        .then((res) => {
          let claims = res.claims as UserClaims
          //Set user claims
          setUserClaims(claims)

          if (claims && claims.userPath) {
            //Set enterprise Id
            setUserEnterpriseId(getEnterpriseId(claims.userPath))

            //Get user data
            onSnapshot(
              doc(getFirestore(firebaseApp), claims.userPath),
              (doc) => {
                if (doc.exists()) {
                  setUserData(doc.data() as User)
                }
              }
            )

            //Get enterprise data
            onSnapshot(
              doc(getFirestore(firebaseApp), getEnterprisePath(claims)),
              (doc) => {
                if (doc.exists()) {
                  setEnterpriseData(doc.data() as Enterprise)
                }
              }
            )

            //Get base module data
            onSnapshot(
              doc(
                getFirestore(firebaseApp),
                getSettingsDocPath(claims, 'base')
              ),
              (doc) => {
                if (doc.exists()) {
                  setBaseModuleData(doc.data() as ModuleBase)
                }
              }
            )

            //Get presence module data
            onSnapshot(
              doc(
                getFirestore(firebaseApp),
                getSettingsDocPath(claims, 'presence')
              ),
              (doc) => {
                if (doc.exists()) {
                  setPresenceModuleData(doc.data() as ModulePresence)
                }
              }
            )

            //Get paymentLabels
            onSnapshot(
              doc(
                getFirestore(firebaseApp),
                `${config.collection('labels')}/enableOrangeMoneyApi`
              ),
              (doc) => {
                if (doc.exists()) {
                  setPaymentLabels((prevState) => ({
                    ...prevState,
                    enableOrangeMoneyApi: doc.data() as Label
                  }))
                }
              }
            )

            onSnapshot(
              doc(
                getFirestore(firebaseApp),
                `${config.collection('labels')}/enableOrangeMoneyManual`
              ),
              (doc) => {
                if (doc.exists()) {
                  setPaymentLabels((prevState) => ({
                    ...prevState,
                    enableOrangeMoneyManual: doc.data() as Label
                  }))
                }
              }
            )

            //Get receipt module data
            onSnapshot(
              doc(
                getFirestore(firebaseApp),
                getSettingsDocPath(claims, 'receipt')
              ),
              (doc) => {
                if (doc.exists()) {
                  setReceiptModuleData(doc.data() as ModuleReceipt)
                }
              }
            )
          }
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      setUserClaims(undefined)
      setUserData(undefined)
      setUserEnterpriseId(undefined)
    }
  }, [account])

  useEffect(() => {
    const endDate = enterpriseData?.currentSubscription?.endDate
      ?.toDate()
      .getTime()
    const nowDate = firebase.firestore.Timestamp.now().toDate().getTime()
    if (endDate) {
      const restDays = Math.round((endDate - nowDate) / (24 * 3600 * 1000))
      restDays < 0 ? setRestDays(0) : setRestDays(restDays)
    }
  }, [enterpriseData])

  useEffect(() => {
    if (userData) {
      fetchAndUpdateExpoToken().catch((e) => {
        console.log(e)
      })
    }

    async function fetchAndUpdateExpoToken() {
      const newExpoToken = await registerForPushNotificationsAsync()
      if (
        newExpoToken &&
        (!userData?.expoTokens ||
          !userData?.expoTokens.includes(newExpoToken!!))
      ) {
        console.log(
          `Fetched and found a new token for user ${userData?.displayName} (${account?.uid}) - "${newExpoToken}"`
        )
        await setDoc(
          doc(getFirestore(firebaseApp), userClaims?.userPath!),
          {
            expoTokens: [...(userData?.expoTokens ?? []), newExpoToken]
          },
          { merge: true }
        )
      }
    }
  }, [userData])

  return (
    <FirebaseContext.Provider
      value={{
        paymentLabels,
        countReceipts,
        setCountReceipts,
        receiptModuleData,
        presenceModuleData,
        baseModuleData,
        restDays,
        enterpriseData,
        userClaims,
        userEnterpriseId,
        account,
        setAccount,
        userData,
        setUserData,
        firebaseApp,
        firestore: getFirestore(firebaseApp),
        functions:
          Constants.manifest?.extra?.applicationContext === 'local'
            ? getFunctions(firebaseApp)
            : getFunctions(firebaseApp, 'europe-west3')
      }}>
      {children}
    </FirebaseContext.Provider>
  )
}

async function registerForPushNotificationsAsync() {
  let token
  if (Constants.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync()
    let finalStatus = existingStatus
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }
    if (finalStatus !== 'granted') {
      alert(
        'Veuillez vérifier les autorisations de notification dans les paramètres'
      )
      return
    } else {
      console.log(Platform.OS + ' Authorization status OK')
    }
    token = (await Notifications.getExpoPushTokenAsync()).data
  } else {
    // alert('Must use physical device for Push Notifications')
  }

  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C'
    })
  }

  return token
}
