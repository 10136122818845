import * as eva from '@eva-design/eva'
import { NavigationContainer } from '@react-navigation/native'
import { ApplicationProvider } from '@ui-kitten/components'
import * as Notifications from 'expo-notifications'
import React, { useEffect, useRef, useState } from 'react'
import { LogBox, Platform, StatusBar } from 'react-native'
import { navigationRef } from 'src/navigations/RootNavigation'
import { FirebaseProvider } from 'src/providers/FirebaseProvider'
import { defaultColor } from 'src/utils/color'
import RoutesNavigator from './src/navigations/RoutesNavigator'
import { default as theme } from './src/utils/Theme/custom-theme.json'

if (Platform.OS === 'android') {
  LogBox.ignoreLogs(['Setting a timer'])
}

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false
  })
})

export default function App() {
  const [notification, setNotification] = useState<Notification | false>(false)
  const notificationListener = useRef<any>() // Subscription (no type found in SDK 43)
  const responseListener = useRef<any>() // Subscription (no type found in SDK 43)

  useEffect(() => {
    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        // @ts-ignore
        setNotification(notification) // to be fixed later.
      })

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        console.log(response)
      })

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current!
      )
      Notifications.removeNotificationSubscription(responseListener.current!)
    }
  }, [])

  return (
    <>
      <ApplicationProvider {...eva} theme={{ ...eva.light, ...theme }}>
        <NavigationContainer ref={navigationRef}>
          <FirebaseProvider>
            <StatusBar
              barStyle={'light-content'}
              backgroundColor={defaultColor}
            />
            <RoutesNavigator />
          </FirebaseProvider>
        </NavigationContainer>
      </ApplicationProvider>
    </>
  )
}
