import { collection } from 'firebase/firestore'
import React, { useContext } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { WarningMessageSubscription } from 'src/components/atoms/WarningMessageSubscription'
import { CoworkerList } from 'src/components/coworkers/CoworkerList'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { User } from 'src/types/businessman'
import getSubCollectionPath from 'src/utils/firebase/path/getSubCollectionPath'

export const CoworkersScreen = () => {
  const { userClaims, firestore } = useContext(FirebaseContext)
  const [coworkers, isLoading, error] = useCollectionData<User>(
    // @ts-ignore
    collection(firestore, getSubCollectionPath(userClaims, 'users')),
    {
      idField: 'id',
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  )

  if (isLoading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen />
  }

  return (
    <>
      <WarningMessageSubscription />
      <CoworkerList coworkers={coworkers} />
    </>
  )
}
