/**
 * Return: xyz
 */
export function getEnterpriseId(userPath: string): string {
  const pathSegments = userPath.split('/')
  return pathSegments.length >= 2 &&
    pathSegments[0] === 'enterprises' &&
    pathSegments[2] === 'users'
    ? pathSegments[1]
    : ''
}
