import { useNavigation } from '@react-navigation/native'
import { Button, Input, Text } from '@ui-kitten/components'
import React, { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  Alert,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  View
} from 'react-native'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'
import { renderEyeIcon } from 'src/components/icons'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'
import { normalizePhoneNumber } from 'src/utils/normalizePhoneNumber'

export const SignUpScreen = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm()
  const [saveLoading, setSaveLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [data, setData] = useState<any>({
    enterpriseName: '',
    firstName: '',
    lastName: '',
    enterpriseAddress: '',
    email: '',
    phoneNumber: '',
    password: ''
  })
  const navigation = useNavigation()

  const createAccount: SubmitHandler<any> = async (data: any) => {
    try {
      setSaveLoading(true)
      const isAccountExist = await getFunctionCallable('accountExists', {
        phoneNumber: normalizePhoneNumber(data?.phoneNumber),
        email: data?.email
      })

      if (isAccountExist.data === true) {
        setSaveLoading(false)
        Alert.alert(
          'Erreur',
          'Un compte existe déjà sous cette adress mail ou ce numéro de téléphone',
          [
            {
              text: 'OK',
              style: 'default'
            }
          ]
        )
        return
      }

      await getFunctionCallable('createEnterprise', {
        data: data
      })
      setSaveLoading(false)
      Alert.alert(
        'Succès',
        'Entreprise créée avec succès, veuillez vous connecter',
        [
          {
            text: 'OK',
            style: 'default',
            onPress: () => navigation.goBack()
          }
        ]
      )
    } catch (e) {
      setSaveLoading(false)
      Alert.alert('Erreur', "Une erreur s'est produite, veuillez réessayer")
    }
  }

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
      contentContainerStyle={{ minHeight: '100%' }}>
      <View style={styles.container}>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <Input
              returnKeyType={'next'}
              clearButtonMode={'while-editing'}
              style={styles.input}
              value={field.value}
              placeholder={'Prénom du responsable *'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              onBlur={field.onBlur}
            />
          )}
          rules={{ required: true }}
          defaultValue={data?.firstName ?? ''}
        />
        {errors.firstName && (
          <Text status={'danger'}>Le prénom est obligatoire.</Text>
        )}
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              placeholder={'Nom du responsable *'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              onBlur={field.onBlur}
              style={styles.input}
              value={field.value}
            />
          )}
          rules={{ required: true }}
          defaultValue={data?.lastName ?? ''}
        />
        {errors.lastName && (
          <Text status={'danger'}>Le nom est obligatoire.</Text>
        )}
        <Controller
          name="enterpriseName"
          control={control}
          render={({ field }) => (
            <Input
              style={styles.input}
              placeholder={"Nom de l'entreprise *"}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              onBlur={field.onBlur}
              value={field.value}
            />
          )}
          rules={{ required: true }}
          defaultValue={data?.enterpriseName ?? ''}
        />
        {errors.enterpriseName && (
          <Text status={'danger'}>Le nom de l'entreprise est obligatoire.</Text>
        )}
        <Controller
          name="enterpriseAddress"
          control={control}
          render={({ field }) => (
            <Input
              style={styles.input}
              placeholder={"Adresse de l'entreprise"}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              onBlur={field.onBlur}
              value={field.value}
            />
          )}
          rules={{ required: false }}
          defaultValue={data?.enterpriseAddress ?? ''}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              style={styles.input}
              placeholder={'Email du responsable *'}
              keyboardType={'email-address'}
              value={field.value}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              onBlur={field.onBlur}
            />
          )}
          rules={{
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'address mail invalide',
              ignoreCase: true
            },
            required: true
          }}
          defaultValue={data?.email ?? ''}
        />
        {errors.email && (
          <Text status={'danger'}>Mail non défini ou invalide</Text>
        )}
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => (
            <Input
              placeholder={'Numéro de téléphone du responsable *'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              onBlur={field.onBlur}
              value={field.value}
              style={styles.input}
            />
          )}
          rules={{ required: true }}
          defaultValue={data?.phoneNumber ?? ''}
        />
        {errors.phoneNumber && (
          <Text status={'danger'}>
            Le numéro du responsable est obligatoire.
          </Text>
        )}
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              placeholder={'Mot de passe *'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              secureTextEntry={hidePassword}
              onBlur={field.onBlur}
              value={field.value}
              style={styles.input}
              accessoryRight={() =>
                renderEyeIcon(hidePassword, setHidePassword)
              }
            />
          )}
          rules={{ required: true, minLength: 6 }}
        />
        {errors.password && (
          <Text status={'danger'}>
            Mot de passe invalide (6 caratères minimum).
          </Text>
        )}
        <Button
          disabled={saveLoading}
          status={'primary'}
          onPress={handleSubmit(createAccount)}
          accessoryRight={saveLoading ? LoadingIndicator : undefined}
          style={{
            borderColor: 'transparent',
            marginTop: 20
          }}>
          {saveLoading ? 'Inscription en cours' : "S'inscrire"}
        </Button>
      </View>
    </KeyboardAvoidingView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10
  },
  input: {
    marginBottom: 5
  },
  successMessage: {
    fontWeight: 'bold'
  },
  pictureContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20
  },
  ImageShape: {
    backgroundColor: 'white',
    height: 90,
    width: 90,
    borderRadius: 90
  },
  iconButton: {
    left: 60,
    top: 70,
    backgroundColor: 'white',
    height: 30,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    zIndex: 1000
  }
})
