import { config } from 'src/config/businessman'
import { UserClaims } from 'src/types/businessman'
import getEnterprisePath from 'src/utils/firebase/path/getEnterprisePath'

/**
 * Return example :
 * enterprises/abc/vouchers/xyz
 */
export default function getVouchersDocPath(
  userClaims: UserClaims | undefined,
  voucherId: string
): string {
  return `${getEnterprisePath(userClaims)}/${config.collection(
    'vouchers'
  )}/${voucherId}`
}
