import { Feather, FontAwesome, Ionicons } from '@expo/vector-icons'
import { Avatar, Divider } from '@ui-kitten/components'
import React, { useContext, useEffect, useState } from 'react'
import {
  Linking,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'
import { AvatarName } from 'src/components/atoms/AvatarName'
import { UserLocation } from 'src/components/presences/UserLocation'
import { navigate } from 'src/navigations/RootNavigation'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { User } from 'src/types/businessman'
import { formatDate } from 'src/utils/date/formatDate'

type CoworkerStatusItemType = {
  user: User
}

export const PresenceListItem = ({ user }: CoworkerStatusItemType) => {
  const [lastDate, setLastDate] = useState('')
  const { userClaims, presenceModuleData, enterpriseData } =
    useContext(FirebaseContext)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (user?.latestStatus?.updatedAt) {
      const date = user?.latestStatus?.updatedAt?.toDate()
      setLastDate(formatDate(date!!))
    }
  }, [user])

  const renderMessageIcon = () => (
    <Feather name="message-square" size={24} color="black" />
  )

  const openIn = async () => {
    try {
      let url
      if (
        presenceModuleData?.settings?.preferredCommunicationChannel === 'sms:'
      ) {
        url = `${presenceModuleData?.settings?.preferredCommunicationChannel}${user.phoneNumber}`
      } else {
        url = `${
          presenceModuleData?.settings?.preferredCommunicationChannel
        }${encodeURIComponent(
          user.latestStatus?.message !== ''
            ? user.latestStatus?.message!
            : 'Entrer le message'
        )}`
      }

      await Linking.openURL(url)
    } catch (error: any) {
      alert("Une erreur s'est produite")
    }
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          if (
            presenceModuleData?.settings?.showPastPresencesForAll &&
            userClaims?.roles.includes('coworker')
          ) {
            navigate('CoworkerHistoryScreen', {
              displayName: user?.displayName,
              userId: user?.accountId
            })
          } else if (userClaims?.roles.includes('manager')) {
            navigate('CoworkerHistoryScreen', {
              displayName: user?.displayName,
              userId: user?.accountId
            })
          }
        }}>
        <UserLocation
          showModal={showModal}
          setShowModal={setShowModal}
          userLocation={user?.latestStatus?.userLocation!}
          statusLabel={user?.latestStatus?.label!}
          displayName={user?.displayName}
        />
        <View style={{ flexDirection: 'row' }}>
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <View>
              <View
                style={{
                  borderRadius: 100,
                  left: 43,
                  top: 42,
                  position: 'absolute',
                  zIndex: 1,
                  width: 14,
                  height: 14,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white'
                }}>
                <FontAwesome
                  name="circle"
                  size={12}
                  color={user.latestStatus?.color ?? 'gray'}
                />
              </View>
              {user?.profileUrl && user.profileUrl != '' ? (
                <Avatar
                  source={{ uri: user.profileUrl }}
                  style={{ backgroundColor: 'gray' }}
                  size={'giant'}
                />
              ) : (
                user.displayName && (
                  <AvatarName displayName={user.displayName!!} />
                )
              )}
            </View>
          </View>
          <View style={{ marginLeft: 15, flex: 4 }}>
            <Text style={styles.name}>{user.displayName}</Text>
            <View>
              <Text>{user.latestStatus?.label ?? 'Pas de status'}</Text>
              {user?.latestStatus && user.latestStatus?.message !== '' && (
                <Text style={{}}>{user.latestStatus?.message}</Text>
              )}
              <Text style={{ marginTop: 2 }}>{lastDate}</Text>
            </View>
          </View>
          <View
            style={{
              flex: 1.05,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}>
            {user.latestStatus?.userLocation &&
              Platform.OS !== 'web' &&
              enterpriseData?.modules?.geolocation &&
              user.latestStatus?.userLocation?.latitude !== 0 &&
              user.latestStatus?.userLocation?.longitude !== 0 && (
                <TouchableOpacity
                  onPress={() => setShowModal(true)}
                  style={{ marginRight: 12 }}>
                  <Ionicons
                    name="md-location-outline"
                    size={24}
                    color="black"
                  />
                </TouchableOpacity>
              )}
            {presenceModuleData?.settings?.preferredCommunicationChannel
              ?.length! > 0 && (
              <TouchableOpacity onPress={openIn}>
                {renderMessageIcon()}
              </TouchableOpacity>
            )}
          </View>
        </View>
      </TouchableOpacity>
      <Divider style={{ marginTop: 10 }} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 5
  },
  name: {
    fontWeight: 'bold'
  },
  displayName: {
    fontWeight: 'bold',
    marginBottom: 25,
    fontSize: 18
  }
})
