import { Text } from '@ui-kitten/components'
import React, { useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { ExpiredSubscriptionModal } from '../molecules/modals/ExpiredSubscriptionModal'

export const WarningMessageSubscription = () => {
  const { restDays } = useContext(FirebaseContext)

  return (
    <>
      {restDays!! < 6 && (
        <View style={styles.container}>
          {restDays === 0 ? (
            <>
              <Text style={{ fontWeight: 'bold' }} status={'danger'}>
                Votre abonnement est en attente d'être renouvelé
              </Text>
              <ExpiredSubscriptionModal />
            </>
          ) : (
            <Text style={{ fontWeight: 'bold' }} status={'warning'}>
              {restDays === 1
                ? "Votre abonnement se termine aujourd'huit"
                : `Votre abonnement se termine dans ${restDays!!} jours`}
            </Text>
          )}
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    height: 'auto',
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
