import { getAuth, onAuthStateChanged } from 'firebase/auth'
import React, { useContext, useEffect, useState } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { FirebaseContext } from '../providers/FirebaseProvider'
import AuthStack from './AuthStackNavigator'
import CoworkerStackNavigator from './CoworkerStackNavigator'
import ManagerStackNavigator from './ManagerStackNavigator'

const auth = getAuth()

export default function RoutesNavigator() {
  const { account, setAccount, userClaims, userEnterpriseId } =
    useContext(FirebaseContext)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(
      auth,
      async (authenticatedUser) => {
        try {
          await (authenticatedUser
            ? // @ts-ignore
              setAccount(authenticatedUser)
            : setAccount(undefined))
          setIsLoading(false)
        } catch (error) {
          console.log(error)
        }
      }
    )
    // unsubscribe auth listener on unmount
    return unsubscribeAuth
  }, [])

  if (isLoading) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" />
      </View>
    )
  }

  return account && userEnterpriseId && userClaims?.roles ? (
    <>
      {userClaims?.roles.includes('manager') ? (
        <ManagerStackNavigator />
      ) : (
        <CoworkerStackNavigator />
      )}
    </>
  ) : (
    <AuthStack />
  )
}
