import { Button, Input, Text } from '@ui-kitten/components'
import { FirebaseRecaptchaVerifierModal } from 'expo-firebase-recaptcha'
import { getAuth, PhoneAuthProvider, signInWithCredential } from 'firebase/auth'
import firebase from 'firebase/compat'
import React, { useRef, useState } from 'react'
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native'
import CodeInput from 'src/components/molecules/CodeInput'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'
import { normalizePhoneNumber } from 'src/utils/normalizePhoneNumber'
import { LoadingIndicator } from '../atoms/LoadingIndicator'
import { ErrorMessage } from '../molecules/errors/ErrorMessage'

const auth = getAuth()

export const PhoneAuth = () => {
  const recaptchaVerifier = useRef(null)
  const [phoneNumber, setPhoneNumber] = useState<string>()
  const [verificationId, setVerificationId] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [confirmationCode, setConfirmationCode] = useState('')
  const [phoneAuthError, setPhoneAuthError] = useState(false)
  const firebaseConfig = firebase.apps.length
    ? firebase.app().options
    : undefined

  const attemptInvisibleVerification = true

  const login = async () => {
    try {
      if (phoneNumber) {
        setLoading(true)
        const isAccountExist = await getFunctionCallable('accountExists', {
          phoneNumber: normalizePhoneNumber(phoneNumber)
        })
        if (isAccountExist.data === true) {
          setPhoneAuthError(false)
          const phoneProvider = new PhoneAuthProvider(auth)
          const verificationId = await phoneProvider.verifyPhoneNumber(
            normalizePhoneNumber(phoneNumber),
            recaptchaVerifier.current!!
          )
          setVerificationId(verificationId)
        } else {
          setPhoneAuthError(true)
        }
        setLoading(false)
      }
    } catch (err: any) {
      setLoginError(err)
      setLoading(false)
    }
  }

  const confirm = async (code: string) => {
    try {
      if (verificationId) {
        setLoading(true)
        const credential = PhoneAuthProvider.credential(verificationId, code)
        await signInWithCredential(auth, credential)
        setLoading(false)
      }
    } catch (err: any) {
      setLoginError(err)
    }
  }

  if (!verificationId) {
    return (
      <View style={styles.container}>
        <FirebaseRecaptchaVerifierModal
          ref={recaptchaVerifier}
          firebaseConfig={firebaseConfig}
          attemptInvisibleVerification={attemptInvisibleVerification}
        />
        <Input
          style={styles.input}
          placeholder="Numéro, exp: +226 99999999"
          autoCompleteType="tel"
          keyboardType="phone-pad"
          textContentType="telephoneNumber"
          onSubmitEditing={login}
          onChangeText={(phoneNumber) => {
            setLoginError('')
            setPhoneAuthError(false)
            setPhoneNumber(phoneNumber)
          }}
        />
        <Button
          style={{ borderColor: 'transparent', marginHorizontal: 30 }}
          status={'control'}
          appearance={'outline'}
          onPress={login}
          accessoryRight={loading ? LoadingIndicator : undefined}>
          {loading ? 'Envoi de sms en cours' : 'Suivant'}
        </Button>
        {loginError ? <ErrorMessage error={loginError} visible={true} /> : null}
        {phoneAuthError && (
          <Text style={styles.phoneError}>
            Aucun compte n'est lié à ce numéro
          </Text>
        )}
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <FirebaseRecaptchaVerifierModal
        ref={recaptchaVerifier}
        firebaseConfig={firebaseConfig}
        attemptInvisibleVerification={attemptInvisibleVerification}
      />
      <Text status={'control'} style={{ alignSelf: 'center' }}>
        Entrer le code de confirmation
      </Text>
      {Platform.OS !== 'web' ? (
        <CodeInput onPress={(code) => confirm(code)} loading={loading} />
      ) : (
        <Input
          style={[styles.input, { marginTop: 10, alignSelf: 'center' }]}
          placeholder="Entrer le code"
          autoCompleteType="cc-number"
          keyboardType="number-pad"
          onSubmitEditing={() => confirm(confirmationCode)}
          onChangeText={setConfirmationCode}
        />
      )}

      <TouchableOpacity
        style={{
          marginVertical: 20,
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onPress={() => login()}>
        <Text style={{ color: '#ced4da' }}>Code pas reçu ?</Text>
      </TouchableOpacity>
      {loginError ? <ErrorMessage error={loginError} visible={true} /> : null}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center'
  },
  input: {
    marginHorizontal: 30,
    marginBottom: 20
  },
  phoneError: {
    marginTop: 20,
    color: '#fdca40',
    fontSize: 15,
    fontWeight: '600'
  }
})
