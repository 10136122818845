import React from 'react'
import { StyleSheet, View } from 'react-native'
import { CustomerForm } from 'src/components/forms/customer'

export const CustomerFormScreen = () => {
  return (
    <View style={styles.container}>
      <CustomerForm />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  }
})
