import { useRoute } from '@react-navigation/native'
import { Button, Card, Text } from '@ui-kitten/components'
import firebase from 'firebase/compat'
import React, { useContext, useEffect, useState } from 'react'
import { Animated, StyleSheet, View } from 'react-native'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { fadeIn, fadeOut } from 'src/utils/animation'
import { localDate } from 'src/utils/date/formatDate'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'
import getEnterprisePath from 'src/utils/firebase/path/getEnterprisePath'

export const ReceiptPreview = () => {
  const { enterpriseData, receiptModuleData, countReceipts, userClaims } =
    useContext(FirebaseContext)
  const [saveLoading, setSaveLoading] = useState(false)
  const [fadeAnimation] = useState(new Animated.Value(1))
  const [showSuccess, setShowSuccess] = useState(false)
  const [mails, setMails] = useState([''])
  const route = useRoute()

  // @ts-ignore
  const data = route?.params?.data
  const receiptNumber = data?.receiptNumber ?? (countReceipts ?? 0) + 1

  useEffect(() => {
    // Receipts emails
    let emails: string[] = []

    if (
      receiptModuleData?.managerEmails &&
      receiptModuleData?.managerEmails?.length > 0
    ) {
      emails = receiptModuleData?.managerEmails
    }
    if (data?.email && data?.email != '') {
      emails = [...emails, data?.email?.trim()]
    }
    setMails(emails)

    // Success message
    if (showSuccess) {
      const interval = setInterval(() => {
        fadeOut(fadeAnimation, setShowSuccess)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [showSuccess])

  const generateReceipt = async () => {
    const receiptData = {
      id: data?.id,
      amountTotal: Number(data?.amount),
      paymentMethod: data?.paymentMethod ?? '',
      reason: data?.reason ?? '',
      to: {
        address: data?.address ?? '',
        customerEmail: data?.email,
        managerEmails: receiptModuleData?.managerEmails,
        firstName: data?.firstName,
        lastName: data?.lastName,
        mobilePhone: data?.phoneNumber
      }
    }
    try {
      setSaveLoading(true)
      setShowSuccess(false)
      await getFunctionCallable('receiptPersistence', {
        action: 'create',
        enterprisePath: getEnterprisePath(userClaims),
        data: receiptData,
        saveCustomer: data?.saveCustomer
      })
      setShowSuccess(true)
      setSaveLoading(false)
      fadeIn(fadeAnimation)
    } catch (e) {
      setSaveLoading(false)
    }
  }

  return (
    <View>
      <View style={styles.receipt}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flex: 1 }}>
            <Text
              category={'s1'}
              style={{ fontWeight: '700', alignSelf: 'center' }}>
              Quittance
            </Text>
            <Text category={'c2'} style={{ fontWeight: '700', marginTop: 10 }}>
              Reçu de
            </Text>
            <Text category={'c2'}>{enterpriseData?.name ?? ''},</Text>
            <Text category={'c2'}>
              Ouagadougou, {enterpriseData?.address ?? ''}
            </Text>
          </View>
          <View style={{ flex: 1, marginTop: 10 }}>
            <Text category={'c2'} style={{ alignSelf: 'flex-end' }}>
              {`N°${receiptNumber}`}
            </Text>
            <View style={styles.row}>
              <Text
                category={'c2'}
                style={{ fontWeight: '700', marginRight: 28 }}>
                Date
              </Text>
              <Text category={'c2'}>
                {localDate(firebase.firestore.Timestamp.now().toDate())}
              </Text>
            </View>
            {data?.paymentMethod != '' && (
              <View style={styles.row}>
                <Text
                  category={'c2'}
                  style={{ fontWeight: '700', marginRight: 15 }}>
                  Méthode
                </Text>
                <Text category={'c2'}>{data?.paymentMethod ?? ''}</Text>
              </View>
            )}

            <View style={styles.row}>
              <Text
                category={'c2'}
                style={{ fontWeight: '700', marginRight: 15 }}>
                Montant
              </Text>
              <Text category={'c2'}>{data?.amount} FCFA</Text>
            </View>
          </View>
        </View>
        <View
          style={{ flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
          <Text category={'c2'} style={{ flex: 0.2, fontWeight: '700' }}>
            Motif:
          </Text>
          <Text category={'c2'} style={{ flex: 0.6, borderBottomWidth: 0.5 }}>
            {data?.reason}
          </Text>
        </View>
        <View
          style={{ flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
          <Text category={'c2'} style={{ flex: 0.2, fontWeight: '700' }}>
            Destinataire:
          </Text>
          <Text category={'c2'} style={{ flex: 0.6, borderBottomWidth: 0.5 }}>
            {data?.firstName} {data?.lastName}
          </Text>
        </View>
      </View>

      <Button
        disabled={saveLoading}
        accessoryLeft={saveLoading ? LoadingIndicator : undefined}
        onPress={generateReceipt}
        style={{ marginTop: 20, alignSelf: 'flex-end' }}
        size={'small'}>
        {saveLoading
          ? 'En cours'
          : `${data?.id ? 'Regénérer la quittance' : 'Générer la quittance'}`}
      </Button>
      <Card status={'warning'} style={{ marginTop: 20 }}>
        <Text category={'s2'}>
          {mails?.length > 1
            ? 'La quittance sera envoyée aux mails suivants: '
            : 'La quittance sera envoyée au mail suivant: '}
          {mails.join(', ') ?? ''}
        </Text>
      </Card>
      {showSuccess && fadeAnimation && (
        <Animated.View style={{ opacity: fadeAnimation }}>
          <Text status={'success'} style={styles.successMessage}>
            {data?.id
              ? 'Quittance regénérée et envoyé avec succès'
              : 'Quittance générée et envoyé avec succès'}
          </Text>
        </Animated.View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  receipt: {
    borderWidth: 1,
    padding: 5,
    paddingBottom: 25
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  successMessage: {
    fontWeight: 'bold',
    alignSelf: 'center',
    marginTop: 50
  }
})
