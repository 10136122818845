import React, { useEffect, useState } from 'react'
import { StyleSheet, Text } from 'react-native'

type ErrorType = {
  error: any
  visible: boolean
}
export const ErrorMessage = ({ error, visible }: ErrorType) => {
  const [authError, setAuthError] = useState('')
  if (!error || !visible) {
    return null
  }

  const parseAuthError = () => {
    switch (error.code) {
      case 'auth/wrong-password':
        {
          setAuthError('Mot de passe invalide !')
        }
        break
      case 'auth/user-not-found':
        {
          setAuthError("Aucun compte n'est lié à cette adresse email!")
        }
        break
      case 'auth/too-many-requests':
        {
          setAuthError('Trop de tentatives, veuillez réessayer plut tard')
        }
        break
      case 'auth/invalid-verification-code':
        {
          setAuthError('Code de vérification invalide')
        }
        break
      case ' auth/invalid-email':
        {
          setAuthError('Adresse mail invalide')
        }
        break
      case 'auth/code-expired':
        {
          setAuthError('Code de vérification expiré')
        }
        break
      case 'auth/user-disabled':
        {
          setAuthError('Votre compte a été désactivé')
        }
        break
      default: {
        setAuthError(
          "Op! une erreur s'est produite veuillez réessayer plut tard"
        )
      }
    }
  }

  useEffect(() => {
    parseAuthError()
  }, [error])

  return <Text style={styles.errorText}>⚠️ {authError}</Text>
}

const styles = StyleSheet.create({
  errorText: {
    marginTop: 20,
    color: '#fdca40',
    fontWeight: '600',
    marginLeft: 30
  }
})
