import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { Button, Input, Layout, Text } from '@ui-kitten/components'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ScrollView, StyleSheet, View } from 'react-native'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'
import { defaultColor } from 'src/utils/color'

type layoutStateType = {
  showSubscriptionInformation: boolean
  showSuccessInformation: boolean
}
type PropsType = {
  totalPrice: string
  confirmationMessage: string
  setConfirmationMessage: (value: string) => void
  saveLoading: boolean
  submit: () => void
  paymentSuccess: boolean
  setPhoneNumber: (value: string) => void
}

export const ManualPaymentView = ({
  totalPrice,
  confirmationMessage,
  setConfirmationMessage,
  saveLoading,
  paymentSuccess,
  submit,
  setPhoneNumber
}: PropsType) => {
  const navigation = useNavigation()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm()

  return (
    <View
      style={{
        flex: 1
      }}>
      <ScrollView style={{ flex: 1 }}>
        {!paymentSuccess ? (
          <Layout style={styles.viewContainer}>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: 17,
                marginBottom: 10,
                marginTop: 20
              }}>
              Montant total: {totalPrice} FCFA
            </Text>
            <Text style={[styles.text, { marginBottom: 5 }]}>
              Etapes à suivre :
            </Text>
            <Text>
              {`1. Veuillez envoyer le montant de ${totalPrice} CFA en tapant sur votre téléphone: `}
            </Text>
            <Text
              style={[styles.text, { fontWeight: '700', marginVertical: 10 }]}>
              *144*2*1*06954748*{totalPrice}#
            </Text>
            <Text style={[styles.text, { marginVertical: 10 }]}>
              2. Une fois l'argent envoyé, veuillez confirmer votre paiement. De
              facon optionelle, vous pouvez copier / coller le SMS de
              confirmation pour faciliter la vérification
            </Text>
            <Text style={[styles.text, { marginVertical: 10 }]}>
              3. Votre abonnement est instantanement renouvelé
            </Text>

            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder={'Numéro orange money'}
                  onChangeText={(value: any) => {
                    field.onChange(value)
                    setPhoneNumber(value)
                  }}
                  keyboardType={'phone-pad'}
                  textContentType={'telephoneNumber'}
                  onBlur={field.onBlur}
                  value={field.value}
                  style={styles.text}
                  defaultValue={''}
                />
              )}
              rules={{
                required: 'Veuillez enter le numéro orange money *',
                minLength: 8
              }}
            />
            {errors.phoneNumber && (
              <Text style={{ marginBottom: 12 }} status={'danger'}>
                {errors.phoneNumber?.type === 'required'
                  ? errors.phoneNumber?.message
                  : errors.phoneNumber?.type === 'minLength'
                  ? 'Numéro non valide'
                  : undefined}
              </Text>
            )}
            <Input
              multiline={true}
              value={confirmationMessage}
              textStyle={{ minHeight: 50 }}
              onChangeText={setConfirmationMessage}
              placeholder="SMS optionel de confirmation de paiement"
              style={styles.text}
            />
            <Button
              disabled={saveLoading}
              status={'primary'}
              accessoryLeft={saveLoading ? LoadingIndicator : undefined}
              onPress={handleSubmit(submit)}
              style={styles.button}>
              {saveLoading ? 'En cours' : `Oui j'ai payé ${totalPrice} CFA`}
            </Button>
          </Layout>
        ) : (
          <Layout style={{ padding: 10, marginTop: 10 }}>
            <View style={{ justifyContent: 'center', alignItems: 'center' }}>
              <MaterialCommunityIcons
                name="hand-peace"
                size={50}
                color={defaultColor}
              />
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 18,
                  marginVertical: 10
                }}>
                Votre abonnement a été renouvelé!
              </Text>

              <Text style={styles.text}>
                Beaucoup de succés dans vos affaires.
              </Text>
              <Text style={styles.text}>Merci pour votre confiance.</Text>
              <Text style={styles.text}>Grandissons ensemble!</Text>
            </View>
            <Button
              status={'primary'}
              onPress={() => navigation.goBack()}
              style={styles.button}>
              Retour à l'abonnement
            </Button>
          </Layout>
        )}
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  viewContainer: {
    flex: 1,
    overflow: 'scroll',
    marginBottom: 25,
    marginLeft: 15,
    marginRight: 15,
    backgroundColor: 'transparent'
  },

  text: {
    fontSize: 16,
    fontWeight: '900',
    marginBottom: 10
  },
  button: {
    marginTop: 15
  }
})
