import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import { defaultColor } from 'src/utils/color'
import {
  CoworkerEditFormScreen,
  CoworkerPastPresenceScreen,
  CustomerFormScreen,
  PaymentScreen,
  ProductFormScreen,
  ReceiptFormScreen,
  ReceiptPreviewScreen
} from '../scenes'
import { ManagerDrawerNavigator } from './ManagerDrawerNavigator'

type StackParamList = {
  DrawerStack: undefined
  CoworkerEditFormScreen: undefined
  ProductFormScreen: undefined
  CoworkerHistoryScreen: undefined
  PaymentScreen: undefined
  ReceiptFormScreen: undefined
  CustomerFormScreen: undefined
  ReceiptPreviewScreen: undefined
}

const Stack = createStackNavigator<StackParamList>()
export default function ManagerStackNavigator() {
  return (
    <Stack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerStyle: {
          backgroundColor: defaultColor
        },
        headerTintColor: 'white'
      }}
      initialRouteName={'DrawerStack'}>
      <Stack.Screen
        name={'DrawerStack'}
        component={ManagerDrawerNavigator}
        options={{
          headerShown: false
        }}
      />
      <Stack.Screen
        name={'CoworkerEditFormScreen'}
        component={CoworkerEditFormScreen}
        options={(route) => ({
          // @ts-ignore
          headerTitle: route.route?.params?.name ?? 'Nouveau collaborateur'
        })}
      />

      <Stack.Screen
        name={'ProductFormScreen'}
        component={ProductFormScreen}
        options={(route) => ({
          // @ts-ignore
          headerTitle: route.route?.params?.name ?? 'Nouveau produit'
        })}
      />
      <Stack.Screen
        name={'CoworkerHistoryScreen'}
        component={CoworkerPastPresenceScreen}
        options={{
          headerTitle: 'Historique des présences'
        }}
      />
      <Stack.Screen
        name={'ReceiptFormScreen'}
        component={ReceiptFormScreen}
        options={{
          headerTitle: 'Nouvelle quittance'
        }}
      />
      <Stack.Screen
        name={'CustomerFormScreen'}
        component={CustomerFormScreen}
        options={(route) => ({
          // @ts-ignore
          headerTitle: route.route?.params?.headerTitle ?? 'Nouveau client'
        })}
      />
      <Stack.Screen
        name={'ReceiptPreviewScreen'}
        component={ReceiptPreviewScreen}
        options={{
          headerTitle: 'Quittance à générer'
        }}
      />
      <Stack.Screen
        name={'PaymentScreen'}
        component={PaymentScreen}
        options={{
          headerTitle: 'Paiement'
        }}
      />
    </Stack.Navigator>
  )
}
