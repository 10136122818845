import { Ionicons } from '@expo/vector-icons'
import firebase from 'firebase/compat'
import React from 'react'
import { Modal, TouchableOpacity, View } from 'react-native'
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps'
import { heightPercentageToDP } from 'react-native-responsive-screen'

type PropsType = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  userLocation: firebase.firestore.GeoPoint | undefined
  displayName: string
  statusLabel: string
}
export const UserLocation = ({
  showModal,
  setShowModal,
  displayName,
  userLocation,
  statusLabel
}: PropsType) => {
  return (
    <Modal
      transparent
      visible={showModal}
      animated
      statusBarTranslucent={false}
      animationType={'slide'}>
      <View
        style={{
          flex: 1,
          justifyContent: 'flex-end',
          marginTop: 20
        }}>
        <View
          style={{
            height: heightPercentageToDP('100')
          }}>
          <TouchableOpacity
            style={{
              alignSelf: 'flex-end',
              marginTop: 25,
              right: 5,
              position: 'absolute',
              top: 30,
              zIndex: 1000
            }}
            onPress={() => setShowModal(false)}>
            <Ionicons name="close-circle" size={35} color={'#495057'} />
          </TouchableOpacity>
          <View style={{ flex: 1 }}>
            {userLocation && (
              <MapView
                provider={PROVIDER_GOOGLE}
                style={{ flex: 1, marginTop: 50 }}
                initialRegion={{
                  latitude: userLocation?.latitude,
                  longitude: userLocation?.longitude,
                  longitudeDelta: 0.04,
                  latitudeDelta: 0.04
                }}>
                <Marker
                  title={displayName}
                  description={statusLabel}
                  coordinate={{
                    latitude: userLocation.latitude,
                    longitude: userLocation.longitude
                  }}
                />
              </MapView>
            )}
          </View>
        </View>
      </View>
    </Modal>
  )
}
