import firebase from 'firebase/compat'
import { isSubscriptionExpired } from 'src/utils/date/isSubscriptionExpired'

export const addOneMonth = (date: firebase.firestore.Timestamp | undefined) => {
  return new Date(
    date && isSubscriptionExpired(date)
      ? date.toMillis() + 2562487930
      : firebase.firestore.Timestamp.now().toMillis() + 2562487930
  )
}
