import { RouteProp } from '@react-navigation/native'
import React from 'react'
import { CoworkerForm } from 'src/components/forms/coworker'

interface PropsType {
  route: RouteProp<any>
}

export const CoworkerEditFormScreen = ({ route }: PropsType) => {
  return <CoworkerForm coworker={route?.params?.coworker} />
}
