import { CheckBox, Text } from '@ui-kitten/components'
import { doc, query } from 'firebase/firestore'
import React, { useContext, useEffect, useState } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { View } from 'react-native'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { config } from 'src/config/businessman'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Module } from 'src/types/businessman'
import { Statistic } from 'src/types/businessman/entities/Statistic'
import { buildPath } from 'src/utils/firebase/path/buildPath'

type PropsType = {
  module: Module
  selectedModules: Module[]
  setSelectedModules: (value: any) => void
  setTotalPrice: (value: any) => void
}
export const ModulePrice = ({
  module,
  selectedModules,
  setSelectedModules,
  setTotalPrice
}: PropsType) => {
  const { firestore, userEnterpriseId } = useContext(FirebaseContext)
  const [currentPrice, setCurrentPrice] = useState(0)
  const statisticPath = buildPath(
    config.collection('enterprises'),
    userEnterpriseId!,
    'statistics',
    module.code === 'base' ? 'users' : module.code + 's'
  )

  const [statisticDoc, loading, error] = useDocumentData<Statistic>(
    // @ts-ignore
    query(doc(firestore, statisticPath))
  )

  useEffect(() => {
    const pricesRules: Array<any> =
      module?.code === 'base'
        ? Object.keys(module.priceUserRules)
        : Object.keys(module.priceRules)

    let price = 0
    for (let index = 0; index < pricesRules.length; index++) {
      const [minLimit, maxLimit] = pricesRules[index].split('-')
      if (
        (statisticDoc?.counter ?? 0) >= Number(minLimit) &&
        (statisticDoc?.counter ?? 0) <= Number(maxLimit)
      ) {
        price =
          module.code === 'base'
            ? module.priceUserRules[pricesRules[index]]
            : module.priceRules[pricesRules[index]]
        break
      }
    }
    if (module.code === 'base') {
      setTotalPrice(price)
      setCurrentPrice(price)
    }
  }, [module, statisticDoc])

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen />
  }
  return (
    <CheckBox
      disabled={module.code === 'base'}
      checked={selectedModules.includes(module) || module.code === 'base'}
      onChange={(check) => {
        if (check) {
          setSelectedModules((prev: any) => [...prev, module])
          setTotalPrice((prev) => (prev += Number(currentPrice)))
        } else {
          const filterData = selectedModules.filter(
            (d) => d.code !== module.code
          )
          setSelectedModules(filterData)
          setTotalPrice((prev) => (prev -= Number(currentPrice)))
        }
      }}
      style={{ marginVertical: 5 }}>
      <View>
        <Text category="label">{module?.name.toUpperCase()}</Text>
        <Text category="label">{`${currentPrice ?? 0} FCFA`}</Text>
      </View>
    </CheckBox>
  )
}
