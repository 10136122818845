import { useRoute } from '@react-navigation/native'
import { Button, Input, Text } from '@ui-kitten/components'
import React, { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Animated, ScrollView, StyleSheet, View } from 'react-native'
import { LoadingIndicator } from 'src/components/atoms/LoadingIndicator'
import { config } from 'src/config/businessman'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { fadeIn, fadeOut } from 'src/utils/animation'
import { errorColor } from 'src/utils/color'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'
import { buildPath } from 'src/utils/firebase/path/buildPath'
import getEnterprisePath from 'src/utils/firebase/path/getEnterprisePath'

export const CustomerForm = () => {
  const { userClaims } = useContext(FirebaseContext)
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [fadeAnimation] = useState(new Animated.Value(1))
  const route = useRoute()

  // @ts-ignore
  const customerData = route?.params?.customerData

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: customerData?.firstName ?? '',
      lastName: customerData?.lastName ?? '',
      customerEmail: customerData?.customerEmail ?? '',
      mobilePhone: customerData?.mobilePhone ?? '',
      address: customerData?.address ?? ''
    }
  })

  // Success message
  useEffect(() => {
    if (showSuccess) {
      const interval = setInterval(() => {
        fadeOut(fadeAnimation, setShowSuccess)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [showSuccess])

  const saveData = async (data: any) => {
    const enterprisePath = getEnterprisePath(userClaims)
    try {
      setLoading(true)
      if (customerData?.id) {
        const customerPath = buildPath(
          enterprisePath,
          config.collection('customers'),
          customerData?.id
        )

        await getFunctionCallable('customerPersistence', {
          action: 'update',
          customerPath: customerPath,
          enterprisePath: enterprisePath,
          data: data
        })
      } else {
        await getFunctionCallable('customerPersistence', {
          action: 'create',
          enterprisePath: enterprisePath,
          data: data
        })
      }
      setShowSuccess(true)
      fadeIn(fadeAnimation)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <View style={styles.container}>
      <ScrollView>
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              style={styles.input}
              value={field.value}
              placeholder={'Prénom *'}
              onChangeText={(value: any) => field.onChange(value)}
              onBlur={field.onBlur}
            />
          )}
          rules={{ required: true }}
        />
        {errors.firstName && (
          <Text style={{ color: errorColor, fontSize: 12 }}>
            Le prénom est obligatoire.
          </Text>
        )}

        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              placeholder={'Nom *'}
              onChangeText={(value: any) => field.onChange(value)}
              onBlur={field.onBlur}
              style={styles.input}
              value={field.value}
            />
          )}
          rules={{ required: true }}
        />
        {errors.lastName && (
          <Text style={{ color: errorColor, fontSize: 12 }}>
            Le nom est obligatoire.
          </Text>
        )}

        <Controller
          name="mobilePhone"
          control={control}
          render={({ field }) => (
            <Input
              size={'large'}
              placeholder={'Numéro de téléphone'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              keyboardType={'phone-pad'}
              textContentType={'telephoneNumber'}
              onBlur={field.onBlur}
              value={field.value}
              style={styles.input}
            />
          )}
          rules={{ required: false }}
        />

        <Controller
          name="customerEmail"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              placeholder={'Adresse mail'}
              onChangeText={(value: any) => {
                field.onChange(value)
              }}
              keyboardType={'email-address'}
              textContentType={'emailAddress'}
              onBlur={field.onBlur}
              value={field.value}
              style={styles.input}
            />
          )}
          rules={{
            required: false,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'address mail invalide'
            }
          }}
        />

        {errors.customerEmail && (
          <Text style={{ color: errorColor, fontSize: 12 }}>
            Address mail invalide.
          </Text>
        )}

        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <Input
              clearButtonMode={'while-editing'}
              returnKeyType={'next'}
              size={'large'}
              placeholder={'Adresse'}
              onChangeText={(value: any) => field.onChange(value)}
              onBlur={field.onBlur}
              style={styles.input}
              value={field.value}
            />
          )}
        />
      </ScrollView>
      <Button
        disabled={loading}
        accessoryLeft={loading ? LoadingIndicator : undefined}
        style={{ marginTop: 20 }}
        onPress={handleSubmit(saveData)}>
        {loading ? 'En cours' : 'Enregistrer'}
      </Button>
      {showSuccess && fadeAnimation && (
        <Animated.View
          style={{
            marginTop: 20,
            opacity: fadeAnimation,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Text status={'success'} style={styles.successMessage}>
            {customerData?.id
              ? 'Client modifié avec succès'
              : 'Client ajouté avec succès'}
          </Text>
        </Animated.View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 10
  },
  input: {
    marginTop: 10,
    borderColor: 'transparent'
  },
  successMessage: {
    fontWeight: 'bold'
  }
})
