import React from 'react'
import { ErrorScreen } from 'src/components/molecules/errors/ErrorScreen'
import { LoadingScreen } from 'src/components/molecules/loading'
import { ProductListView } from 'src/components/shop/products/List/productListView'
import { useProducts } from 'src/hooks/shop/products/useProducts'

export const ProductHomeScreen = () => {
  const { products, isLoading, hasError } = useProducts()

  if (isLoading) {
    return <LoadingScreen />
  }
  if (hasError) {
    return <ErrorScreen />
  }

  return <ProductListView products={products} />
}
