import { Text } from '@ui-kitten/components'
import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Customer } from 'src/types/businessman'

type PropsType = {
  data: Customer
  setSelectedCustomer: (value: any) => void
  setVisible: (value: boolean) => void
}
export const SearchItem = ({
  data,
  setSelectedCustomer,
  setVisible
}: PropsType) => {
  return (
    <TouchableOpacity
      onPress={() => {
        setSelectedCustomer(data)
        setVisible(false)
      }}>
      <Text style={{ fontWeight: '700' }}>
        {data.firstName} {data.lastName}
      </Text>
      {data?.customerEmail?.length > 0 && (
        <Text style={{ color: 'rgba(0, 0, 0, .8)' }} category={'s2'}>
          {data.customerEmail}
        </Text>
      )}
      {data?.mobilePhone?.length > 0 && (
        <Text style={{ color: 'rgba(0, 0, 0, .8)' }} category={'s2'}>
          {data.mobilePhone}
        </Text>
      )}
    </TouchableOpacity>
  )
}
