import { Text } from '@ui-kitten/components'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { localDate } from 'src/utils/date/formatDate'
import { SubscriptionHistory } from 'src/utils/types/SubscriptionHistory'

type PropsType = {
  item: SubscriptionHistory
}

export const PastSubscriptionListItem = ({ item }: PropsType) => {
  return (
    <View style={styles.container}>
      {item?.startDate?.toDate() && item?.endDate?.toDate() && (
        <Text style={{ fontWeight: 'bold' }}>
          Du {localDate(item?.startDate?.toDate())} au{' '}
          {localDate(item?.endDate?.toDate())}
        </Text>
      )}
      {item?.modules!.map((item, index: number) => (
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            paddingVertical: 3
          }}
          key={index}>
          <Text style={{ flex: 0.35, paddingRight: 5 }}>
            {item?.name ?? ' '}:{' '}
          </Text>
          <Text style={{ flex: 0.75 }}>{item?.price ?? ''} FCFA</Text>
        </View>
      ))}
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          paddingVertical: 3,
          backgroundColor: '#e9ecef'
        }}>
        <Text style={{ flex: 0.3, fontWeight: 'bold' }}>Prix total: </Text>
        <Text style={{ flex: 0.7, fontWeight: 'bold' }}>
          {item.totalPrice!} FCFA
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    padding: 5,
    borderBottomWidth: 0.2,
    backgroundColor: 'white',
    paddingVertical: 15
  }
})
