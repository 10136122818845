import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons'
import { Text } from '@ui-kitten/components'
import React, { useContext, useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { ConfirmModal } from 'src/components/molecules/modals/ConfirmModal'
import { config } from 'src/config/businessman'
import { navigate } from 'src/navigations/RootNavigation'
import { FirebaseContext } from 'src/providers/FirebaseProvider'
import { Receipt } from 'src/types/businessman/entities/Receipt'
import { defaultColor } from 'src/utils/color'
import { localDate } from 'src/utils/date/formatDate'
import getFunctionCallable from 'src/utils/firebase/getFunctionCallable'
import { buildPath } from 'src/utils/firebase/path/buildPath'

type PropsType = {
  data: Receipt
}

export const ReceiptListItem = ({ data }: PropsType) => {
  const { userEnterpriseId } = useContext(FirebaseContext)
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const deleteCoworker = async () => {
    try {
      setLoading(true)
      const receiptPath = buildPath(
        config.collection('enterprises'),
        userEnterpriseId!,
        config.collection('receipts'),
        data?.id!
      )

      await getFunctionCallable('receiptPersistence', {
        action: 'delete',
        receiptPath: receiptPath,
        enterprisePath: buildPath(
          config.collection('enterprises'),
          userEnterpriseId!
        )
      })
      setLoading(false)
      setShowDialog(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
      setShowDialog(false)
    }
  }

  return (
    <>
      <ConfirmModal
        loading={loading}
        setVisible={setShowDialog}
        visible={showDialog}
        iconName={'ios-close-circle-outline'}
        color={defaultColor}
        message={'Voulez vraiment supprimer cette quittance ?'}
        onPress={deleteCoworker}
      />
      <TouchableOpacity
        style={styles.container}
        onPress={() => {
          navigate('ReceiptPreviewScreen', {
            data: {
              id: data?.id,
              amount: data?.amountTotal,
              firstName: data?.to?.firstName,
              lastName: data?.to?.lastName,
              email: data?.to?.customerEmail,
              reason: data?.reason,
              phoneNumber: data?.to?.mobilePhone,
              paymentMethod: data?.paymentMethod,
              receiptNumber: data?.receiptNumber
            }
          })
        }}>
        <View style={styles.row}>
          <View style={{ marginRight: 10, flex: 0.55 }}>
            <Text style={{ fontWeight: '700' }}>
              {data?.to?.lastName} {data?.to?.firstName}
            </Text>
            <Text
              category={'s2'}
              style={{ paddingVertical: 10, textAlign: 'justify' }}>
              {data?.reason}
            </Text>

            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
              <Text category={'s2'} style={{ marginRight: 15 }}>
                {data?.amountTotal} FCFA
              </Text>
            </View>
          </View>
          <View
            style={{
              flex: 0.3,
              alignSelf: 'flex-end'
            }}>
            <Text category={'s2'}>{localDate(data?.emittedAt?.toDate())}</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              flex: 0.15
            }}>
            <TouchableOpacity
              style={{ marginRight: 10 }}
              onPress={() => setShowDialog(true)}>
              <MaterialCommunityIcons
                name="delete-outline"
                size={24}
                color="gray"
              />
            </TouchableOpacity>
            <TouchableOpacity>
              <Entypo name="chevron-small-right" size={24} color="black" />
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 3,
    backgroundColor: 'white',
    padding: 10,
    shadowOpacity: 0.1,
    shadowRadius: 2.65,
    shadowColor: '#000',
    shadowOffset: { height: 1, width: 0 },
    elevation: 1
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
