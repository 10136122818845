import { Button, Input, Text } from '@ui-kitten/components'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import React, { useState } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
import { renderEyeIcon } from 'src/components/icons'
import { navigate } from 'src/navigations/RootNavigation'
import { defaultColor } from 'src/utils/color'
import { validator } from 'src/utils/valiator'
import { LoadingIndicator } from '../atoms/LoadingIndicator'
import { ErrorMessage } from '../molecules/errors/ErrorMessage'

const auth = getAuth()
export const EmailAndPassword = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState('')
  const [loading, setLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)

  const login = async () => {
    try {
      if (validator.isEmail(email) && password !== '') {
        setLoading(true)
        await signInWithEmailAndPassword(auth, email, password)
        setLoading(false)
      }
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      setLoginError(error)
    }
  }

  return (
    <View style={styles.container}>
      <Input
        style={styles.input}
        placeholder={'Email'}
        autoCompleteType={'email'}
        textContentType={'emailAddress'}
        keyboardType={'email-address'}
        value={email}
        returnKeyType={'next'}
        clearButtonMode={'while-editing'}
        onChangeText={(text) => {
          setEmail(text)
          setLoginError('')
        }}
      />
      <Input
        style={styles.input}
        placeholder={'Mot de passe'}
        secureTextEntry={hidePassword}
        value={password}
        returnKeyType={'done'}
        accessoryRight={() => renderEyeIcon(hidePassword, setHidePassword)}
        onSubmitEditing={login}
        onChangeText={(text) => {
          setPassword(text)
          setLoginError('')
        }}
      />

      <Button
        status={'control'}
        appearance={'outline'}
        style={styles.loginButton}
        onPress={login}
        accessoryRight={loading ? LoadingIndicator : undefined}>
        {loading ? 'Connexion en cours' : 'Connexion'}
      </Button>
      {loginError ? <ErrorMessage error={loginError} visible={true} /> : null}

      <TouchableOpacity
        style={styles.forgotPassword}
        onPress={() => navigate('ResetPasswordScene')}>
        <Text status={'control'} style={{ color: '#ced4da', fontSize: 13 }}>
          Mot de passe oublié ?
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center'
    // alignItems: 'center'
  },
  input: {
    marginHorizontal: 30,
    marginBottom: 20
  },
  title: {
    color: defaultColor,
    bottom: 100,
    textTransform: 'uppercase'
  },
  forgotPassword: {
    marginTop: 15,
    fontSize: 12,
    alignSelf: 'center',
    marginBottom: 15
  },
  loginButton: {
    marginHorizontal: 30,
    borderColor: 'transparent'
  }
})
